import React from "react";
import { normaliseDate } from "../../../utils/date";
import DatePicker from "../../common/datePicker";
import { Button, Modal } from "react-bootstrap";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import Input from "../../common/input";
import ResizeTextarea from "../../common/resizeTextarea";
import Select from "../../common/select";

function ContentModal({
  state,
  onHide,
  onCancel,
  actors,
  handleSDateChange,
  handleEDateChange,
  handleCurrentWorkingChange,
  handleTitleChange,
  handleCompanyChange,
  handleLocationChange,
  handleContentChange,
  handleSkillsChange,
  handleActorChange,
  doSaveContent,
  confirmDeleteContent,
  keydownhandler,
  onHideDeleteContent,
  doDeleteContent,
  onCancelDelete,
}) {
  return (
    <div>
      <Modal
        size="lg"
        show={state.showmodalcontent}
        onHide={onHide}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title className="text-dark font-weight-bold">
            {state.contentmodaltitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="font-weight-bold mt-0 mb-4 text-left">
          {/* <Input
            type="text"
            name="title"
            value={state.title}
            label="Title"
            placeholder="Ex: Third Officer"
            onChange={handleTitleChange}
            // onBlur={handleBlur}
            error={state.errors["title"]}
          />
          <Input
            type="text"
            name="company"
            value={state.company}
            label="Company Name"
            placeholder="Ex: Global Gateway"
            onChange={handleCompanyChange}
            // onBlur={handleBlur}
            error={state.errors["company"]}
          />
          <Input
            type="text"
            name="location"
            value={state.location}
            label="Location"
            placeholder="Ex: Manila"
            onChange={handleLocationChange}
            // onBlur={handleBlur}
            error={state.errors["location"]}
          />
          <div className="container">
            <div className="row">
              <div className="form-group col mb-0 pb-3 pl-0 pr-0">
                <label htmlFor="sdate">
                  <div className="pr-2">Start Date</div>
                </label>
                <DatePicker
                  name="sdate"
                  selected={
                    state?.sdate
                      ? normaliseDate(new Date(state?.sdate))
                      : normaliseDate(new Date())
                  }
                  onChange={handleSDateChange}

                  // isErr={state.errors["sdate"] !== ""}
                />
              </div>
              <div className="form-group col mb-0 pb-3 pr-0">
                <label htmlFor="edate">
                  <div className="pr-2">End Date</div>
                </label>
                <DatePicker
                  disabled={state.currentlyworking === true}
                  name="edate"
                  selected={
                    state?.edate
                      ? normaliseDate(new Date(state?.edate))
                      : normaliseDate(new Date())
                  }
                  onChange={handleEDateChange}
                  isErr={state.errors["edate"]}
                />
              </div>
            </div>
            <div className="row">
              {state.errors["edate"] && (
                <p className="standardfontsize font-weight-normal text-danger">
                  *{state.errors["edate"]}
                </p>
              )}
            </div>
            <div className="row">
              <>
                <div
                  className="text-center custom-control-lg custom-control custom-checkbox mb-4"
                  style={{ zIndex: "0" }}>
                  <input
                    className="custom-control-input active"
                    value={state.currentlyworking}
                    id="checkbox-large"
                    type="checkbox"
                    onChange={handleCurrentWorkingChange}
                    checked={state.currentlyworking}
                  />
                  <label
                    className="custom-control-label active"
                    htmlFor="checkbox-large">
                    <p className="pointer">I currently work here</p>
                  </label>
                </div>
              </>
            </div>
          </div> */}
          <Select
            name="selecteduser"
            value={state.selecteduser}
            label=""
            options={actors}
            onChange={handleActorChange}
          />
          <ResizeTextarea
            onKeyDown={(e) => keydownhandler(e)}
            type="text"
            name="takeaway"
            value={state.takeaway}
            label="Takeaway"
            placeholder=""
            onChange={handleContentChange}
            error={state.errors["takeaway"]}
          />
          <ResizeTextarea
            onKeyDown={(e) => keydownhandler(e)}
            type="text"
            name="content"
            value={state.content}
            label="Content"
            placeholder=""
            onChange={handleContentChange}
            error={state.errors["content"]}
          />

          {/* <Input
            type="text"
            name="skills"
            value={state.skills}
            label="Skills"
            placeholder=""
            onChange={handleSkillsChange}
            // onBlur={handleBlur}
            // error={state.errors["skills"]}
          /> */}
        </Modal.Body>
        <div
          className={`d-flex ${
            state.contentmodaltitle === "Add Content"
              ? "justify-content-end"
              : "justify-content-between"
          }  px-4 pb-4 `}>
          {state.contentmodaltitle === "Edit Content" && (
            <Button
              variant="pure"
              style={{ fontSize: "16px" }}
              className="text-danger"
              onClick={confirmDeleteContent}>
              <DeleteForeverOutlinedIcon style={{ fontSize: "18px" }} /> Delete
            </Button>
          )}
          <div>
            <Button
              disabled={state.savingcontent}
              variant="link"
              className="forcenoline noline mr-2"
              //   onClick={() => {
              //     setState({ showmodalcontent: false, errors: {} });
              //   }}
              onClick={onCancel}>
              <span className="text-dark forcenoline noline">Cancel</span>
            </Button>
            {state.savingcontent !== true && (
              <Button
                variant={`${state.showdeletecontent ? "" : "success"}`}
                onClick={() => doSaveContent()}>
                Save
              </Button>
            )}
            {state.savingcontent === true && (
              <Button
                disabled
                variant="success"
                onClick={() => doSaveContent()}>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>{" "}
                Saving...
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        contentClassName="redframecolor"
        show={state.showdeletecontent}
        onHide={onHideDeleteContent}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title className="text-dark font-weight-bold">
            Delete Content?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="  mt-0 mb-4 text-left text-dark">
          Are you sure you want to delete content?
        </Modal.Body>
        <div className="d-flex justify-content-end px-4 pb-4 ">
          <div>
            <Button
              disabled={state.deletingcontent}
              variant="link"
              className="forcenoline noline mr-2"
              onClick={onCancelDelete}>
              <span className="text-dark forcenoline noline">Cancel</span>
            </Button>
            {state.deletingcontent !== true && (
              <Button variant="danger" onClick={() => doDeleteContent()}>
                Delete
              </Button>
            )}
            {state.deletingcontent === true && (
              <Button disabled variant="danger">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>{" "}
                Deleting...
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ContentModal;
