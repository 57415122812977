import React from "react";
import { formatTimeSimple } from "../../../utils/utils";
import { Row } from "react-bootstrap";
import PublicIcon from "@material-ui/icons/Public";

function DisplayRow({ i }) {
  return (
    <div className="appearslowly bg-white d-flex justify-content-between  pointer mt-3 roundcornernocolor py-3 pl-3 pr-2 shadow-sm">
      <div className="ml-3 mr-2">
        <Row>
          <div className="text-left font-weight-bold noline">{i.header}</div>
        </Row>
        <Row>
          <div
            style={{ textDecoration: "none !important" }}
            className="pr-2 standardfontsize text-left noline text-muted lineentry">
            {i.description}
          </div>
        </Row>
        <Row className="">
          <div className=" mt-1 standardfontsize d-flex align-items-center text-muted">
            <img
              className=" rounded-circle mr-1"
              src={i?.smallpic}
              width={16}
              height={16}
            />
            <div style={{ textDecoration: "none !important" }}>
              {i.owner} &bull; {formatTimeSimple(new Date(i.created))} &bull;{" "}
              <PublicIcon style={{ fontSize: "12px" }} />
            </div>
          </div>
        </Row>
      </div>
      {/* <div>
          {i.label} <ArrowForwardIosIcon />
        </div> */}
      {i?.knowledgesmallpic && (
        <div className="ml-1">
          <img
            className="rounded ml-1"
            width={100}
            height={100}
            src={i.knowledgesmallpic}
          />
        </div>
      )}
    </div>
  );
}

export default DisplayRow;
