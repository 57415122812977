import React, { Component } from "react";
import Header from "../../common/header";
import { getCurrentUserData, userLogout } from "../../../services/user";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { logEvent } from "../../../services/log";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { Link } from "react-router-dom";

import {
  enableEditBasicProfile,
  enableProfilePhotoUpload,
  getWebsiteFullDescription,
  getWebsiteName,
} from "../../../services/settings";
import { isValidFullname } from "../../../services/validation";
import SystemBarError from "../../common/systemBarError";
import fire from "../../../services/fire";
import { updateProfilePic } from "../../../services/profile";
import SuccessMessage from "../../common/successMessageBox";
import { getLatestVersion } from "../../../services/getversion";
import ReloadBar from "../../common/reloadBar";
import IntroPage from "../../common/introPage";
import countryList from "react-select-country-list";
import Footer from "../../common/footer";
import { getLongMonths } from "../../../utils/utils";
import { isEmpty } from "../../../utils/obj";
import { normaliseDate } from "../../../utils/date";
import FormatColorFillRoundedIcon from "@material-ui/icons/FormatColorFillRounded";
import { getThemeData } from "../../../utils/colors";
import BackgroundSelectModal from "../component/backgroundSelectModal";
import BasicInfo from "../component/basicInfo";
import PhotoEdit from "../component/photoEdit";
import ContentModal from "../component/contentModal";
import DisplayRow from "../../link/component/displayRow";

class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      knowledge: [],
      actors: [
        { name: "Jeyner Gil Caga", _id: "9yrgVHIm80QFx0dbfF59eXgxgt93" },
        { name: "Emily Johnson", _id: "AuMMZzpZZtQopd2wWCq0qbxW6UV2" },
        { name: "Ava Martinez", _id: "j33mGf9Bg4gnDHKsJeXc2qEIZiv2" },
        { name: "Benjamin Davis", _id: "jWA70rXfP7Okdz4YaUkseq5dt5C2" },
      ],
      data: {
        background: 0,
        verified: false,
        bigpic: "",
        smallpic: "",
        fullname: "",
        userhandle: "",
        email: "",
        school: "",
        degree: "",
        description: "",
        currentcompanydesc: "",
        currentcompany: "",
        phonenumber: "",
        address: "",
        init: false,
      },
      background: 0,
      updatingbackground: false,
      user: this.props.user,
      imageDataUrl: "",
      showphotoedit: false,
      showbackground: false,
      saving: false,
      loading: true,
      zoom: 1,
      showcopymodal: false,
      maxjobdesclength: 1000,
      errors: {},
      entry: {},
      /** trainings modal */
      trainmodaltitle: "Add Training",
      showdeletetrain: false,
      showmodaltrain: false,
      savingtrain: false,
      deletingtrain: false,
      certnumber: "",
      /** documents modal */
      travelmodaltitle: "Add Travel Document",
      showdeletetravel: false,
      showmodaltravel: false,
      savingtravel: false,
      deletingtravel: false,
      documentnumber: "",
      /** experience modal */
      expmodaltitle: "Add Experience",
      showdeleteexp: false,
      showmodalexp: false,
      savingexp: false,
      deletingexp: false,
      company: "",
      description: "",
      skills: "",
      /** content modal */
      contentmodaltitle: "Add Experience",
      showdeletecontent: false,
      showmodalcontent: false,
      savingcontent: false,
      deletingcontent: false,
      content: "",
      takeaway: "",
      selecteduser: "",
      /** sea experience modal */
      seaexpmodaltitle: "Add Sea-Based Experience",
      showdeleteseaexp: false,
      showmodalseaexp: false,
      savingseaexp: false,
      deletingseaexp: false,
      company: "",
      vessel: "",
      type: "",
      grt: "",
      kw: "",
      dwt: "",
      skills: "",
      /** school modal */
      schoolmodaltitle: "Add Education",
      showdeleteschool: false,
      showmodalschool: false,
      savingschool: false,
      deletingschool: false,
      school: "",
      degree: "",
      awards: "",
      sdate: "",
      edate: "",
      currentlyworking: false,
      startmonth: "",
      startyear: "",
      endmonth: "",
      endyear: "",
      /** common */
      location: "",
      description: "",
      title: "",
      months: getLongMonths().map((i) => {
        return { _id: i, name: i };
      }),
      years: Array.from(
        { length: 101 },
        (_, i) => new Date().getFullYear() - i
      ).map((i) => {
        return { _id: i, name: i };
      }),
      expectedyears: Array.from(
        { length: 101 },
        (_, i) => new Date().getFullYear() + 10 - i
      ).map((i) => {
        return { _id: i, name: i };
      }),
    };
  }

  async getCurrentUserData() {
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);
    const l = getLongMonths().map((i) => {
      return { _id: i, name: i };
    });
    // console.log(l);
    if (user) {
      const {
        train,
        travel,
        exp,
        edu,
        bio,
        fullname,
        email,
        currentpos,
        bigpic,
        country,
        init,
        smallpic,
        school,
        degree,
        address,
        phonenumber,
        currentcompanydesc,
        currentcompany,
        userhandle,
        verified,
        background,
        dob,
        height,
        weight,
        civilstatus,
      } = user;

      let isvalidfullname = isValidFullname(fullname);

      const photoUrl = bigpic;
      let el = document.querySelector("title");
      const title = `${getWebsiteName()}`;
      const content = getWebsiteFullDescription();
      el.innerText = title;
      el = document.querySelector("meta[name='description']");
      el.setAttribute("content", content);
      let version = await getLatestVersion();
      const countries = countryList().getData();

      let countryStr = "";
      if (countries && country !== "") {
        //get country str
        for (let i = 0; i < countries.length; i++) {
          const item = countries[i];
          if (item.value === country) {
            countryStr = item.label;
            break;
          }
        }
      }
      //get all contents from knowledge collection in firestore where userid = user.uid sort by created
      const db = fire.firestore();
      let doc;
      if (uid === "9yrgVHIm80QFx0dbfF59eXgxgt93") {
        doc = await db.collection("knowledge").orderBy("created", "desc").get();
      } else {
        doc = await db
          .collection("knowledge")
          .where("userid", "==", uid)
          .orderBy("created", "desc")
          .get();
      }

      const knowledge = [];
      doc.forEach((item) => {
        const data = item.data();
        const id = item.id;
        //format created to readable format {nanoseconds, seconds} to timestamp
        // const created = data.created.seconds * 1000;
        // const updated = data.updated.seconds * 1000;
        knowledge.push({ ...data, id });
      });
      // console.log(countries);
      // console.log(countryStr);
      this.setState({
        knowledge,
        countryStr,
        countries,
        version,
        isvalidfullname,
        background: background || 0,
        data: {
          train: train || [],
          travel: travel || [],
          verified: verified || false,
          exp,
          userhandle,
          edu,
          bio,
          dob,
          height,
          weight,
          civilstatus,
          fullname,
          bigpic: bigpic || "",
          smallpic: smallpic || "",
          background: background || 0,
          email,
          init,
          currentpos,
          address,
          phonenumber,
          school,
          degree,
          currentcompanydesc,
          currentcompany,
          country,
          countryStr,
        },
        photoUrl,
        loading: false,
      });
    } else {
      await userLogout();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      logEvent("logout_click", { email: data.email });
      await userLogout();
      window.scrollTo(0, 0);

      // signed out
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  doEdit = () => {
    this.props.history.push("/account/edit");
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { data } = this.state;
      const file = e.target.files[0];
      let imageDataUrl = await this.readFile(file);

      logEvent("profile_new_photo_upload", { email: data.email });

      this.setState({ imageDataUrl, showphotoedit: true });
    }
  };

  readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  onClickCancel = () => {
    this.setState({ showphotoedit: false });
  };

  doDeleteSchool = async () => {
    this.setState({ deletingschool: true });
    const userid = this.state.user.uid;
    const edu = this.state.data?.edu || [];
    const entry = this.state.entry;
    // console.log(entry);
    // console.log(edu);
    const newEdu = edu.filter((i) => entry.created !== i.created);

    // console.log(newEdu);
    await fire.firestore().doc(`users/${userid}`).update({
      edu: newEdu,
    });
    this.setState({
      deletingschool: false,
      showdeleteschool: false,
      showmodalschool: false,
      data: { ...this.state.data, edu: newEdu },
    });
  };

  doUpdateBackground = async () => {
    this.setState({ updatingbackground: true });
    const userid = this.state.user.uid;
    const back = this.state?.background || 0;
    await fire.firestore().doc(`users/${userid}`).update({
      background: back,
    });
    this.setState({
      updatingbackground: false,
      showbackground: false,
      data: { ...this.state.data, background: back },
    });
  };

  doDeleteExp = async () => {
    this.setState({ deletingexp: true });
    const userid = this.state.user.uid;
    const exp = this.state.data?.exp || [];
    const entry = this.state.entry;
    // console.log(entry);
    // console.log(exp);
    const newExp = exp.filter((i) => entry.created !== i.created);

    // console.log(newExp);
    await fire.firestore().doc(`users/${userid}`).update({
      exp: newExp,
    });
    this.setState({
      deletingexp: false,
      showdeleteexp: false,
      showmodalexp: false,
      data: { ...this.state.data, exp: newExp },
    });
  };

  doDeleteSeaExp = async () => {
    this.setState({ deletingseaexp: true });
    const userid = this.state.user.uid;
    const exp = this.state.data?.exp || [];
    const entry = this.state.entry;
    // console.log(entry);
    // console.log(exp);
    const newExp = exp.filter((i) => entry.created !== i.created);

    // console.log(newExp);
    await fire.firestore().doc(`users/${userid}`).update({
      exp: newExp,
    });
    this.setState({
      deletingseaexp: false,
      showdeleteseaexp: false,
      showmodalseaexp: false,
      data: { ...this.state.data, exp: newExp },
    });
  };

  doDeleteTrain = async () => {
    this.setState({ deletingtrain: true });
    const userid = this.state.user.uid;
    const obj = this.state.data?.train || [];
    const entry = this.state.entry;
    // console.log(entry);
    // console.log(obj);
    const newObj = obj.filter((i) => entry.created !== i.created);

    // console.log(newObj);
    await fire.firestore().doc(`users/${userid}`).update({
      train: newObj,
    });
    this.setState({
      deletingtrain: false,
      showdeletetrain: false,
      showmodaltrain: false,
      data: { ...this.state.data, train: newObj },
    });
  };

  doDeleteTravel = async () => {
    this.setState({ deletingtravel: true });
    const userid = this.state.user.uid;
    const obj = this.state.data?.travel || [];
    const entry = this.state.entry;
    // console.log(entry);
    // console.log(obj);
    const newObj = obj.filter((i) => entry.created !== i.created);

    // console.log(newObj);
    await fire.firestore().doc(`users/${userid}`).update({
      travel: newObj,
    });
    this.setState({
      deletingtravel: false,
      showdeletetravel: false,
      showmodaltravel: false,
      data: { ...this.state.data, travel: newObj },
    });
  };

  doSaveSchool = async () => {
    console.log("saving");
    const {
      startmonth,
      startyear,
      endmonth,
      endyear,
      school,
      entry,
      degree,
      awards,
      schoolmodaltitle,
    } = this.state;
    let errors = {};
    if (school.trim() === "") {
      errors["school"] = "School must not be empty";
    }

    // if (degree.trim() === "") {
    //   errors["degree"] = "Degree must not be empty";
    // }

    if (startmonth.trim() === "") {
      errors["startmonth"] = "Month must not be empty";
    }

    if (startyear.trim() === "") {
      errors["startyear"] = "Year must not be empty";
    }

    if (endyear.trim() === "") {
      errors["endyear"] = "Year must not be empty";
    }

    if (endmonth.trim() === "") {
      errors["endmonth"] = "Month must not be empty";
    }
    //check end
    if (
      new Date(`${startmonth} ${startyear}`).getTime() >
      new Date(`${endmonth} ${endyear}`).getTime()
    ) {
      errors["endyear"] = "End Date can't be earlier than start date";
    }
    // console.log(errors);
    // console.log(isEmpty(errors));
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingschool: true });
    // console.log(startmonth, startyear, endmonth, endyear, school, degree);
    //save here
    const userid = this.state.user.uid;
    let edu = this.state.data?.edu || [];
    const newEdu = {
      created: new Date().getTime(),
      startdate: new Date(`${startmonth} ${startyear}`).getTime(),
      startmonth,
      startyear,
      endmonth,
      endyear,
      school,
      awards: awards || "",
      degree,
    };
    //save
    if (schoolmodaltitle === "Add Education") {
      edu.push(newEdu);
    } else {
      const tempEdu = edu.filter((i) => entry.created !== i.created);
      tempEdu.push(newEdu);
      edu = tempEdu;
    }
    // console.log(edu);
    //sort
    edu.sort((a, b) => {
      if (a.startdate < b.startdate) {
        return 1;
      }
      if (a.startdate > b.startdate) {
        return -1;
      }
      return 0;
    });
    // console.log(edu);

    await fire.firestore().doc(`users/${userid}`).update({
      edu: edu,
    });
    this.setState({
      startmonth: "",
      startyear: "",
      endmonth: "",
      endyear: "",
      school: "",
      degree: "",
      errors: {},
      awards: awards || "",

      data: { ...this.state.data, edu: edu },
      savingschool: false,
      showmodalschool: false,
    });
  };

  doSaveExp = async () => {
    console.log("saving");
    const {
      sdate,
      edate,
      currentlyworking,
      startmonth,
      startyear,
      endmonth,
      endyear,
      title,
      entry,
      location,
      skills,
      company,
      description,
      expmodaltitle,
    } = this.state;
    let errors = {};
    if (title.trim() === "") {
      errors["title"] = "Title must not be empty";
    }

    if (location.trim() === "") {
      errors["location"] = "Location must not be empty";
    }
    if (company.trim() === "") {
      errors["company"] = "Company must not be empty";
    }

    // console.log(currentlyworking);
    // if (startmonth.trim() === "") {
    //   errors["startmonth"] = "Month must not be empty";
    // }

    // if (startyear.trim() === "") {
    //   errors["startyear"] = "Year must not be empty";
    // }
    if (description?.trim() !== "" && description.length > 1000) {
      errors["description"] = "Exceeded maximum character length of 1,000";
    }

    // if (endyear.trim() === "") {
    //   errors["endyear"] = "Year must not be empty";
    // }

    // if (endmonth.trim() === "") {
    //   errors["endmonth"] = "Month must not be empty";
    // }
    // if (endmonth.trim() !== "" && endyear.trim() === "") {
    //   errors["endyear"] = "Year must not be empty";
    // }
    // if (endmonth.trim() === "" && endyear.trim() !== "") {
    //   errors["endmonth"] = "Month must not be empty";
    // }
    //check end
    // if (
    //   new Date(`${startmonth} ${startyear}`).getTime() >
    //   new Date(`${endmonth} ${endyear}`).getTime()
    // ) {
    //   errors["endyear"] = "End year can't be earlier than start date";
    // }
    if (edate < sdate) {
      errors["edate"] = "End Date can't be earlier than start date";
    }
    console.log(errors);
    // console.log(isEmpty(errors));
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingexp: true });
    //save here
    const userid = this.state.user.uid;
    let exp = this.state.data?.exp || [];
    const newExp = {
      created: new Date().getTime(),
      sdate: sdate.toString(),
      edate: edate.toString(),
      currentlyworking: currentlyworking || false,
      startdate: sdate.getTime(),
      // startdate: new Date(`${startmonth} ${startyear}`).getTime(),
      startmonth,
      startyear,
      endmonth,
      endyear,
      location,
      skills: skills || "",
      company,
      description: description || "",
      title,
    };
    //save
    if (expmodaltitle === "Add Experience") {
      exp.push(newExp);
    } else {
      const tempExp = exp.filter((i) => entry.created !== i.created);
      tempExp.push(newExp);
      exp = tempExp;
    }
    // console.log(exp);
    //sort
    exp.sort((a, b) => {
      if (a.startdate < b.startdate) {
        return 1;
      }
      if (a.startdate > b.startdate) {
        return -1;
      }
      return 0;
    });
    // console.log(exp);

    await fire.firestore().doc(`users/${userid}`).update({ exp: exp });
    const defaultDate = normaliseDate(new Date());

    this.setState({
      startmonth: "",
      startyear: "",
      endmonth: "",
      sdate: defaultDate,
      edate: defaultDate,
      currentlyworking: false,
      endyear: "",
      title: "",
      skills: "",
      location: "",
      company: "",
      description: "",
      errors: {},
      data: { ...this.state.data, exp: exp },
      savingexp: false,
      showmodalexp: false,
    });
  };

  doSaveContent = async () => {
    console.log("Saving Content...");
    const { entry, content, contentmodaltitle, takeaway } = this.state;
    let errors = {};

    if (content.trim() === "") {
      errors["content"] = "Content must not be empty";
    }

    console.log(errors);
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingcontent: true });
    //save here
    const userid = this.state.selecteduser;
    const newContent = { content: content, userid, takeaway: takeaway || "" };
    console.log(newContent);
    //save
    if (contentmodaltitle === "Add Content") {
      // exp.push(newContent);
      console.log("Add Content");
    } else {
      // const tempExp = exp.filter((i) => entry.created !== i.created);
      // tempExp.push(newExp);
      // exp = tempExp;
      console.log("Delete Content");
    }
    // console.log(exp);

    const knowRef = fire.firestore().collection(`knowledge`).doc();
    await knowRef.set({ ...newContent });

    this.setState({
      content: "",
      errors: {},
      // data: { ...this.state.data, exp: exp },
      savingcontent: false,
      showmodalcontent: false,
    });
  };

  doSaveTrain = async () => {
    console.log("saving");
    const {
      sdate,
      edate,
      title,
      entry,
      location,
      certnumber,
      trainmodaltitle,
    } = this.state;
    let errors = {};
    if (title.trim() === "") {
      errors["title"] = "Name must not be empty";
    }

    if (location.trim() === "") {
      errors["location"] = "Place of Issue must not be empty";
    }
    if (certnumber?.trim() === "") {
      errors["certnumber"] = "Number must not be empty";
    }

    if (edate < sdate) {
      errors["edate"] = "Expiry can't be earlier than Issue Date";
    }
    // console.log(errors);
    // console.log(isEmpty(errors));
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingtrain: true });
    //save here
    const userid = this.state.user.uid;
    let obj = this.state.data?.train || [];
    const newObj = {
      created: new Date().getTime(),
      sdate: sdate.toString(),
      edate: edate.toString(),
      startdate: sdate.getTime(),
      location,
      certnumber: certnumber || "",
      title,
    };
    //save
    if (trainmodaltitle === "Add Training/Certificate") {
      obj.push(newObj);
    } else {
      const tempObj = obj.filter((i) => entry.created !== i.created);
      tempObj.push(newObj);
      obj = tempObj;
    }
    // console.log(obj);
    //sort
    obj.sort((a, b) => {
      if (a.startdate < b.startdate) {
        return 1;
      }
      if (a.startdate > b.startdate) {
        return -1;
      }
      return 0;
    });
    // console.log(obj);

    await fire.firestore().doc(`users/${userid}`).update({
      train: obj,
    });
    this.setState({
      sdate: new Date(),
      edate: new Date(),
      title: "",
      certnumber: "",
      location: "",
      errors: {},
      data: { ...this.state.data, train: obj },
      savingtrain: false,
      showmodaltrain: false,
    });
  };

  doSaveSeaExp = async () => {
    console.log("saving");
    const {
      sdate,
      edate,
      currentlyworking,
      startmonth,
      startyear,
      endmonth,
      endyear,
      title,
      entry,
      vessel,
      grt,
      kw,
      dwt,
      type,
      company,
      seaexpmodaltitle,
    } = this.state;
    let errors = {};
    if (title.trim() === "") {
      errors["title"] = "Rank must not be empty";
    }

    if (company.trim() === "") {
      errors["company"] = "Shipping Agency must not be empty";
    }
    if (vessel.trim() === "") {
      errors["vessel"] = "Vessel must not be empty";
    }
    if (grt.trim() === "") {
      errors["grt"] = "GRT must not be empty";
    }
    // if (dwt.trim() === "") {
    //   errors["dwt"] = "DWT must not be empty";
    // }
    if (type.trim() === "") {
      errors["type"] = "Type must not be empty";
    }
    if (kw.trim() === "") {
      errors["kw"] = "KW must not be empty";
    }

    // console.log(currentlyworking);
    // if (startmonth.trim() === "") {
    //   errors["startmonth"] = "Month must not be empty";
    // }

    // if (startyear.trim() === "") {
    //   errors["startyear"] = "Year must not be empty";
    // }

    // if (endyear.trim() === "") {
    //   errors["endyear"] = "Year must not be empty";
    // }

    // if (endmonth.trim() === "") {
    //   errors["endmonth"] = "Month must not be empty";
    // }
    // if (endmonth.trim() !== "" && endyear.trim() === "") {
    //   errors["endyear"] = "Year must not be empty";
    // }
    // if (endmonth.trim() === "" && endyear.trim() !== "") {
    //   errors["endmonth"] = "Month must not be empty";
    // }
    //check end
    // if (
    //   new Date(`${startmonth} ${startyear}`).getTime() >
    //   new Date(`${endmonth} ${endyear}`).getTime()
    // ) {
    //   errors["endyear"] = "End year can't be earlier than start date";
    // }
    if (edate < sdate) {
      errors["edate"] = "Sign Off can't be earlier than Sign On";
    }
    console.log(errors);
    // console.log(isEmpty(errors));
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingseaexp: true });
    //save here
    const userid = this.state.user.uid;
    let exp = this.state.data?.exp || [];
    const newExp = {
      created: new Date().getTime(),
      sdate: sdate.toString(),
      edate: edate.toString(),
      currentlyworking: currentlyworking || false,
      startdate: sdate.getTime(),
      // startdate: new Date(`${startmonth} ${startyear}`).getTime(),
      startmonth,
      startyear,
      endmonth,
      endyear,
      vessel,
      grt: grt || "",
      // dwt: dwt || "",
      kw: kw || "",
      type: type || "",
      company,
      title,
    };
    //save
    if (seaexpmodaltitle === "Add Sea-Based Experience") {
      exp.push(newExp);
    } else {
      const tempExp = exp.filter((i) => entry.created !== i.created);
      tempExp.push(newExp);
      exp = tempExp;
    }
    // console.log(exp);
    //sort
    exp.sort((a, b) => {
      if (a.startdate < b.startdate) {
        return 1;
      }
      if (a.startdate > b.startdate) {
        return -1;
      }
      return 0;
    });
    // console.log(exp);

    await fire.firestore().doc(`users/${userid}`).update({ exp: exp });
    const defaultDate = normaliseDate(new Date());

    this.setState({
      startmonth: "",
      startyear: "",
      endmonth: "",
      sdate: defaultDate,
      edate: defaultDate,
      currentlyworking: false,
      endyear: "",
      title: "",
      vessel: "",
      grt: "",
      kw: "",
      dwt: "",
      type: "",
      company: "",
      errors: {},
      data: { ...this.state.data, exp: exp },
      savingseaexp: false,
      showmodalseaexp: false,
    });
  };

  doSaveTravel = async () => {
    console.log("saving");
    const {
      sdate, //issued
      edate, //expiry
      title, //Passport
      entry,
      location, //Place of Issue
      documentnumber, //Number
      travelmodaltitle,
    } = this.state;
    let errors = {};
    if (title.trim() === "") {
      errors["title"] = "Document must not be empty";
    }

    if (location.trim() === "") {
      errors["location"] = "Place of Issue must not be empty";
    }
    if (documentnumber?.trim() === "") {
      errors["documentnumber"] = "Document Number must not be empty";
    }

    if (edate < sdate) {
      errors["edate"] = "Expiry can't be earlier than Issue Date";
    }
    // console.log(errors);
    // console.log(isEmpty(errors));
    if (!isEmpty(errors)) {
      this.setState({ errors: errors });
      return;
    }
    this.setState({ savingtravel: true });
    //save here
    const userid = this.state.user.uid;
    let obj = this.state.data?.travel || [];
    const newObj = {
      created: new Date().getTime(),
      sdate: sdate.toString(),
      edate: edate.toString(),
      startdate: sdate.getTime(),
      location,
      documentnumber: documentnumber || "",
      title,
    };
    //save
    if (travelmodaltitle === "Add Travel Document") {
      obj.push(newObj);
    } else {
      const tempObj = obj.filter((i) => entry.created !== i.created);
      tempObj.push(newObj);
      obj = tempObj;
    }
    // console.log(obj);
    //sort
    obj.sort((a, b) => {
      if (a.startdate < b.startdate) {
        return 1;
      }
      if (a.startdate > b.startdate) {
        return -1;
      }
      return 0;
    });
    // console.log(obj);

    await fire.firestore().doc(`users/${userid}`).update({
      travel: obj,
    });
    this.setState({
      sdate: new Date(),
      edate: new Date(),
      title: "",
      location: "",
      documentnumber: "",
      errors: {},
      data: { ...this.state.data, travel: obj },
      savingtravel: false,
      showmodaltravel: false,
    });
  };

  onClickSave = async () => {
    if (this.editor) {
      const { data } = this.state;

      this.setState({ saving: true });
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const userid = this.state.user.uid;
      const uploadpath = `images/${userid}`;
      const filename = `${userid}.jpeg`;

      fire
        .storage()
        .ref(uploadpath)
        .child(filename)
        .putString(canvasScaled.toDataURL(), "data_url")
        .then(async (snapshot) => {
          await updateProfilePic(userid, filename);
          logEvent("profile_photo_changed_success", { email: data.email });

          this.setState({
            newprofileupload: true,
            saving: false,
            showphotoedit: false,
            photoUrl: canvasScaled.toDataURL(),
          });
        });
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  increaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 3;
    if (zoom < 3) {
      newzoom = zoom + 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  decreaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 1;
    if (zoom > 1) {
      newzoom = zoom - 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  reloadProfile = () => {
    window.location.reload();
  };

  goHome = () => {
    this.props.history.push("/home");
  };
  handleSchoolChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ school: input.value });
  };
  handleSDateChange = (value) => {
    const updatedValue = normaliseDate(value);

    this.setState({ sdate: updatedValue });
    // this.setState({ sdate: updatedValue, edate: updatedValue });
  };

  handleEDateChange = (value) => {
    const updatedValue = normaliseDate(value);

    console.log(updatedValue);
    this.setState({ edate: updatedValue });
  };
  handleCurrentWorkingChange = (value) => {
    // console.log(input.value);
    const working = !this.state.currentlyworking;
    this.setState({ currentlyworking: working });
  };
  handleDegreeChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ degree: input.value });
  };
  handleStartMonthChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ startmonth: input.value });
  };
  handleStartYearChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ startyear: input.value });
  };
  handleEndMonthChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ endmonth: input.value });
  };

  keydownhandler = ({ currentTarget: input }) => {
    console.log(input.value);
    if (
      input?.value?.length &&
      input?.value?.length <= this.state.maxjobdesclength
    ) {
      console.log("Celaring");
      let errors = this.state.errors;
      delete errors["description"];
      this.setState({ errors: errors });
    }
    if (
      input?.value?.length &&
      input?.value?.length > this.state.maxjobdesclength
    ) {
      let errors = this.state.errors;
      errors["description"] = "Exceeded maximum character length of 1,000";
      this.setState({ errors: errors });
    }
  };
  handleEndYearChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ endyear: input.value });
  };
  handleBriefDescChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ awards: input.value });
  };
  handleDescriptionChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ description: input.value });
  };
  handleTitleChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ title: input.value });
  };
  handleSkillsChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ skills: input.value });
  };
  handleCompanyChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ company: input.value });
  };
  handleOrganisationChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ organisation: input.value });
  };
  handleCertNumChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ certnumber: input.value });
  };
  handleDocumentNumberChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ documentnumber: input.value });
  };
  handleLocationChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ location: input.value });
  };
  handleVesselChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ vessel: input.value });
  };
  handleGRTChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ grt: input.value });
  };
  handleDWTChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ dwt: input.value });
  };
  handleKWChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ kw: input.value });
  };
  handleTypeChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ type: input.value });
  };
  handleDescriptionChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ description: input.value });
  };
  handleContentChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    const { name, value } = input;

    this.setState({ [name]: value });
  };
  handleActorChange = ({ currentTarget: input }) => {
    // console.log(input.value);
    this.setState({ selecteduser: input.value });
  };

  confirmDeleteSchool = () => {
    this.setState({ showdeleteschool: true });
  };

  confirmDeleteExp = () => {
    this.setState({ showdeleteexp: true });
  };
  confirmDeleteSeaExp = () => {
    this.setState({ showdeleteseaexp: true });
  };
  confirmDeleteTrain = () => {
    this.setState({ showdeletetrain: true });
  };
  confirmDeleteTravel = () => {
    this.setState({ showdeletetravel: true });
  };

  editSchool = (entry) => {
    // console.log(entry);
    this.setState({
      entry: entry,
      showmodalschool: true,
      schoolmodaltitle: "Edit Education",
      school: entry.school,
      degree: entry.degree,
      startmonth: entry.startmonth,
      startyear: entry.startyear,
      endmonth: entry.endmonth,
      endyear: entry.endyear,
      errors: {},
      savingschool: false,
      awards: entry?.awards,
    });
  };

  editTrain = (entry) => {
    // console.log(entry);
    const sdate = entry?.sdate ? new Date(entry?.sdate) : new Date();
    const edate = entry?.edate ? new Date(entry?.edate) : new Date();
    this.setState({
      entry: entry,
      showmodaltrain: true,
      trainmodaltitle: "Edit Training/Certificate",
      title: entry.title,
      certnumber: entry?.certnumber || "",
      location: entry.location,
      sdate: normaliseDate(sdate),
      edate: normaliseDate(edate),
      errors: {},
      savingtrain: false,
    });
  };

  editTravel = (entry) => {
    // console.log(entry);
    const sdate = entry?.sdate ? new Date(entry?.sdate) : new Date();
    const edate = entry?.edate ? new Date(entry?.edate) : new Date();
    this.setState({
      entry: entry,
      showmodaltravel: true,
      travelmodaltitle: "Edit Travel Document",
      title: entry.title,
      documentnumber: entry?.documentnumber || "",
      location: entry.location,
      sdate: normaliseDate(sdate),
      edate: normaliseDate(edate),
      errors: {},
      savingtravel: false,
    });
  };

  editExp = (entry) => {
    // console.log(entry);
    const sdate = entry?.sdate ? new Date(entry?.sdate) : new Date();
    const edate = entry?.edate ? new Date(entry?.edate) : new Date();
    this.setState({
      entry: entry,
      showmodalexp: true,
      expmodaltitle: "Edit Experience",
      title: entry.title,
      company: entry.company,
      location: entry.location,
      startmonth: entry.startmonth,
      startyear: entry.startyear,
      endmonth: entry.endmonth,
      endyear: entry.endyear,
      skills: entry?.skills || "",
      sdate: normaliseDate(sdate),
      edate: normaliseDate(edate),
      currentlyworking: entry?.currentlyworking || false,
      errors: {},
      savingexp: false,
      degree: entry.degree,
      description: entry?.description || "",
    });
  };
  editSeaExp = (entry) => {
    // console.log(entry);
    const sdate = entry?.sdate ? new Date(entry?.sdate) : new Date();
    const edate = entry?.edate ? new Date(entry?.edate) : new Date();
    this.setState({
      entry: entry,
      showmodalseaexp: true,
      seaexpmodaltitle: "Edit Experience",
      title: entry.title,
      company: entry.company,
      vessel: entry.vessel,
      startmonth: entry.startmonth,
      startyear: entry.startyear,
      endmonth: entry.endmonth,
      endyear: entry.endyear,
      grt: entry?.grt || "",
      kw: entry?.kw || "",
      dwt: entry?.dwt || "",
      type: entry?.type || "",
      sdate: normaliseDate(sdate),
      edate: normaliseDate(edate),
      currentlyworking: entry?.currentlyworking || false,
      errors: {},
      savingseaexp: false,
      degree: entry.degree,
      description: entry?.description || "",
    });
  };

  showBackground = () => {
    this.setState({ showbackground: true });
  };

  render() {
    const {
      data,
      loading,
      isvalidfullname,
      photoUrl,
      zoom,
      showphotoedit,
      imageDataUrl,
      saving,
      newprofileupload,
      version,
      knowledge,
    } = this.state;

    const enablePicUpload = enableProfilePhotoUpload();
    const { fullname } = data;

    if (loading === true) {
      return <LoadingSpinnerNav hidehome hidefriends hide="profile" />;
    }
    const linkcolor = getThemeData(this.state.background)?.linkcolor || "";
    const forecolor = getThemeData(this.state.background)?.foreground || "";
    let showedit = false;
    if (enableEditBasicProfile()) {
      showedit = true;
    }
    if (!data?.init) {
      return (
        <IntroPage
          user={this.props.user}
          version={version}
          handler={() => this.props.history.push("/account/edit")}
        />
      );
    }
    if (newprofileupload) {
      return (
        <SuccessMessage
          message="Your photo has been successfully saved!"
          label="Done"
        />
      );
    }

    return (
      <React.Fragment>
        <Header
          hidehome
          hide="profile"
          user={this.state.user}
          shortcode={this.state.data.userhandle}
        />
        <ReloadBar version={version} />
        {!isvalidfullname && (
          <SystemBarError
            text="Please update your Full Name."
            link="/account/edit"
          />
        )}
        <div className="row justify-content-center mx-auto appearslowly">
          <div className="col-lg-8 text-center mx-0 px-0">
            <main className="container text-center mx-auto ">
              {!showphotoedit && (
                <React.Fragment>
                  <div
                    className={`mt-4 roundcornernocolor card p-4 shadow ${
                      getThemeData(this.state.background)?.bgcolor
                    }`}>
                    <div
                      className="pointer"
                      onClick={this.showBackground}
                      style={{ position: "absolute", right: "20px" }}>
                      <OverlayTrigger
                        overlay={<Tooltip>Change Background</Tooltip>}>
                        <FormatColorFillRoundedIcon
                          style={{
                            fontSize: "20px",
                            color: linkcolor,
                          }}
                        />
                      </OverlayTrigger>
                    </div>

                    <BasicInfo
                      showlink={false}
                      state={this.state}
                      data={data}
                      linkcolor={linkcolor}
                      forecolor={forecolor}
                      enablePicUpload={enablePicUpload}
                      photoUrl={photoUrl}
                      fullname={fullname}
                      onFileChange={this.onFileChange}
                      onCopy={() => this.setState({ showcopymodal: true })}
                      onHide={() => this.setState({ showcopymodal: false })}
                    />
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        showmodalcontent: true,
                        contentmodaltitle: "Add Content",
                        content: "",
                        takeaway: "",
                        selecteduser: "",
                      })
                    }
                    //dashed border style
                    style={{ border: "2px dashed #aaa", borderRadius: "8px" }}
                    className="my-4 py-4 pointer d-flex justify-content-center font-weight-bold text-left defaultfontsize">
                    Add Content
                    <div className="">
                      <AddRoundedIcon
                        className="ml-4 text-primary"
                        fontSize="medium"
                      />
                    </div>
                  </div>
                  {knowledge.length > 0 &&
                    knowledge.map((i) => {
                      return (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/l/${i.itemid}`}
                          key={i.id}>
                          <DisplayRow i={i} />
                        </Link>
                        // <div
                        //   onClick={() =>
                        //     this.props.history.push(`/l/${i.itemid}`)
                        //   }
                        //   key={i.id}
                        //   className="alert alert-pure d-flex justify-content-between  pointer mt-2 roundcornernocolor p-3 shadow">
                        //   <div className="ml-3">
                        //     <Row>
                        //       <div className="text-left font-weight-bold">
                        //         {i.header}
                        //       </div>
                        //     </Row>
                        //     <Row>
                        //       <div className="mt-1 standardfontsize d-flex align-items-center text-muted">
                        //         <img
                        //           className=" rounded-circle mr-1"
                        //           src={i?.smallpic}
                        //           width={16}
                        //           height={16}
                        //         />
                        //         {i.owner} &bull;{" "}
                        //         {formatTimeSimple(new Date(i.created))}
                        //       </div>
                        //     </Row>
                        //   </div>

                        //   {i?.knowledgesmallpic && (
                        //     <div>
                        //       <img
                        //         className="rounded"
                        //         width={60}
                        //         height={60}
                        //         src={i.knowledgesmallpic}
                        //       />
                        //     </div>
                        //   )}
                        // </div>
                      );
                    })}
                  {/* 
                  <div className="d-flex justify-content-center my-4">
                    <PrintableCV user={this.props.user} data={data} />
                  </div> */}
                  {/* <div>
                    {(data?.bigpic?.length === 0 ||
                      data?.edu?.length === 0 ||
                      data?.exp?.length === 0 ||
                      data?.train?.length === 0) && (
                      <ProgressIndicator obj={data} />
                    )}
                  </div> */}
                  {/* <DisplaySeaExperience
                    data={data}
                    onAdd={() =>
                      this.setState({
                        showmodalseaexp: true,
                        seaexpmodaltitle: "Add Sea-Based Experience",
                        vessel: "",
                        company: "",
                        title: "",
                        type: "",
                        grt: "",
                        kw: "",
                        dwt: "",
                        startmonth: "",
                        startyear: "",
                        endmonth: "",
                        endyear: "",
                        sdate: normaliseDate(new Date()),
                        edate: normaliseDate(new Date()),
                      })
                    }
                    editExp={this.editSeaExp}
                  />
                  <DisplayEducation
                    data={data}
                    editSchool={this.editSchool}
                    onAdd={() =>
                      this.setState({
                        showmodalschool: true,
                        schoolmodaltitle: "Add Education",
                        school: "",
                        degree: "",
                        startmonth: "",
                        startyear: "",
                        endmonth: "",
                        endyear: "",
                        awards: "",
                      })
                    }
                  />
                  <DisplayDocument
                    data={data}
                    editTravel={this.editTravel}
                    onAdd={() =>
                      this.setState({
                        showmodaltravel: true,
                        travelmodaltitle: "Add Travel Document",
                        title: "",
                        documentnumber: "",
                        location: "",
                        sdate: normaliseDate(new Date()),
                        edate: normaliseDate(new Date()),
                      })
                    }
                  />
                  <DisplayTraining
                    data={data}
                    editTrain={this.editTrain}
                    onAdd={() =>
                      this.setState({
                        showmodaltrain: true,
                        trainmodaltitle: "Add Training/Certificate",
                        title: "",
                        certnumber: "",
                        location: "",
                        sdate: normaliseDate(new Date()),
                        edate: normaliseDate(new Date()),
                      })
                    }
                  /> */}

                  <div className="mt-4 pt-4"></div>
                  <div className="pointer mt-4 pt-4" onClick={this.doSubmit}>
                    Logout your account <ExitToAppIcon />
                  </div>
                  <div className="pt-4 mt-4"></div>
                  <Footer />
                </React.Fragment>
              )}
              {showphotoedit && (
                <PhotoEdit
                  state={this.state}
                  onClickSave={this.onClickSave}
                  onClickCancel={this.onClickCancel}
                  onZoomChange={this.onZoomChange}
                  setEditorRef={this.setEditorRef}
                  increaseZoom={this.increaseZoom}
                  decreaseZoom={this.decreaseZoom}
                  imageDataUrl={imageDataUrl}
                  zoom={zoom}
                  saving={saving}
                />
              )}
            </main>
          </div>
        </div>
        {/* Experience Start */}
        {/* <SeaExperienceModal
          state={this.state}
          onHide={() => this.setState({ showmodalseaexp: false })}
          onCancel={() => this.setState({ showmodalseaexp: false, errors: {} })}
          handleSDateChange={this.handleSDateChange}
          handleEDateChange={this.handleEDateChange}
          handleCurrentWorkingChange={this.handleCurrentWorkingChange}
          handleTitleChange={this.handleTitleChange}
          handleCompanyChange={this.handleCompanyChange}
          handleVesselChange={this.handleVesselChange}
          handleGRTChange={this.handleGRTChange}
          handleDWTChange={this.handleDWTChange}
          handleTypeChange={this.handleTypeChange}
          handleKWChange={this.handleKWChange}
          doSaveExp={this.doSaveSeaExp}
          confirmDeleteExp={this.confirmDeleteSeaExp}
          keydownhandler={this.keydownhandler}
          onHideDeleteExp={() => this.setState({ showdeleteseaexp: false })}
          doDeleteExp={this.doDeleteSeaExp}
          onCancelDelete={() =>
            this.setState({ showdeleteseaexp: false, errors: {} })
          }
        /> */}
        {/* <ExperienceModal
          state={this.state}
          onHide={() => this.setState({ showmodalexp: false })}
          onCancel={() => this.setState({ showmodalexp: false, errors: {} })}
          handleSDateChange={this.handleSDateChange}
          handleEDateChange={this.handleEDateChange}
          handleCurrentWorkingChange={this.handleCurrentWorkingChange}
          handleTitleChange={this.handleTitleChange}
          handleCompanyChange={this.handleCompanyChange}
          handleLocationChange={this.handleLocationChange}
          handleDescriptionChange={this.handleDescriptionChange}
          handleSkillsChange={this.handleSkillsChange}
          doSaveExp={this.doSaveExp}
          confirmDeleteExp={this.confirmDeleteExp}
          keydownhandler={this.keydownhandler}
          onHideDeleteExp={() => this.setState({ showdeleteexp: false })}
          doDeleteExp={this.doDeleteExp}
          onCancelDelete={() =>
            this.setState({ showdeleteexp: false, errors: {} })
          }
        /> */}
        {/* Experience End */}
        {/* Document Start */}
        {/* <DocumentModal
          state={this.state}
          onHide={() => this.setState({ showmodaltravel: false })}
          onCancel={() => this.setState({ showmodaltravel: false, errors: {} })}
          handleTitleChange={this.handleTitleChange}
          handleDocumentNumberChange={this.handleDocumentNumberChange}
          handleLocationChange={this.handleLocationChange}
          handleSDateChange={this.handleSDateChange}
          handleEDateChange={this.handleEDateChange}
          doSaveTravel={this.doSaveTravel}
          confirmDeleteTravel={this.confirmDeleteTravel}
          onHideDeleteTravel={() => this.setState({ showdeletetravel: false })}
          doDeleteTravel={this.doDeleteTravel}
          onCancelDelete={() =>
            this.setState({ showdeletetravel: false, errors: {} })
          }
        /> */}
        {/* Document End */}
        {/* Education Start */}
        {/* <EducationModal
          state={this.state}
          onHide={() => this.setState({ showmodalschool: false })}
          onCancel={() => this.setState({ showmodalschool: false, errors: {} })}
          handleSchoolChange={this.handleSchoolChange}
          handleDegreeChange={this.handleDegreeChange}
          handleStartMonthChange={this.handleStartMonthChange}
          handleStartYearChange={this.handleStartYearChange}
          handleEndMonthChange={this.handleEndMonthChange}
          handleEndYearChange={this.handleEndYearChange}
          handleBriefDescChange={this.handleBriefDescChange}
          doSaveSchool={this.doSaveSchool}
          confirmDeleteSchool={this.confirmDeleteSchool}
          doDeleteSchool={this.doDeleteSchool}
          onHideDeleteSchool={() => this.setState({ showdeleteschool: false })}
          onCancelDelete={() =>
            this.setState({ showdeleteschool: false, errors: {} })
          }
        /> */}
        {/* Education End */}
        <BackgroundSelectModal
          state={this.state}
          onHide={() => this.setState({ showbackground: false })}
          onCancel={() => this.setState({ showbackground: false, errors: {} })}
          doUpdateBackground={this.doUpdateBackground}
          onSelectColor={(o) => this.setState({ background: parseInt(o.id) })}
        />
        {/* Training Start */}
        <ContentModal
          state={this.state}
          onHide={() => this.setState({ showmodalcontent: false })}
          onCancel={() =>
            this.setState({ showmodalcontent: false, errors: {} })
          }
          actors={
            this.state.user.uid === "9yrgVHIm80QFx0dbfF59eXgxgt93"
              ? this.state.actors
              : []
          }
          handleActorChange={this.handleActorChange}
          handleContentChange={this.handleContentChange}
          doSaveContent={this.doSaveContent}
          confirmDeleteContent={() => {}}
          keydownhandler={() => {}}
          onHideDeleteContent={() => this.setState({ showmodalcontent: false })}
          doDeleteContent={() => {}}
          onCancelDelete={() =>
            this.setState({ showmodalcontent: false, errors: {} })
          }
        />
        {/* <TrainingModal
          state={this.state}
          onHide={() => this.setState({ showmodaltrain: false })}
          onCancel={() => this.setState({ showmodaltrain: false, errors: {} })}
          handleTitleChange={this.handleTitleChange}
          handleCertNumChange={this.handleCertNumChange}
          handleLocationChange={this.handleLocationChange}
          handleSDateChange={this.handleSDateChange}
          handleEDateChange={this.handleEDateChange}
          doSaveTrain={this.doSaveTrain}
          confirmDeleteTrain={this.confirmDeleteTrain}
          onHideDeleteTrain={() => this.setState({ showdeletetrain: false })}
          doDeleteTrain={this.doDeleteTrain}
          onCancelDelete={() =>
            this.setState({ showdeletetrain: false, errors: {} })
          } 
        />*/}
        {/* Training End */}
      </React.Fragment>
    );
  }
}

export default UserProfilePage;
