import React, { Component } from "react";
import { withRouter } from "react-router";
import Header from "../../common/header";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import SimpleMessage from "../../blocks/simpleMessageBlock";
import ThankyouContentPanel from "../component/thankyouContentPanel";
import { getMessageById } from "../service/thankyounote";
import { logEvent } from "../../../services/log";
import { Helmet } from "react-helmet";
import { getCurrentUserData } from "../../../services/user";
import { getLatestVersion } from "../../../services/getversion";
import ReloadBar from "../../common/reloadBar";
import { getNotifications } from "../../../services/notifications";

class ViewMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owner: {
        fullname: "",
        email: "",
        mobile: "+65",
        parish: 0,
        parishcode: "",
      },
      messageid: this.props.match.params.id,
      messagelist: [],
      errors: {},
      user: this.props.user,
      loading: true,
    };
  }

  getMessage = async (messageid) => {
    const { owner } = this.state;
    try {
      //retrieve data from firestore
      let message = await getMessageById(messageid);

      if (message) {
        const list = [];
        message.forEach((doc) => {
          list.push(doc.data());
        });
        // console.log({ list });
        logEvent("message_load", {
          email: owner.email,
          messageid,
        });
        let el = document.querySelector("title");
        const title = `Message from ${list[0].author}`;
        const content = `${list[0].author} sent you a ThankyNote 👍`;
        el.innerText = title;
        el = document.querySelector("meta[name='description']");
        el.setAttribute("content", content);
        const version = await getLatestVersion();

        this.setState({
          version,
          title,
          content,
          messagelist: list,
          loading: false,
        });
      }
    } catch (error) {
      logEvent("message_fail", {
        email: owner.email,
        message: error.message,
      });
      console.error("Messages not yet created");
      this.setState({ loading: false });
    }
  };

  getCurrentUserData = async () => {
    //retrieve data from firestore
    const messageid = this.state.messageid;
    try {
      if (this.state.user) {
        const uid = this.state.user.uid;

        const user = await getCurrentUserData(uid);

        if (user) {
          const country = user?.country || "";
          const notifications = await getNotifications(uid);
          const unreadnotifications = notifications.filter(
            (i) => i.open === false
          );
          this.setState({
            country,
            unreadnotifications: unreadnotifications.length,
          });
        }
      }
      await this.getMessage(messageid);
    } catch (error) {
      console.log(error);
      console.error("User not yet created");
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  render() {
    const {
      version,
      loading,
      messagelist,
      title,
      content,
      user,
      country,
      messageid,
      unreadnotifications,
    } = this.state;
    if (loading === true) {
      return <LoadingSpinnerNav hide="profile" hidehome={true} />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={content} />
          <link rel="canonical" href="https://thankynote.com" />
        </Helmet>
        {user ? (
          <Header
            user={user}
            smallpic={user.photoURL}
            hide={true}
            hidefriends={true}
            hidehome={true}
            notificationcount={unreadnotifications || 0}
          />
        ) : (
          <Header hide="profile" />
        )}
        <ReloadBar version={version} />

        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-1">
                <div id="messagesection" className="my-4">
                  {messagelist &&
                    messagelist.map((list) => (
                      <ThankyouContentPanel
                        key={list.id}
                        item={list}
                        user={user}
                        country={country}
                        messageid={messageid}
                      />
                    ))}
                  {messagelist.length === 0 && (
                    <SimpleMessage
                      title="Ooops"
                      content="Seems like the message does not exist anymore."
                      link="/home"
                      label="Back to Home"
                    />
                  )}
                </div>
                {/* {messagelist.length === 1 && (
                  <React.Fragment>
                    <LinkButton
                      type="button"
                      link="/announcement"
                      label="View all announcements"
                    />
                    <LinkButton type="link" link="/home" label="Back to Home" />
                  </React.Fragment>
                )}
                {messagelist.length > 1 && (
                  <React.Fragment>
                    <ScrollToTop />
                    <LinkButton type="link" link="/home" label="Back to Home" />
                  </React.Fragment>
                )} */}
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ViewMessage);
