import fire from "./fire";

export async function sendNotifications(type, sender, receiver, item) {
  //Somebody has replied to your ThankyNote: "Thank you Labsy for taking..."
  console.log(sender);
  console.log(receiver);
  console.log(item);
  const db = fire.firestore();
  let sendername = "Somebody";
  let senderimagelink = "";
  let senderid = "";
  if (sender) {
    sendername = sender.name;
    senderid = sender.id;
    senderimagelink = sender.photo;
  }
  let subject;
  if (type === "notereply") {
    subject = `${sendername} has replied to your ThankyNote`;
  } else if (type === "notesent") {
    subject = `${sendername} has sent you a ThankyNote`;
  } else if (type === "notesaved") {
    subject = `${sendername} has saved your ThankyNote`;
  }
  const notificationsRef = db.collection(`notifications`).doc();
  const notificationObj = {
    type: type,
    receivername: receiver.name,
    receiverid: receiver.id,
    receiverimagelink: receiver.photo || "",

    subject: subject,
    content: item.content,
    messageid: item.id,

    sendername: sendername,
    senderid: senderid,
    senderimagelink: senderimagelink,

    publiclink: `https://thankynote.com/p/${item.id}`,
    timestamp: new Date().getTime(),
    open: false,
  };
  console.log({ notificationObj });
  await notificationsRef.set(notificationObj);
}

export async function openNotifications(item) {
  //Somebody has replied to your ThankyNote: "Thank you Labsy for taking..."
  console.log(item);
  const db = fire.firestore();

  await db.doc(`notifications/${item.id}`).update({
    open: true,
  });
}

export async function getNotifications(uid) {
  const db = fire.firestore();
  try {
    const notificationObj = await db
      .collection(`notifications`)
      .where("receiverid", "==", uid)
      .orderBy("timestamp", "desc")
      .limit(20)
      .get();
    const notifications = [];
    notificationObj.forEach((doc) => {
      const data = doc.data();
      notifications.push({ ...data, id: doc.id });
    });
    return notifications; //.sort(compare);
    // return notifications.sort(compare);
  } catch (e) {
    console.log({ e });
  }

  return null;
}
