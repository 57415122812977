import React from "react";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";

const DisplayStreak = ({ label, data }) => {
  const append =
    data.count > 0 ? (
      <CheckCircleRoundedIcon className="text-success" />
    ) : (
      <Brightness1RoundedIcon style={{ color: "#dedede" }} />
    );

  return (
    <>
      {label}
      <br />
      {append}
    </>
  );
};

export default DisplayStreak;
