import React from "react";
import TitleCreator from "./titleCreator";

function HeadingSectionOnly({
  type,
  subtitle,
  json,
  content,
  owner,
  smallpic,
}) {
  return (
    <div className="row justify-content-center mx-2">
      <div className="appearslowly container px-0">
        <TitleCreator
          type={type}
          subtitle={subtitle}
          title={json.title}
          content={content}
          owner={owner}
          smallpic={smallpic}
        />

        <p className="pt-4 text-left">{json.description}</p>
      </div>
    </div>
  );
}

export default HeadingSectionOnly;
