import React, { Component } from "react";

class ScrollToTop extends Component {
  handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <button
        className="btn btn-lg btn-outline-primary btn-block"
        style={{ border: "1px solid #dddddd" }}
        onClick={this.handleClick}
      >
        Scroll to top
      </button>
    );
  }
}

export default ScrollToTop;
