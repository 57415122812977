export function getAwardContent(count) {
  let heading = "Awesome!";
  let subtext = "Be consistent. Practice gratitude everyday!";

  if (count === 7) {
    heading = "You're a champion!";
    // subtext =
    //   "You made it to 1 week straight of daily gratitude! 👏👏👏 Encourage your friends and family members to practice daily gratitude and be a Champion like you!";

    subtext = "You made it to a full week of daily gratitude! 👏👏👏";
  } else if (count === 6) {
    heading = "Gold!";
    subtext = "You're consistency has earned you a Gold Medal!";
  } else if (count === 5) {
    heading = "1st Place Award!";
    subtext = "You have done such an amazing job!";
  } else if (count === 4) {
    heading = "You got a Silver Medal!";
    subtext = "You are more grateful everyday!";
  } else if (count === 3) {
    heading = "You're consistent!";
    subtext = "You are getting more consistent everyday!";
  } else if (count === 2) {
    heading = "Great job!";
    subtext = "Wow! You rock!";
  }
  return [heading, subtext];
}
