import React from "react";
import LinkButton from "../../blocks/linkButtonBlock";
import { useParams } from "react-router-dom";
import Header from "../../common/header";
import Invite from "./invite";

function InviteFriends({ userhandle }) {
  const params = useParams();
  const { refid } = params;

  return (
    <>
      <Header
        hide="profile"
        type="backandlogo"
        hidehome={false}
        // showcreate={true}
        // user={user}
        shortcode={refid}
      />
      <div className="row justify-content-center mx-0">
        <div className="col-lg-8 px-1">
          <main className="container-fluid text-center mb-4 py-4">
            <div className="alert-success alert roundcornernocolor pt-4 mt-4">
              <Invite refid={refid} />
            </div>

            <LinkButton type="link" link="/home" label="Back to Home" />
          </main>
        </div>
      </div>
    </>
  );
}

export default InviteFriends;
