import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fire from "../../../services/fire";
import countryList from "react-select-country-list";
import { Image } from "react-bootstrap";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CheckIcon from "@material-ui/icons/Check";
import { getCurrentUserData } from "../../../services/user";
import LinkButton from "../../blocks/linkButtonBlock";

function AddFriend({ user }) {
  const [friend, setFriend] = useState(null);
  const [isFriend, setIsFriend] = useState(false);
  const params = useParams();
  const { refid } = params;

  useEffect(() => {
    const getDatas = async () => {
      //get user details here
      const db = fire.firestore();

      const doc = await db
        .collection("users")
        .where("userhandle", "==", refid)
        .get();
      //   console.log(doc);
      let bigpic,
        fullname,
        userhandle,
        email = "",
        school,
        degree,
        bio,
        country,
        exp,
        edu,
        train,
        currentpos,
        currentcompanydesc,
        currentcompany;
      let result = {};
      doc.forEach((item) => {
        let data = item.data();
        userhandle = data.userhandle;
        email = data.email;
        exp = data.exp;
        edu = data.edu;
        school = data.school;
        fullname = data.fullname;
        train = data?.train || [];
        degree = data.degree;
        currentcompany = data.currentcompany;
        currentcompanydesc = data.currentcompanydesc;
        bigpic = data.bigpic;
        bio = data.bio;
        currentpos = data.currentpos;
        country = data.country;
        result = data;
      });

      const countries = countryList().getData();
      //   console.log(countries);
      let countryStr = "";
      if (countries && country !== "") {
        //get country str
        for (let i = 0; i < countries.length; i++) {
          const item = countries[i];
          if (item.value === country) {
            countryStr = item.label;
            break;
          }
        }
      }
      const obj = { ...result, countryStr: countryStr };

      const follower = await db
        .doc(`followers/${obj.userid}/userfollowers/${user.uid}`)
        .get();

      if (follower && follower.exists) {
        setIsFriend(true);
      }
      setFriend(obj);
    };
    getDatas();
  }, []);

  const handleAddFriend = async () => {
    //handle add friend
    console.log(user);
    console.log(friend);
    //check if already friend
    const db = fire.firestore();
    const followers = await db
      .doc(`followers/${friend.userid}/userfollowers/${user.uid}`)
      .get();
    let isFollowing = false;
    if (followers && followers.exists) {
      isFollowing = true;
    } else {
      //following
      const userObj = await getCurrentUserData(user.uid);

      await db.doc(`followers/${friend.userid}/userfollowers/${user.uid}`).set({
        friendid: userObj.userid,
        smallpic: userObj?.smallpic || "",
        fullname: userObj?.fullname,
        country: userObj?.country,
        bio: userObj?.bio,
        userhandle: userObj?.userhandle,
      });
      //follower here
      await db
        .doc(`followings/${user.uid}/userfollowings/${friend.userid}`)
        .set({
          friendid: friend.userid,
          smallpic: friend?.smallpic || "",
          fullname: friend?.fullname,
          country: friend?.country,
          bio: friend?.bio,
          userhandle: friend?.userhandle,
        });

      setIsFriend(true);
    }
    // const friendlist = await db
    //   .collection("friends")
    //   .where("parish", "==", parish)
    //   //.orderBy("fullname", "asc")
    //   .get();
    // const list = [];
    // friendlist.forEach((doc) => {
    //   list.push(doc.data());
    // });
    // return list;
    // await db.doc(`friends/${userid}`).set({
    //   //   email,
    //   //   fullname,
    //   //   country,
    //   //   created: uniqNum,
    //   //   userid: userid,
    //   //   userhandle: userhandle?.trim(),
    //   //   bio: "",
    //   //   edu: [],
    //   //   exp: [],
    // });
  };

  return (
    <div className="row justify-content-center mx-0">
      <div className="col-lg-8 px-1">
        <main className="container-fluid text-center mb-4 py-4">
          {friend && (
            <div className="roundcornernocolor card p-4 shadow text-center">
              <div className="text-center">
                <div>
                  {!friend?.bigpic && (
                    <AccountCircleTwoToneIcon
                      className="text-primary text-center"
                      style={{ fontSize: "150px" }}
                    />
                  )}
                  {friend?.bigpic && (
                    <Image
                      src={friend?.bigpic}
                      width="150px"
                      height="150px"
                      alt=""
                      className="text-center"
                      roundedCircle
                    />
                  )}
                </div>
                <div>
                  <h2 className="pt-2 no mb-0">{friend.fullname}</h2>
                  {friend?.userhandle && (
                    <div className="text-dark py-1 my-0 ">
                      @{friend.userhandle}
                    </div>
                  )}
                  {friend?.bio && (
                    <div>
                      <h5 className="text-dark py-1 my-0 ">{friend?.bio}</h5>
                    </div>
                  )}
                  {friend?.country && (
                    <p className="py-1 mb-1 text-dark averagefontsize">
                      {friend?.countryStr}{" "}
                      <span className="">
                        <ReactCountryFlag
                          countryCode={friend?.country}
                          className="emojiFlag"
                          style={{
                            fontSize: "1.4em",
                            lineHeight: "1em",
                          }}
                        />
                      </span>
                    </p>
                  )}
                  {friend !== null &&
                    isFriend === false &&
                    friend?.userid?.trim() !== user?.uid?.trim() && (
                      <div className="py-4">
                        <button
                          className="btn btn-success"
                          onClick={handleAddFriend}>
                          <PersonAddIcon /> Follow
                        </button>
                      </div>
                    )}
                  {isFriend === true && (
                    <div className="py-4">
                      <button
                        className="btn btn-success"
                        // onClick={handleAddFriend}
                      >
                        <CheckIcon /> Following
                      </button>
                    </div>
                  )}
                  <div className="py-4">
                    <LinkButton type="link" link="/home" label="Back to Home" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default AddFriend;
