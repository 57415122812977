import React from "react";
import { Button, Modal, Toast } from "react-bootstrap";
import Joi from "joi-browser";
import Form from "./common/form";
import Header from "./common/header";
import fire from "../services/fire";
import firebase from "firebase/app";
import ScrollToTop from "./common/scrollToTop";
import LoadingSpinnerNav from "./common/loadingSpinnerNavbar";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import { getCurrentUserData } from "../services/user";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import { formatTime } from "../utils/utils";
import { Image as ReactImage } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReloadBar from "./common/reloadBar";
import { getLatestVersion } from "../services/getversion";
import { logEvent } from "../services/log";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Media } from "react-bootstrap";
// import DoneAllIcon from "@material-ui/icons/DoneAll";
import ReactCountryFlag from "react-country-flag";
import Picker from "emoji-picker-react";
import insertTextAtCursor from "insert-text-at-cursor";
import Loader from "./common/loader";
import IntroPage from "./common/introPage";
import ViewContent from "./thankyounote/component/viewContent";
import ViewContentBody from "./thankyounote/component/viewContentBody";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import StatPage from "./common/statPage";
import MilestoneIcon from "./thankyounote/component/milestoneIcon";
// import PublicIcon from "@material-ui/icons/Public";
import { getAwardContent } from "./thankyounote/service/contentObject";
import Typist from "react-typist";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InsertPhotoOutlinedIcon from "@material-ui/icons/InsertPhotoOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Zoom from "react-medium-image-zoom";
import DisplayStreak from "./common/displayStreak";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import ShareNote from "./share/shareNote";
import NoteButtonAction from "./blocks/noteButtonAction";
import { getGeneratedFriends } from "./friends/services/friends";
import FriendList from "./friends/component/friendList";
import NoFriends from "./friends/component/noFriends";
import Confetti from "react-confetti";
import { getNotifications, sendNotifications } from "../services/notifications";
import { convertFireStorageUrl } from "../utils/url";
import {
  getWebsiteFullDescription,
  getWebsiteName,
} from "../services/settings";
/** Project: Elijah */
class ThankYouSetup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        content: "",
      },
      owner: {
        fullname: "",
        email: "",
        mobile: "+65",
      },
      notes: [],
      emailsent: false,
      errors: {},
      user: this.props.user,
      friendlist: [],
      loading: true,
      submitting: false,
      showalert: false,
      showdeletemodal: false,
      lastpost: 0,
      alertstyle: "alert-danger",
      haschosen: false,
      sendingemail: false,
      clickedsection: 0,
      section: 0,
      sentmsgs: [],
      receivedmsgs: [],
      showemoji: false,
      text: "",
      hidebutton: false,
      noteslastline: 0,
      notesnextcount: 0,
      recvlastline: 0,
      recvnextcount: 0,
      sentlastline: 0,
      sentnextcount: 0,
      imagepost: [],
    };
  }
  maxLength = 360;
  maxNotesToLoad = 8;
  schema = {
    content: Joi.string()
      .max(this.maxLength)
      .required()
      .trim()
      .label("Thank You Note"),
  };

  computeStatsPercent = (list, created) => {
    const overallcount = list.length;
    const timenow = new Date().getTime();
    const days = Math.ceil((timenow - created) / (1000 * 60 * 60 * 24));
    //process here
    //count daily
    /*
    this will be based how many entries in 1 day
    */
    //get cur date
    const curdate = new Date();
    const year = curdate.getFullYear();
    const month = curdate.getMonth();
    const day = curdate.getDate();
    //get first 3 only

    const first3 = list.slice(0, 3);
    const dailyentries = first3.filter((item) => {
      let itemdate = new Date(item.created);
      if (
        year === itemdate.getFullYear() &&
        month === itemdate.getMonth() &&
        day === itemdate.getDate()
      ) {
        return item;
      }
    });
    let daily = 0;
    let dailycount = dailyentries.length;

    if (dailycount >= 3) {
      daily = 100;
    } else {
      daily = dailycount === 0 ? 0 : Math.floor((dailycount / 3) * 100);
    }
    //count weekly
    /*
    to get 100%, minimum must be 7days * 3 = 21
    */
    const first21 = list.slice(0, 21);
    const weeklyentries = first21.filter((item) => {
      let age = Math.ceil((timenow - item.created) / (1000 * 60 * 60 * 24));
      if (age <= 21) {
        return item;
      }
    });
    let weekly = 0;
    let weeklycount = weeklyentries.length;
    if (weeklycount >= 21) {
      weekly = 100;
    } else {
      weekly = weeklycount === 0 ? 0 : Math.floor((weeklycount / 21) * 100);
    }
    //count overall
    /*
    this will be based since he joined x 3
    */
    const max = days * 3;
    let overall = 0;
    if (overallcount >= max) {
      overall = 100;
    } else {
      overall = overallcount === 0 ? 0 : Math.floor((overallcount / max) * 100);
    }

    return [daily, weekly, overall];
  };

  computeStats = (list, created) => {
    const overallcount = list.length;
    const timenow = new Date().getTime();
    // const days = Math.ceil((timenow - created) / (1000 * 60 * 60 * 24));
    //process here
    //count daily
    /*
    this will be based how many entries in 1 day
    */
    //get cur date
    const curdate = new Date();
    const year = curdate.getFullYear();
    const month = curdate.getMonth();
    const day = curdate.getDate();
    //get first 3 only

    const first3 = list; //.slice(0, 3);
    const dailyentries = first3.filter((item) => {
      let itemdate = new Date(item.created);
      if (
        year === itemdate.getFullYear() &&
        month === itemdate.getMonth() &&
        day === itemdate.getDate()
      ) {
        return item;
      }
    });
    let daily = 0;
    let dailycount = dailyentries.length;
    daily = dailycount;
    // if (dailycount >= 3) {
    //   daily = 100;
    // } else {
    //   daily = dailycount === 0 ? 0 : Math.floor((dailycount / 3) * 100);
    // }
    //count weekly
    /*
    to get 100%, minimum must be 7days * 3 = 21
    */
    const first21 = list; //.slice(0, 21);
    const weeklyentries = first21.filter((item) => {
      let age = Math.ceil((timenow - item.created) / (1000 * 60 * 60 * 24));
      if (age <= 7) {
        return item;
      }
    });
    let weekly = 0;
    let weeklycount = weeklyentries.length;
    weekly = weeklycount;
    // if (weeklycount >= 21) {
    //   weekly = 100;
    // } else {
    //   weekly = weeklycount === 0 ? 0 : Math.floor((weeklycount / 21) * 100);
    // }
    //count overall
    /*
    this will be based since he joined x 3
    */
    // const max = days * 3;
    let overall = overallcount;
    // if (overallcount >= max) {
    //   overall = 100;
    // } else {
    //   overall = overallcount === 0 ? 0 : Math.floor((overallcount / max) * 100);
    // }
    // console.log({ overallcount });

    return [daily, weekly, overall];
  };

  getLast7Days = (list) => {
    const timenow = new Date().getTime();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    //compose last 7 days array
    let week = []; //new Map();
    for (let i = 6; i >= 0; i--) {
      const d = new Date();
      d.setDate(d.getDate() - i);
      const month = months[d.getMonth()];
      const day = d.getDate();
      const date = `${month} ${day}`;
      week.push({ date, counter: 0 });
    }
    for (let i = 0; i < list.length; i++) {
      let created = list[i].created;
      let age = Math.ceil((timenow - created) / (1000 * 60 * 60 * 24));
      const curdate = new Date(created);
      const month = months[curdate.getMonth()];
      const day = curdate.getDate();
      const date = `${month} ${day}`;
      if (age <= 8) {
        week.filter((item) => {
          if (item.date === date) {
            item.counter++;
            return item;
          }
        });
      } else {
        break;
      }
    }
    return week;
  };

  async getNotes() {
    //retrieve data from firestore
    const { userid, created } = this.state.owner;
    try {
      let noteslastline = 0;
      console.log("before await");
      if (this.mounted) {
        const querySnapshot = await fire
          .firestore()
          .collection("notes")
          .where("authorid", "==", userid)
          .orderBy("created", "desc")
          .limit(this.maxNotesToLoad)
          .get();
        console.log("after await");

        let list = [];
        let lastpost = 0;
        let lastpostfound = false;
        console.log("before loop");
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          if (lastpostfound === false) {
            lastpost = data.created;
            lastpostfound = true;
          }
          list.push(data);
        });
        let notesnextcount = 0;
        if (list.length > this.maxNotesToLoad - 1) {
          notesnextcount = list.length - 1;
          list.pop();
          noteslastline = list[list.length - 1].created;
        }
        // console.log(list.length);
        const [daily, weekly, overall] = this.computeStats(list, created);
        const activities = this.getLast7Days(list);
        console.log({ notesnextcount });
        this.setState({
          weekly,
          overall,
          daily,
          activities,
          notes: list,
          notesnextcount,
          noteslastline,
          lastpost,
          loading: false,
          dataloaded: true,
        });
      }
    } catch (error) {
      console.error("Announcements not yet created");
      this.setState({ loading: false });
    }
  }

  async getMoreNotes() {
    //retrieve data from firestore
    const { userid } = this.state.owner;
    try {
      console.log("before await");
      if (this.mounted) {
        let noteslastline = this.state.noteslastline;

        const querySnapshot = await fire
          .firestore()
          .collection("notes")
          .where("authorid", "==", userid)
          .where("created", "<", noteslastline)
          .orderBy("created", "desc")
          .limit(this.maxNotesToLoad)
          .get();
        console.log("after await");

        const list = [];
        console.log("before loop");
        querySnapshot.forEach((doc) => {
          list.push(doc.data());
        });
        let notesnextcount = 0;
        if (list.length > this.maxNotesToLoad - 1) {
          notesnextcount = list.length - 1;
          list.pop();
          noteslastline = list[list.length - 1].created;
        }
        const latestlist = [...this.state.notes, ...list];
        this.setState({
          notesnextcount,
          noteslastline,
          notes: latestlist,
        });
      }
    } catch (error) {
      console.error("Announcements not yet created");
      this.setState({ loading: false });
    }
  }

  async getPureNotes() {
    //retrieve data from firestore
    const { userid } = this.state.owner;
    try {
      const querySnapshot = await fire
        .firestore()
        .collection("notes")
        .where("authorid", "==", userid)
        .orderBy("created", "desc")
        .limit(21)
        .get();

      let list = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        list.push(data);
      });
      const user = await getCurrentUserData(userid);
      logEvent("notes_click", { email: user.email });
      console.log(user);
      //try to get the latest notification count
      this.setState({
        notes: list,
        owner: { ...this.state.owner, totalnotes: user.totalnotes },
      });
    } catch (error) {
      console.error("Notes not yet created");
      this.setState({ loading: false });
    }
  }

  compareReceived(a, b) {
    if (a.received < b.received) {
      return 1;
    }
    if (a.received > b.received) {
      return -1;
    }
    return 0;
  }

  async getSent() {
    //retrieve data from firestore
    const { userid, sentclick } = this.state.owner;
    try {
      fire
        .firestore()
        .collection("notes")
        .where("authorid", "==", userid)
        .where("hasrecepient", "==", true)
        .orderBy("received", "desc")
        .limit(21)
        .get()
        .then(async (querySnapshot) => {
          let sentmsgs = [];
          // let lastpost = 0;
          // let lastpostfound = false;
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            // if (lastpostfound === false) {
            //   // lastpost = data.created;
            //   lastpostfound = true;
            // }
            sentmsgs.push(data);
          });
          console.log(sentmsgs.length);
          sentmsgs = sentmsgs.sort(this.compareReceived);
          const user = await getCurrentUserData(userid);
          const totalreceived = user?.totalreceived || 0;
          const totalsent = user?.totalsent || 0;
          logEvent("sent_click", { email: user.email });
          if (!sentclick) {
            const userRef = fire.firestore().collection("users").doc(userid);

            await userRef.update({
              sentclick: true,
            });
          }
          this.setState({
            sentmsgs,
            owner: {
              ...this.state.owner,
              totalreceived,
              totalsent,
              sentclick: true,
            },
          });
        });
    } catch (error) {
      console.error("Error get data");
      console.error(error);
    }
  }

  async getReceived() {
    //retrieve data from firestore
    const { userid, recvclick } = this.state.owner;
    try {
      fire
        .firestore()
        .collection("notes")
        .where("recepientid", "==", userid)
        .orderBy("received", "desc")
        .limit(21)
        .get()
        .then(async (querySnapshot) => {
          let receivedmsgs = [];
          // let lastpost = 0;
          // let lastpostfound = false;
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            // if (lastpostfound === false) {
            //   // lastpost = data.created;
            //   lastpostfound = true;
            // }
            receivedmsgs.push(data);
          });
          // console.log({ receivedmsgs });
          console.log(receivedmsgs.length);
          const user = this.state.user;
          logEvent("recv_click", { email: user.email });
          if (!recvclick) {
            const userRef = fire.firestore().collection("users").doc(userid);

            await userRef.update({
              recvclick: true,
            });
          }
          this.setState({
            receivedmsgs,
            owner: { ...this.state.owner, recvclick: true },
          });
        });
    } catch (error) {
      console.error("Error get data");
      console.error(error);
    }
  }

  async getCurrentUserData() {
    //retrieve data from firestore

    const uid = this.state.user.uid;

    try {
      const user = await getCurrentUserData(uid);

      if (user) {
        const {
          fullname,
          email,
          mobile,
          userid,
          created,
          totalnotes,
          smallpic,
          totalreceived,
          totalsent,
          country,
          init,
          streak,
          lastentry,
          sentclick,
          recvclick,
        } = user;
        if (init) {
          this.props.history.push("/home");
          return;
        }
        // console.log({ streak });
        const version = await getLatestVersion();
        const notifications = await getNotifications(userid);
        const unreadnotifications = notifications.filter(
          (i) => i.open === false
        );
        console.log({ notifications });
        console.log("after version");
        if (this.mounted) {
          this.setState({
            version,
            streak: streak || [],
            lastentry,
            unreadnotifications: unreadnotifications.length,
            owner: {
              userid,
              fullname,
              email,
              mobile,
              created,
              totalnotes,
              totalreceived: totalreceived || 0,
              totalsent: totalsent || 0,
              country: country || "",
              smallpic: smallpic || "",
              init: init || false,
              sentclick: sentclick || false,
              recvclick: recvclick || false,
            },
          });
          // this.getNotes();
          this.setState({ loading: false });
          console.log("after getNotes");
        }
      } else {
        throw new Error("No user found!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      //isSuperAdminRole(curUser) || isParishPriestRole(curUser)) {
      await this.getCurrentUserData();
      let el = document.querySelector("title");
      const title = getWebsiteName();
      const content = getWebsiteFullDescription();
      el.innerText = title;
      el = document.querySelector("meta[name='description']");
      el.setAttribute("content", content);

      window.scrollTo(0, 0);
    } else {
      this.props.history.push("/home");
      return;
    }
  }

  submitData = async () => {
    // Call the server
    this.setState({ submitting: true, showemoji: false });

    const { notes, lastpost, version } = this.state;
    const { fullname, userid, created, smallpic, country, email } =
      this.state.owner;
    const { content } = this.state.data;

    const user = await getCurrentUserData(userid);

    let { streak, lastentry } = user;
    if (!streak) {
      streak = [];
    }
    const timenow = new Date();
    // const timetext = `August 19, 2021 ${timenow.getHours()}:${timenow.getMinutes()}:${timenow.getSeconds()}`;
    // const timetext = `August 29, 2021 1:04:40`;
    let timetext = timenow;
    console.log(timetext);
    const datenow = new Date(timetext);
    console.log(datenow);
    const day = datenow.getDay();
    console.log(day);
    const date = datenow.getDate();
    const year = datenow.getFullYear();
    let showstats = true;
    console.log(datenow.toDateString());
    console.log(streak);
    console.log(`Last Entry: ${lastentry}`);
    let expired = false;

    const beforeOneWeek = new Date(datenow.getTime() - 60 * 60 * 24 * 7 * 1000);
    const beforeOneWeek2 = new Date(beforeOneWeek);
    const lastweekday = beforeOneWeek.getDay();
    console.log({ lastweekday });
    const diffDate =
      beforeOneWeek.getDate() - lastweekday + (lastweekday === 0 ? -6 : 0);
    const lastSunday = new Date(beforeOneWeek.setDate(diffDate)).toDateString();
    const lastSaturday = new Date(
      beforeOneWeek2.setDate(diffDate + 6)
    ).toDateString();

    console.log({ lastSunday });
    console.log({ lastSaturday });
    if (lastentry) {
      //check if entry was last week
      console.log(new Date(lastentry));
      console.log(new Date(lastSaturday));
      if (new Date(lastentry) < new Date(lastSaturday)) {
        expired = true;
      }
    }

    console.log({ expired });
    if (streak.length && !expired) {
      //check if same day, if not, this is the first
      const streakobj = streak[day];
      console.log(streakobj);
      if (streakobj.count === 0) {
        console.log("here");
        showstats = true;
        //first ever record of streak for the day
        streakobj.count = 1;
        streakobj.created = datenow.getTime();
      } else {
        //already has a value
        const dateadded = new Date(streakobj.created);
        console.log({ dateadded });
        if (
          day === dateadded.getDay() &&
          date === dateadded.getDate() &&
          year === dateadded.getFullYear()
        ) {
          showstats = false;
          //not first day, add
          streakobj.count = streakobj.count + 1;
          console.log("inside here");
        } else {
          showstats = true;
          streakobj.count = streakobj.count + 1;
          streakobj.created = datenow.getTime();
        }
      }
      // console.log({ showstats });
      //if sunday, clear the rest
      if (day === 0) {
        console.log("Sunday");
        //check if has existing record
        const dateadded = new Date(streakobj.created);
        console.log({ dateadded });
        if (
          (day === dateadded.getDay() ||
            date === dateadded.getDate() ||
            year === dateadded.getFullYear()) &&
          showstats !== true
        ) {
          showstats = false;
          //not first entry, add
          console.log("here1");
          // streakobj.count = streakobj.count + 1;
        } else {
          console.log("here2");
          showstats = true;
          //record entry for sunday
          streak[0] = { count: 1, created: datenow.getTime() };
          for (let i = 1; i < 7; i++) {
            streak[i] = { count: 0, created: 0 };
          }
        }
      }
    } else {
      //if empty, this is the very first
      showstats = true;
      for (let i = 0; i < 7; i++) {
        if (day === i) {
          streak[i] = { count: 1, created: datenow.getTime() };
        } else {
          streak[i] = { count: 0, created: 0 };
        }
      }
    }

    const uniqNum = datenow.getTime();
    if (uniqNum - lastpost <= 5 * 1000) {
      this.setState({ submitting: false, slowdown: true });
      return;
    }

    try {
      const notesref = fire.firestore().collection("notes").doc();
      //we create a human-readable URL so it won't look like a spam
      const author = fullname.trim();
      const humanizeauthor = author
        .replace(/[^A-Za-z0-9]/g, " ")
        .replace(/\s+/g, "-");
      //get first 8 for notesref.id
      const octoid = notesref.id.slice(0, 8);
      const from = `from-${humanizeauthor}`;
      const humanizecontent = content
        .replace(/[^A-Za-z0-9]/g, " ")
        .replace(/\s+/g, "-")
        .slice(0, 30);
      const id = `${humanizecontent}-${from}-${octoid}`;
      /** if showstats === true
       * 1. count the entries
       * 2. save date and count
       * 3. write to db
       */

      let count = 0;

      if (showstats === true) {
        streak.forEach((item) => {
          if (item.count > 0) {
            count++;
          }
        });
        const milestonesref = fire.firestore().collection("milestones").doc();
        await milestonesref.set({
          count,
          created: uniqNum,
          authorid: userid,
          author: author,
          smallpic,
          country,
          email,
        });
      }

      let smallphoto = "";
      let bigphoto = "";
      let mediumphoto = "";
      let imagepost = this.state.imagepost;

      //upload photo here
      if (imagepost?.length) {
        // const image = this.state.imagedata;
        // const image = imagepost[0].data_url;
        const uploadpath = `images/${userid}`;
        const uniqNum = new Date().getTime();
        const filename = `${uniqNum}.jpeg`;

        const projectStorage = fire.storage();
        const storageRef = projectStorage.ref(`${uploadpath}/${filename}`);

        await storageRef.put(this.state.selectedfile);
        // await fire
        //   .storage()
        //   .ref(uploadpath)
        //   .child(filename)
        //   .putString(image, "data_url");

        logEvent("photo_post_uploaded_success", { email: email });

        const imageDir = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images`;
        const thumb = encodeURIComponent(
          `/${userid}/thumbs/${uniqNum}_200x200.jpeg`
        );
        const medthumb = encodeURIComponent(
          `/${userid}/thumbs/${uniqNum}_300x300.jpeg`
        );
        const bigthumb = encodeURIComponent(
          `/${userid}/thumbs/${uniqNum}_1000x1000.jpeg`
        );
        const photo = encodeURIComponent(`/${userid}/${filename}`);
        smallphoto = `${imageDir}${thumb}?alt=media`;
        mediumphoto = `${imageDir}${medthumb}?alt=media`;
        // bigphoto = `${imageDir}${photo}?alt=media`;
        bigphoto = `${imageDir}${bigthumb}?alt=media`;
      }
      // console.log({ bigphoto });
      /** save the count  also */
      const docData = {
        email,
        uid: notesref.id,
        id: id,
        authorid: userid,
        author: author,
        created: uniqNum,
        smallpic,
        content,
        country,
        count,
        smallphoto: smallphoto,
        mediumphoto: mediumphoto,
        bigphoto: bigphoto,
      };

      await notesref.set(docData);

      console.log(`Newest Entry: ${datenow}`);

      //update counter
      const userRef = fire.firestore().collection("users").doc(userid);

      await userRef.update({
        totalnotes: firebase.firestore.FieldValue.increment(1),
        streak,
        lastentry: datenow.toDateString(),
      });

      //get latest count here
      const latestdata = await userRef.get();
      const owner = this.state.owner;
      if (latestdata.exists) {
        owner.totalnotes = latestdata.data().totalnotes;
      }
      const list = [{ ...docData, bigphoto: imagepost[0] }, ...notes];
      const [daily, weekly, overall] = this.computeStats(list, created);
      //get latest version here
      const latestversion = await getLatestVersion();
      if (latestversion !== version) {
        //update here
      }
      window.scrollTo(0, 0); //go up
      logEvent("post_success", { email: email });

      this.setState({
        daily,
        weekly,
        overall,
        owner,
        notes: list,
        lastpost: uniqNum,
        submitting: false,
        slowdown: false,
        data: {
          content: "",
        },
        showstats: showstats,
        streak,
        version: latestversion,
        imagepost: [],
        hidebutton: false,
      });
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  doSubmit = async () => {
    await this.submitData();
  };

  handleDelete = (id, timestamp) => {
    this.setState({
      showdeletemodal: true,
      idtodelete: id,
      timestamp: timestamp,
      deleting: false,
    });
  };

  doDelete = async () => {
    const owner = this.state.owner;
    const userid = owner.userid;
    const created = owner.created;
    const id = this.state.idtodelete;
    try {
      const noteitem = await fire
        .firestore()
        .collection("notes")
        .where("authorid", "==", userid)
        .where("id", "==", id)
        .get();

      noteitem.forEach((doc) => {
        doc.ref.delete();
      });

      //update counter
      const userRef = fire.firestore().collection("users").doc(userid);
      await userRef.update({
        totalnotes: firebase.firestore.FieldValue.increment(-1),
      });
      const { notes } = this.state;
      let updatednotes = notes.filter((item) => item.id !== id);
      const [daily, weekly, overall] = this.computeStats(updatednotes, created);
      //get latest count here
      const latestdata = await userRef.get();
      if (latestdata.exists) {
        owner.totalnotes = latestdata.data().totalnotes;
      }
      this.setState({
        daily,
        weekly,
        overall,
        owner,
        notes: updatednotes,
        submitting: false,
        showdeletemodal: false,
        showalert: true,
        text: "Deleted  🗑️",
        alertstyle: "alert-danger",
        data: {
          content: "",
        },
      });
    } catch (error) {
      console.log("Unable to Delete:", error);
      this.setState({
        submitting: false,
      });
    }
  };

  handleClose = () => {
    this.setState({
      viewusermodal: { showmodal: false, obj: null },
      showdeletemodal: false,
      showsendtomodal: false,
      showcopymodal: false,
      viewawardmodal: false,
      idtodelete: "",
      deleting: false,
      viewentrymodal: false,
      copied: false,
      haschosen: false,
      sendingemail: false,
      sentok: false,
      sentdone: false,
      senterr: false,
    });
  };

  handleSendNoteEmail = async () => {
    this.setState({ sendingemail: true });
    //get latest email
    // console.log(this.state.selectedentry);
    const friendid = this.state.selectedentry.friendid;
    const user = await getCurrentUserData(friendid);
    // console.log(user.email);
    //get selected id
    const noteid = this.state.idtosend;
    // console.log(noteid);
    //send here
    const result = await this.handleSendEmail(user.email, noteid);
    if (result) {
      //success
      const itemtosend = this.state.itemtosend;
      const friend = this.state.selectedentry;
      const sender = {
        id: itemtosend.authorid,
        name: itemtosend.author,
        photo: itemtosend.smallpic,
      };
      const receiver = {
        id: friend.friendid,
        name: friend.friendname,
        photo: friend.friendpic || "",
      };
      await sendNotifications("notesent", sender, receiver, itemtosend);

      this.setState({ sentdone: true, sentok: true });
    } else {
      //error
      this.setState({ sentdone: false, senterr: true });
    }
    this.setState({ haschosen: false });
    // this.handleClose();
  };

  handleDelete = (id, timestamp) => {
    this.setState({
      showdeletemodal: true,
      idtodelete: id,
      timestamp: timestamp,
      deleting: false,
    });
  };

  handleSendNoteTo = async (item) => {
    console.log(item);
    // console.log(id, timestamp);
    const id = item.id;
    const timestamp = item.created;
    //get friends
    let friends = [];
    //TODO: try to generate friends here
    // if (this.state.friendlist.length === 0) {
    friends = await getGeneratedFriends(this.state.user.uid);
    // console.log({ friends });
    // }
    this.setState({
      itemtosend: item,
      friendlist: friends,
      showsendtomodal: true,
      idtosend: id,
      timestamp: timestamp,
      deleting: false,
    });
  };

  getRemarks = () => {
    const { daily, weekly, overall, owner } = this.state;
    let remarks;
    if (daily === 100) {
      remarks = "You are very grateful today!";
    } else if (weekly === 100) {
      remarks = "You did great this week!";
    } else if (daily === 0 && weekly === 0 && overall === 0) {
      remarks = `Welcome ${owner.fullname}!`;
    }
    if (daily === 100 && weekly === 100 && overall === 100) {
      remarks = "Perfect!";
    }
    return <h3 className="text-success">{remarks}</h3>;
  };

  handleSendEmail = async (recepient, noteid) => {
    const { owner, itemtosend } = this.state;
    let titleText = itemtosend.content;
    if (titleText.length > 30) {
      titleText = `${this.shorten(titleText, 30)}...`;
    }
    const header = `ThankyNote from ${owner.fullname.trim()}`;
    const param = {
      email: recepient.trim(),
      name: owner.fullname.trim(),
      shortmessage: titleText,
      header: header,
      publiclink: `https://thankynote.com/p/${noteid}`,
    };
    const thankyouMessage = fire
      .functions("asia-east2")
      .httpsCallable("thankyouMessage");
    try {
      const result = await thankyouMessage(param);
      if (result.data.status === 0) {
        return true;
      }
    } catch (err) {
      console.log("Error:", err);
    }
    return false;
  };

  handleKeyDown = async (e) => {
    // e.preventDefault();
    const { content } = this.state.data;

    if (
      content &&
      content.trim() !== "" &&
      content.trim().length > this.maxLength
    ) {
      const errors = this.validate();
      this.setState({ errors: errors || {} });
    }
    return;
  };

  deleteModal = () => {
    const showdeletemodal = this.state.showdeletemodal;
    const deleting = this.state.deleting;
    return (
      <Modal
        show={showdeletemodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title className="text-dark font-weight-bold">
            Delete Note?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="font-weight-bold mt-0 mb-4 text-center">
          <CancelIcon className="text-danger" style={{ fontSize: "50px" }} />
          <br />
          <br />
          This cannot be undone.
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button
            disabled={deleting}
            variant="link"
            className="forcenoline noline mr-2"
            onClick={this.handleClose}>
            <span className="text-muted forcenoline noline">Cancel</span>
          </Button>
          {deleting !== true && (
            <Button variant="danger" onClick={() => this.doDelete()}>
              Delete
            </Button>
          )}
          {deleting === true && (
            <Button disabled variant="danger" onClick={() => this.doDelete()}>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>{" "}
              Deleting...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  selectRecepient = (entry) => {
    console.log({ entry });
    this.setState({ haschosen: true, selectedentry: entry });
  };

  sendToModal = () => {
    const showsendtomodal = this.state.showsendtomodal;
    const haschosen = this.state.haschosen;
    const friends = this.state.friendlist;
    const sendingemail = this.state.sendingemail;
    const sentdone = this.state.sentdone;
    const sentok = this.state.sentok;
    const senterr = this.state.senterr;
    return (
      <Modal
        show={showsendtomodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        {sentdone && sentok === true && (
          <Confetti
            style={{ zIndex: "2000" }}
            recycle={false}
            opacity={1}
            gravity={0.2}
          />
        )}
        <Modal.Header style={{ border: "none" }} closeButton>
          <Modal.Title className="text-dark font-weight-bold">
            Send ThankyNote
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-0 text-center">
          {!sentdone && !haschosen && (
            <>
              <div className="pt-3 pointer text-center">
                {friends &&
                  friends.map((entry, index) => {
                    return (
                      <FriendList
                        key={index}
                        entry={entry}
                        action="send"
                        clickHandler={this.selectRecepient}
                      />
                    );
                  })}
              </div>
              {friends.length === 0 && <NoFriends />}
            </>
          )}
          {!sentdone && haschosen && (
            <div className="pt-3 text-left">
              Send to{" "}
              <span className="text-primary font-weight-bold">
                {this.state.selectedentry.friendname}?
              </span>
            </div>
          )}
          {sentdone && sentok === true && (
            <>
              <CheckCircleIcon
                className="text-success"
                style={{ fontSize: "50px" }}
              />
              <br />
              <br />
              ThankyNote sent{" "}
              <span role="img" aria-label="thumb">
                👍
              </span>
            </>
          )}
          {!sentdone && senterr === true && (
            <>
              <CancelIcon
                className="text-danger"
                style={{ fontSize: "50px" }}
              />
              <br />
              <br />
              Error sending{" "}
              <span role="img" aria-label="sad">
                😞
              </span>
            </>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          {!haschosen && (
            <Button
              variant="link"
              className="forcenoline noline mr-2"
              onClick={this.handleClose}>
              <span className="text-muted forcenoline noline">Close</span>
            </Button>
          )}
          {haschosen && !sendingemail && (
            <Button
              variant="success"
              className="mr-2"
              onClick={this.handleSendNoteEmail}>
              <span>Send</span>
            </Button>
          )}
          {haschosen && sendingemail && (
            <button disabled className="btn btn-success float-right">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>{" "}
              Sending...
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  copyModal = () => {
    const showcopymodal = this.state.showcopymodal;
    return (
      <Modal
        show={showcopymodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title className="text-dark font-weight-bold">
            Note Copied
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-0 mb-4 text-center">
          <CheckCircleIcon
            className="text-success"
            style={{ fontSize: "50px" }}
          />
          <br />
          <br />
          {this.state.text}
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button
            variant="link"
            className="forcenoline noline mr-2"
            onClick={this.handleClose}>
            <span className="text-muted forcenoline noline">Got it</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  viewUserModal = () => {
    const viewusermodal = this.state.viewusermodal;
    if (!viewusermodal && !viewusermodal?.obj) {
      return null;
    }
    const { obj, showmodal, type } = viewusermodal;
    // console.log(type);
    let showstreak = false;
    let daylabel = [];

    if (obj) {
      const data = obj?.streak;
      if (data && data.length > 0) {
        daylabel = [
          { id: 1, label: "S", data: data[0] },
          { id: 2, label: "M", data: data[1] },
          { id: 3, label: "T", data: data[2] },
          { id: 4, label: "W", data: data[3] },
          { id: 5, label: "T", data: data[4] },
          { id: 6, label: "F", data: data[5] },
          { id: 7, label: "S", data: data[6] },
        ];
        showstreak = true;
      }
    }

    return (
      <Modal
        show={showmodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}></Modal.Header>
        <Modal.Body className="mt-0 mb-4 text-center appearslowly">
          <ViewContent
            item={obj}
            design={0}
            type={type === "sent" ? "sender" : "receiver"}
          />

          <div className="row pt-2 px-4">
            <div className=" col px-0">
              <h2 className="py-0 my-0 text-center text-dark">
                {obj?.totalnotes || 0}
              </h2>
              <p className="text-center text-muted py-0">NOTES</p>
            </div>

            <div className=" col  px-0">
              <h2 className="py-0 my-0 text-center text-dark">
                {obj?.totalsent || 0}
              </h2>

              <p className="text-center text-muted py-0">SENT</p>
            </div>
            <div className=" col px-0">
              <h2 className="py-0 my-0 text-center text-dark">
                {obj?.totalreceived || 0}
              </h2>

              <p className="text-center text-muted py-0">RECEIVED</p>
            </div>
          </div>

          {showstreak && (
            <div className="card lightframecolor roundcornernocolor">
              <div className="row p-4">
                {daylabel.map((item) => (
                  <div key={item.id} className="col text-center px-0">
                    {/* {this.displayData(item.label, item.data)} */}
                    <DisplayStreak label={item.label} data={item.data} />
                  </div>
                ))}
              </div>
            </div>
          )}
          {!showstreak && (
            <div className="text-center py-4">No Activities Yet</div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button
            variant="link"
            className="forcenoline noline mr-2"
            onClick={this.handleClose}>
            <span className="text-muted forcenoline noline">Close</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  onEmojiClick = (event, emojiObject) => {
    console.log(emojiObject);
    const el = document.getElementById("content");
    insertTextAtCursor(el, emojiObject.emoji);
  };

  copyLink = () => {};

  openNew = (item) => {
    const url = `https://thankynote.com/p/${item.id}`;
    const win = window.open(url, "_blank");
    win.focus();
  };

  handleCopy = (source = "stream", entry) => {
    const logname = `link_copy_${source}`;
    logEvent(logname, {
      text: `link copy ${source}`,
    });
    if (source === "stream") {
      this.setState({
        // copied: true,
        selecteditem: entry,
        // showalert: true,
        showcopymodal: true,
        text: "Note copied! 👍 You may now share this ThankyNote 📝 with your friend.",
        alertstyle: "alert-success",
      });
      return;
    }
    this.setState({
      copied: true,
      text: "Note copied! 👍 You may now share this ThankyNote 📝 with your friend.",
    });
  };

  handleClick = async (section) => {
    if (section === 0) {
      //see all notes
      const version = await getLatestVersion();
      this.getPureNotes();

      this.setState({
        version,
        clickedsection: section,
        section,
        getsent: false,
        getreceived: false,
      });
    } else if (section === 1) {
      this.setState({ getsent: true });

      const version = await getLatestVersion();
      await this.getSent();

      //see all sent
      this.setState({
        version,
        clickedsection: section,
        section,
        getsent: false,
        getreceived: false,
      });
    } else if (section === 2) {
      this.setState({ getreceived: true });

      const version = await getLatestVersion();
      await this.getReceived();

      //see all received
      this.setState({
        version,
        clickedsection: section,
        section,
        getsent: false,
        getreceived: false,
      });
    }
  };

  getTextStyle = (
    section,
    enabled = "text-primary",
    disabled = "text-muted"
  ) => {
    let textstyle = enabled;
    if (section !== this.state.clickedsection) {
      textstyle = disabled;
    }
    return textstyle;
  };

  getSectionVisibilty = (section) => {
    let visible = "showsection";
    if (section !== this.state.clickedsection) {
      visible = "hidesection";
    }
    return visible;
  };

  displaySent = () => {
    const sentmsgs = this.state.sentmsgs;
    const getsent = this.state.getsent;

    if (getsent) {
      return (
        <div className="text-center py-4 my-4 appearslowly">
          <Loader />
        </div>
      );
    }
    if (sentmsgs.length <= 0) {
      //empty
      return (
        <div className="text-center pt-0 pb-4 appearslowly">
          <span
            style={{ fontSize: "50px" }}
            role="img"
            aria-label="halo"
            className="mr-2">
            😇
          </span>
          <p className="defaultfontsize">
            To send notes, please go Notes, select a note, click "Copy", and
            share.
          </p>
        </div>
      );
    } else {
      return sentmsgs.map((item) => {
        // console.log(item);
        let pasttime = formatTime(item.created);
        return (
          <div
            className="pt-4 pb-2 appearslowly pointer"
            key={item.id}
            style={{ borderBottom: "1px solid #efefef" }}>
            <Media className="mb-2">
              <div
                className="text-left"
                onClick={() => this.processUserView(item, "sent")}>
                {/* <Link to="/profile" style={{ textDecoration: "none" }}> */}
                <div className="" style={{ width: "50px" }}>
                  {!item.recepientpic && (
                    <AccountCircleTwoToneIcon
                      className="text-primary"
                      style={{
                        fontSize: "40px",
                        marginRight: "5px",
                        marginTop: "0px",
                      }}
                    />
                  )}
                  {item.recepientpic && (
                    <ReactImage
                      src={convertFireStorageUrl(item.recepientpic)}
                      width="35px"
                      height="35px"
                      alt=""
                      roundedCircle
                      style={{
                        fontSize: "40px",
                        marginRight: "5px",
                        marginTop: "0px",
                      }}
                    />
                  )}
                </div>
                {/* </Link> */}
              </div>
              <Media.Body>
                <div className=" text-left float-left">
                  {/* <Link to="/profile" style={{ textDecoration: "none" }}> */}
                  <h6
                    className="pb-0 mb-0 text-dark"
                    onClick={() => this.processUserView(item, "sent")}>
                    To {item.recepientname}{" "}
                    {item?.recepientcountry && (
                      <span className="">
                        <ReactCountryFlag
                          countryCode={item?.recepientcountry}
                          className="emojiFlag ml-1"
                          style={{
                            fontSize: "1em",
                            lineHeight: "0.9em",
                          }}
                        />
                      </span>
                    )}
                  </h6>
                  {/* </Link> */}

                  <small
                    className="text-muted font-weight-light pointer"
                    onClick={() => this.showEntry(item, "sent")}>
                    <SaveOutlinedIcon
                      className="text-primary"
                      style={{ fontSize: "12px" }}
                    />{" "}
                    {new Date(item.received).toDateString()} &bull;{" "}
                    <span className="text-muted">{pasttime}</span>{" "}
                    {/* <DoneAllIcon
                      className="text-success"
                      style={{
                        fontSize: "15px",
                        marginTop: "-2px",
                      }}
                    /> */}
                  </small>
                </div>
              </Media.Body>
            </Media>
            <div className="clearfix"></div>
            <p
              className="py-3 mb-0 text-dark pointer"
              style={{ marginLeft: "50px" }}
              onClick={() => this.showEntry(item, "sent")}>
              {item.content.split("\n").map((str, id) => (
                <span key={id}>
                  {str}
                  <br />
                </span>
              ))}
            </p>
            {item.reply && (
              <div
                style={{ marginLeft: "50px" }}
                className="mt-0 mb-4 text-muted pointer">
                {item.replyphoto.length > 0 ? (
                  <ReactImage
                    src={convertFireStorageUrl(item.replyphoto)}
                    width="15px"
                    height="15px"
                    alt=""
                    roundedCircle
                    style={{
                      zIndex: "1",
                      marginTop: "2px",
                    }}
                  />
                ) : (
                  <AccountCircleTwoToneIcon
                    className="text-primary"
                    style={{
                      fontSize: "15px",
                      marginTop: "0px",
                    }}
                  />
                )}{" "}
                <small>
                  &bull;{" "}
                  <CommentOutlinedIcon
                    className="text-primary"
                    style={{ fontSize: "12px" }}
                  />{" "}
                  {formatTime(item.replytime)}{" "}
                </small>
                <div>{item.reply}</div>
              </div>
            )}
            {item.bigphoto && (
              <div style={{ marginLeft: "50px" }}>
                <Zoom>
                  <img
                    src={convertFireStorageUrl(item.bigphoto)}
                    alt=""
                    width="100%"
                    className="lightframecolor roundcornernocolor mb-4"
                  />
                </Zoom>
              </div>
            )}

            <Button
              variant="link"
              className="float-right btn-sm text-muted p-2"
              onClick={() => this.showEntry(item, "sent")}>
              <div className={`float-left `} style={{ width: "25px" }}>
                <PlayCircleOutlineOutlinedIcon
                  className="text-primary"
                  style={{
                    fontSize: "18px",
                    marginTop: "-2px",
                  }}
                />
              </div>
              <div className={`float-left`}>Play</div>
            </Button>
            <div className="clearfix"></div>
          </div>
        );
      });
    }
  };

  displayReceived = () => {
    const receivedmsgs = this.state.receivedmsgs;
    const getreceived = this.state.getreceived;

    if (getreceived) {
      return (
        <div className="text-center py-4 my-4 appearslowly">
          <Loader />
        </div>
      );
    }
    if (receivedmsgs.length <= 0) {
      //empty
      return (
        <div className="text-center pt-0 pb-4 appearslowly">
          <span
            style={{ fontSize: "50px" }}
            role="img"
            aria-label="wink"
            className="mr-2">
            😉
          </span>
          <p className="defaultfontsize">
            They say, when you give, it will comeback to you. Start sending your
            personalized ThankyNotes to the people you are grateful for.
          </p>
        </div>
      );
    } else {
      return receivedmsgs.map((item) => {
        let pasttime = formatTime(item.created);
        return (
          <div
            className="pt-4 pb-2 appearslowly pointer"
            key={item.id}
            style={{ borderBottom: "1px solid #efefef" }}>
            <Media className="mb-2">
              <div
                className="text-left"
                onClick={() => this.processUserView(item, "recevied")}>
                {/* <Link to="/profile" style={{ textDecoration: "none" }}> */}
                <div className="" style={{ width: "50px" }}>
                  {!item.smallpic && (
                    <AccountCircleTwoToneIcon
                      className="text-primary"
                      style={{
                        fontSize: "40px",
                        marginRight: "5px",
                        marginTop: "0px",
                      }}
                    />
                  )}
                  {item.smallpic && (
                    <ReactImage
                      src={convertFireStorageUrl(item.smallpic)}
                      width="35px"
                      height="35px"
                      alt=""
                      roundedCircle
                      style={{
                        fontSize: "40px",
                        marginRight: "5px",
                        marginTop: "0px",
                      }}
                    />
                  )}
                </div>
                {/* </Link> */}
              </div>
              <Media.Body>
                <div className=" text-left float-left">
                  {/* <Link to="/profile" style={{ textDecoration: "none" }}> */}
                  <h6
                    className="pb-0 mb-0 text-dark"
                    onClick={() => this.processUserView(item, "recevied")}>
                    From {item.author}{" "}
                    {item?.country && (
                      <span className="">
                        <ReactCountryFlag
                          countryCode={item?.country}
                          className="emojiFlag ml-1"
                          style={{
                            fontSize: "1em",
                            lineHeight: "0.9em",
                          }}
                        />
                      </span>
                    )}
                  </h6>
                  {/* </Link> */}

                  <small
                    className="text-muted font-weight-light pointer"
                    onClick={() => this.showEntry(item, "received")}>
                    <SaveOutlinedIcon
                      className="text-primary"
                      style={{ fontSize: "12px" }}
                    />{" "}
                    {new Date(item.received).toDateString()} &bull;{" "}
                    <span className="text-muted">{pasttime}</span>
                  </small>
                </div>
              </Media.Body>
            </Media>
            <div className="clearfix"></div>
            <p
              className="py-3 mb-0 text-dark pointer"
              style={{ marginLeft: "50px" }}
              // onClick={() => this.openNew(item)}
              onClick={() => this.showEntry(item, "received")}>
              {item.content.split("\n").map((str, id) => (
                <span key={id}>
                  {str}
                  <br />
                </span>
              ))}
            </p>
            {item.reply && (
              <div
                style={{ marginLeft: "50px" }}
                className="mt-0 mb-4 text-muted pointer">
                {item.replyphoto.length > 0 ? (
                  <ReactImage
                    src={convertFireStorageUrl(item.replyphoto)}
                    width="15px"
                    height="15px"
                    alt=""
                    roundedCircle
                    style={{
                      zIndex: "1",
                      marginTop: "2px",
                    }}
                  />
                ) : (
                  <AccountCircleTwoToneIcon
                    className="text-primary"
                    style={{
                      fontSize: "15px",
                      marginTop: "0px",
                    }}
                  />
                )}{" "}
                <small>
                  &bull;{" "}
                  <CommentOutlinedIcon
                    className="text-primary"
                    style={{ fontSize: "12px" }}
                  />{" "}
                  {formatTime(item.replytime)}{" "}
                </small>
                <div>{item.reply}</div>
              </div>
            )}
            {item.bigphoto && (
              <div style={{ marginLeft: "50px" }}>
                <Zoom>
                  <img
                    src={convertFireStorageUrl(item.bigphoto)}
                    alt=""
                    width="100%"
                    className="lightframecolor roundcornernocolor mb-4"
                  />
                </Zoom>
              </div>
            )}
            <Button
              variant="link"
              className="float-right btn-sm text-muted p-2"
              onClick={() => this.openNew(item)}>
              <div className={`float-left `} style={{ width: "25px" }}>
                {item.reply ? (
                  <OpenInNewOutlinedIcon
                    className="text-primary"
                    style={{
                      fontSize: "18px",
                      marginTop: "-2px",
                    }}
                  />
                ) : (
                  <CommentOutlinedIcon
                    className="text-primary"
                    style={{
                      fontSize: "18px",
                      marginTop: "-2px",
                    }}
                  />
                )}
              </div>
              <div className={`float-left`}>
                {item.reply ? "Open" : "Comment"}
              </div>
            </Button>
            <Button
              variant="link"
              className="float-right btn-sm text-muted p-2"
              onClick={() => this.showEntry(item, "received")}>
              <div className={`float-left `} style={{ width: "25px" }}>
                <PlayCircleOutlineOutlinedIcon
                  className="text-success"
                  style={{
                    fontSize: "18px",
                    marginTop: "-2px",
                  }}
                />
              </div>
              <div className={`float-left`}>Play</div>
            </Button>

            <div className="clearfix"></div>
          </div>
        );
      });
    }
  };

  viewEntryModal = () => {
    const viewentrymodal = this.state.viewentrymodal;
    const item = this.state.selecteditem;
    const copied = this.state.copied;
    const type = this.state.type;
    const texttocopy = this.copyText(item);
    const style = copied === true ? "success" : "success";
    return (
      <Modal
        show={viewentrymodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}
        fullscreen="true"
        scrollable={true}>
        <Modal.Header closeButton className="whitebottomborder">
          <Modal.Title className="text-danger font-weight-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="whitebottomborder pt-0">
          <div className="cards pt-0 mx-0">
            <div className="">
              <ViewContent item={item} design={0} type={type} />
            </div>
            <div className="py-3">
              <ViewContentBody animate={true} item={item} type={type} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="whitetopborder pb-3">
          <Button
            variant="link"
            className="forcenoline noline"
            onClick={this.handleClose}>
            <span className="text-muted forcenoline noline">Close</span>
          </Button>
          {type === "notes" && !copied && (
            <CopyToClipboard
              text={texttocopy}
              onCopy={() => this.handleCopy("dialog")}>
              <Button
                variant={`${style}`}
                className="float-right btn-sm py-2 pl-2 pr-3">
                <div className="float-left" style={{ width: "25px" }}>
                  <FileCopyOutlinedIcon
                    className="text-white"
                    style={{
                      fontSize: "18px",
                      marginTop: "-2px",
                    }}
                  />
                </div>
                <div className="float-left">Copy</div>
              </Button>
            </CopyToClipboard>
          )}
          {type === "notes" && copied && (
            <Button
              variant={`${style}`}
              className="float-right btn-sm py-2 pl-2 pr-3">
              <div className="float-left" style={{ width: "25px" }}>
                <CheckCircleOutlineOutlinedIcon
                  style={{
                    fontSize: "18px",
                    marginTop: "-2px",
                  }}
                />
              </div>
              <div className="float-left">Copied</div>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  handleAwardClick = (count) => {
    this.setState({ awardcount: count, viewawardmodal: true });
  };

  viewAwardModal = () => {
    const { awardcount, viewawardmodal } = this.state;
    const [heading, subtext] = getAwardContent(awardcount);
    return (
      <Modal
        show={viewawardmodal}
        onHide={this.handleClose}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}
        fullscreen="true"
        scrollable={true}>
        <Modal.Header closeButton className="whitebottomborder text-center">
          <Modal.Title className="text-danger font-weight-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="whitebottomborder pt-0 appearslowly">
          <div className="cards pt-0 mx-0 text-center">
            <div className="">
              <MilestoneIcon count={awardcount} />
            </div>
            <div className="py-3">
              <Typist cursor={{ show: false }}>
                <h3>{heading}</h3>
                <p className="averagefontsize">{subtext}</p>
              </Typist>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="whitetopborder">
          <Button
            variant="link"
            className="forcenoline noline"
            onClick={this.handleClose}>
            <span className="text-muted forcenoline noline">Close</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  showEntry = (entry, type) => {
    // console.log({ entry });
    this.setState({ viewentrymodal: true, selecteditem: entry, type: type });
    // const link = `/p/${entry.id}`;
    // this.props.history.push(link);
  };

  shorten = (str, maxLen, separator = " ") => {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
  };

  copyText = (item) => {
    if (!item) {
      return null;
    }
    let text = item.content;
    // if (text.length > 70) {
    //   //text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    //   text = `${this.shorten(text, 83)}... See more.`;
    // }
    const texttocopy = `📨 ${text}
    
👉 https://thankynote.com/p/${item.id}`;
    return texttocopy;
  };

  copyTextOnly = (item) => {
    if (!item) {
      return null;
    }
    let text = item.content;
    if (text.length > 70) {
      //text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
      text = `${this.shorten(text, 83)}... See more.`;
    }

    const texttocopy = `📨 ${text}
    
👉`;
    return texttocopy;
  };

  onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log({ imageList });
    this.setState({ imagepost: imageList, hidebutton: true });
  };

  handleRemovePhoto = (index, onImageRemove) => {
    onImageRemove(index);
    this.setState({ hidebutton: false });
    window.scrollTo(0, 0);
  };

  removePhoto = () => {
    console.log("remove photo");
    this.setState({
      hidebutton: false,
      imagepost: [],
      imagedata: "",
      selectedfile: "",
    });
  };

  convertDataURL = (src, callback, outputFormat) => {
    var img = new Image();

    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  };

  updateImageCallback = (dataUrl, imagepost) => {
    console.log("updateImageCallback");
    this.setState({
      imagepost: [imagepost],
      imagedata: dataUrl,
      hidebutton: true,
      uploading: false,
    });
  };

  uploadSingleFile = (e) => {
    console.log("upload");
    const selected = e.target.files[0];
    // URL.createObjectURL()
    console.log({ selected });
    if (selected) {
      this.setState({ uploading: true, selectedfile: selected });
      const imagepost = URL.createObjectURL(selected);
      console.log("imagepost:", imagepost);
      this.convertDataURL(imagepost, (dataUrl) =>
        this.updateImageCallback(dataUrl, imagepost)
      );
      e.target.value = "";
    }
  };

  processUserView = async (entry, type = "sent") => {
    // console.log(entry);
    // console.log(type);
    const id = type === "sent" ? entry.recepientid : entry.authorid;
    //get recepient details
    // const id = type === "sent"?entry.recepientid;
    const user = await getCurrentUserData(id);
    // console.log(user);
    this.setState({
      viewusermodal: { showmodal: true, obj: user, type },
    });
  };

  render() {
    const {
      submitting,
      loading,
      notes,
      user,
      showalert,
      slowdown,
      owner,
      totalnotes,
      daily,
      weekly,
      alertstyle,
      version,
      showemoji,
      dataloaded,
      showstats,
      streak,
      selecteditem,
      hidebutton,
      imagepost,
      unreadnotifications,
    } = this.state;
    if (loading === true) {
      return (
        <LoadingSpinnerNav
          user={user}
          smallpic={user.photoURL}
          hidehome={true}
          hide={true}
          hidefriends={true}
          hidenotifications={true}
        />
      );
    }
    console.log(owner);

    if (!owner?.init) {
      return (
        <IntroPage
          user={user}
          version={version}
          handler={() => this.setState({ owner: { ...owner, init: true } })}
        />
      );
    }
    if (!owner?.country || owner?.country === "") {
      console.log("Empty country...");
    }
    // const sentdone = this.state.sentdone;
    // const sentok = this.state.sentok;

    // if (showstats) {
    //   return (
    //     <StatPage
    //       user={user}
    //       version={version}
    //       data={streak}
    //       handler={() => this.setState({ showstats: false })}
    //     />
    //   );
    // }

    // let placeholder = "What are you thankful for in 2020?";
    // if (daily === 0 || (daily === 0 && weekly === 0 && totalnotes === 0)) {
    //   placeholder = "Write 3 things that you are thankful for today.";
    // }
    // placeholder = [
    //   "What are you grateful for today?",
    //   // "What are you thankful for this week?",
    //   // "What made you happy today?",
    //   // "Write 1 thing that you are thankful for today.",
    //   // "What made you smile today?",
    // ];
    // placeholder = placeholder[Math.floor(Math.random() * placeholder.length)];
    // // const data = {
    // //   labels: [
    // //     activities[0].date,
    // //     activities[1].date,
    // //     activities[2].date,
    // //     activities[3].date,
    // //     activities[4].date,
    // //     activities[5].date,
    // //     activities[6].date,
    // //   ],
    // //   datasets: [
    // //     {
    // //       backgroundColor: [
    // //         "#007bff",
    // //         "#007bff",
    // //         "#007bff",
    // //         "#007bff",
    // //         "#007bff",
    // //         "#007bff",
    // //         "#007bff",
    // //       ],
    // //       label: "Age Group",
    // //       data: [
    // //         activities[0].counter,
    // //         activities[1].counter,
    // //         activities[2].counter,
    // //         activities[3].counter,
    // //         activities[4].counter,
    // //         activities[5].counter,
    // //         activities[6].counter,
    // //       ],
    // //     },
    // //   ],
    // // };
    // // const legendOpts = {
    // //   display: false,
    // //   position: "top",
    // //   fullWidth: true,
    // //   reverse: false,
    // //   labels: {
    // //     fontColor: "rgb(255, 99, 132)",
    // //   },
    // // };
    // let emojistyle = "hidesection";
    // if (showemoji) {
    //   emojistyle = "showsection";
    // }

    let foundfirst = false;
    const datenow = new Date().getTime() / 1000;
    return (
      <React.Fragment>
        <Header
          user={user}
          smallpic={convertFireStorageUrl(user.photoURL)}
          hide={true}
          hidehome={true}
          notificationcount={unreadnotifications || 0}
        />
        <ReloadBar version={version} />
        {/* <Button
          variant="link"
          className="float-right btn-sm text-danger p-2"
          onClick={this.handleSendEmail}
        >
          <div className="float-left" style={{ width: "25px" }}>
            <ContactMailOutlinedIcon
              style={{ fontSize: "18px", marginTop: "-2px" }}
            />
          </div>
          <div className="float-left">Send</div>
        </Button> */}

        <div className="row justify-content-center  mx-auto appearslowly">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-4 mx-auto">
                <div className="row mx-auto">
                  {/* <Link to="/profile">
                    <div className="" style={{ width: "45px" }}>
                      {!user.photoURL && (
                        <AccountCircleTwoToneIcon
                          className="text-primary"
                          style={{
                            fontSize: "40px",
                            marginRight: "5px",
                            marginTop: "0px",
                          }}
                        />
                      )}
                      {user.photoURL && (
                        <ReactImage
                          src={user.photoURL}
                          width="35px"
                          height="35px"
                          alt=""
                          roundedCircle
                          style={{
                            fontSize: "40px",
                            marginRight: "5px",
                            marginTop: "0px",
                          }}
                        />
                      )}{" "}
                    </div>
                  </Link> */}
                  <div className="text-left">
                    <div className="text-left float-left">
                      <h3 className="pb-2 text-dark noline forcenoline">
                        <span
                          role="img"
                          aria-label="wave"
                          className="wave mr-2">
                          👋
                        </span>
                        Welcome, <b>{owner?.fullname.split(" ")[0]}</b>{" "}
                        {/* {owner?.country && (
                          <span className="">
                            <ReactCountryFlag
                              countryCode={owner?.country}
                              className="emojiFlag"
                              style={{
                                fontSize: "0.7em",
                                lineHeight: "0.9em",
                                marginTop: "-15px",
                                marginLeft: "-2px",
                              }}
                            />
                          </span>
                        )} */}
                        {/* {!owner?.country && (
                          <Link
                            to="/account/edit"
                            style={{ textDecoration: "none" }}
                          >
                             <PublicIcon
                            className="text-success pulsecountry"
                            style={{
                              fontSize: "15px",
                              marginRight: "5px",
                              marginTop: "0px",
                            }} 
                            <div
                              style={{
                                fontSize: "15px",
                                marginRight: "5px",
                                marginTop: "-10px",
                              }}
                            >
                              <span
                                role="img"
                                aria-label="globe"
                                className="defaultsmallfontsize"
                              >
                                🌍
                              </span>
                            </div>
                          </Link>
                        )} */}
                      </h3>
                    </div>
                  </div>
                </div>
                {/* <div>
                  {this.getRemarks()}
                  <h4 className="">Your Gratitude Score</h4>
                </div> */}
                <div className="row py-2">
                  {/* <div className="col px-0">
                    <h2 className="py-0 my-0 text-center text-primary">
                      {daily}
                    </h2>
                    <p className="text-center text-muted py-0">TODAY</p>
                  </div> */}

                  <div
                    className="col pointer px-0"
                    onClick={() => this.handleClick(0)}>
                    <h2
                      className={`py-0 my-0 text-center ${this.getTextStyle(
                        0
                      )}`}
                      style={{ userSelect: "none" }}>
                      {owner?.totalnotes || 0}
                    </h2>

                    <p
                      className={`text-center ${this.getTextStyle(
                        0,
                        "text-primary font-weight-bold",
                        "text-muted font-weight-bold"
                      )} py-0`}
                      style={{ userSelect: "none" }}>
                      NOTES
                    </p>
                  </div>
                  <div
                    className="col pointer px-0"
                    onClick={() => this.handleClick(1)}>
                    {owner.recvclick && !owner.sentclick && (
                      <div className="pulsating-circle"></div>
                    )}
                    <h2
                      className={`py-0 my-0 text-center ${this.getTextStyle(
                        1
                      )}`}
                      style={{ userSelect: "none" }}>
                      {owner?.totalsent || 0}
                    </h2>

                    <p
                      className={`text-center ${this.getTextStyle(
                        1,
                        "text-primary font-weight-bold",
                        "text-muted font-weight-bold"
                      )}  py-0`}
                      style={{ userSelect: "none" }}>
                      SENT
                    </p>
                  </div>
                  <div
                    className="col pointer px-0"
                    onClick={() => this.handleClick(2)}>
                    {!owner.recvclick && (
                      <div className="pulsating-circle"></div>
                    )}

                    <h2
                      className={`py-0 my-0 text-center ${this.getTextStyle(
                        2
                      )}`}
                      style={{ userSelect: "none" }}>
                      {owner?.totalreceived || 0}
                    </h2>

                    <p
                      className={`text-center ${this.getTextStyle(
                        2,
                        "text-primary font-weight-bold",
                        "text-muted font-weight-bold"
                      )} py-0`}
                      style={{ userSelect: "none" }}>
                      RECEIVED
                    </p>
                  </div>
                </div>
                <div className={`${this.getSectionVisibilty(0)} pb-4 mb-4`}>
                  <form onSubmit={this.handleSubmit}>
                    {/* {this.renderTextareaKeydown(
                      "content",
                      "",
                      "text",
                      placeholder,
                      this.handleKeyDown,
                      true
                    )} */}
                    {/* {this.renderResizeTextarea(
                      "content",
                      "",
                      "text",
                      placeholder,
                      this.handleKeyDown,
                      true
                    )} */}
                    {/* {slowdown && (
                      <div className="text-danger">
                        Ooops, please slow down....
                      </div>
                    )} */}
                    <div className="imageuploaddiv">
                      <div className="float-left" style={{ right: "35px" }}>
                        <div className={hidebutton ? "hidesection" : "pointer"}>
                          <input
                            type="file"
                            className="form-control pointer"
                            onChange={this.uploadSingleFile}
                            accept="image/png,image/jpg,image/jpeg"
                            id="icon-button-file"
                            style={{ display: "none" }}
                          />
                          <label htmlFor="icon-button-file" className="mt-0">
                            <InsertPhotoOutlinedIcon
                              className="text-success pointer"
                              style={{
                                marginTop: "-2px",
                                fontSize: "20px",
                              }}
                            />{" "}
                            <span className="text-muted pointer">
                              Add Photo
                            </span>{" "}
                            {this.state.uploading && (
                              <span
                                className="spinner-border spinner-border-sm text-muted"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                          </label>
                        </div>

                        {/* <ImageUploading
                          value={imagepost}
                          onChange={this.onChange}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper">
                              <div
                                className={hidebutton ? "hidesection" : null}
                              >
                                <div
                                  className="text-muted float-right pointer mt-1 mb-3"
                                  onClick={onImageUpload}
                                  // {...dragProps}
                                >
                                  <InsertPhotoOutlinedIcon
                                    className="text-success"
                                    style={{
                                      marginTop: "-2px",
                                      fontSize: "20px",
                                    }}
                                  />{" "}
                                  Add Photo
                                </div>
                              </div>
                              {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <div className="image-item__btn-wrapper float-right">
                                    <div
                                      className="float-right pointer mt-0 mb-2 text-danger"
                                      onClick={() =>
                                        this.handleRemovePhoto(
                                          index,
                                          onImageRemove
                                        )
                                      }
                                    >
                                      <CloseOutlinedIcon
                                        className="text-dark"
                                        style={{ marginTop: "-3px" }}
                                      />
                                    </div>
                                  </div>
                                  <img
                                    src={image.data_url}
                                    alt=""
                                    width="100%"
                                    className="roundcornernocolor mb-4"
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </ImageUploading> */}
                      </div>
                      {imagepost.length > 0 && (
                        <div>
                          <div
                            className="float-right pointer mt-0 mb-2 text-danger"
                            onClick={this.removePhoto}>
                            <CloseOutlinedIcon
                              className="text-dark"
                              style={{ marginTop: "-3px" }}
                            />
                          </div>
                          <img
                            width="100%"
                            className="lightframecolor roundcornernocolor mb-4"
                            src={convertFireStorageUrl(imagepost[0])}
                            alt=""
                          />
                          <div className="clearfix"></div>
                        </div>
                      )}
                    </div>

                    <div
                      className="ml-2 float-left pr-4 pointer d-none d-md-block"
                      style={{ marginTop: "1px" }}
                      onClick={() =>
                        this.setState({ showemoji: !this.state.showemoji })
                      }>
                      {this.state.showemoji && (
                        <div className="text-muted">
                          <div
                            className="float-right ml-2"
                            style={{ marginTop: "0px" }}>
                            Hide Emoji
                          </div>
                          <span
                            aria-label="smile"
                            style={{ fontSize: "18px" }}
                            role="img">
                            😀
                          </span>
                        </div>
                      )}

                      {!this.state.showemoji && (
                        <div className="text-muted">
                          <div
                            className="float-right ml-2"
                            style={{ marginTop: "0px" }}>
                            Show Emojis
                          </div>
                          <span
                            aria-label="smile"
                            style={{ fontSize: "18px" }}
                            role="img">
                            😊
                          </span>
                        </div>
                      )}
                    </div>

                    {/* <div className="clearfix"></div> */}
                    {/* <div className={`mt-3 ${emojistyle}`}>
                      <Picker
                        disableSearchBar={true}
                        onEmojiClick={this.onEmojiClick}
                        pickerStyle={{
                          marginBottom: "10px",
                          width: "100%",
                        }}
                        preload={true}
                        groupVisibility={{
                          flags: false,
                        }}
                      />
                    </div> */}
                    {/* <div className="clearfix"></div> */}
                    <div className="float-right">
                      {!submitting && this.renderRightWithDisableButton("Post")}
                      {submitting &&
                        this.renderRightLoadingBlockButton("Posting...")}
                    </div>
                  </form>
                  <div className="clearfix mt-4"></div>
                  {!dataloaded && (
                    <div className="mt-4 pt-4 d-flex justify-content-center">
                      <Loader />
                    </div>
                  )}
                  <div id="announcementsection" className={`my-4 pt-2 pb-4`}>
                    {notes.map((list) => {
                      const disablesendto = list.recepientid ? true : false;
                      const texttocopy = this.copyText(list);
                      const pasttime = formatTime(list.created);
                      let textstyle = "text-muted";
                      if (foundfirst === false) {
                        if (datenow - list.created / 1000 > 2 * 24 * 60 * 60) {
                          textstyle = "text-danger";
                        }
                      }
                      foundfirst = true;
                      if (pasttime === "Just now") {
                        textstyle = "text-success";
                      }

                      let copystyle = "text-muted";
                      if (showalert && selecteditem?.id === list.id) {
                        copystyle = "text-success";
                      }
                      // console.log({ list });
                      let titleText = list.content;
                      if (titleText.length > 30) {
                        //text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
                        titleText = `${this.shorten(titleText, 30)}...`;
                      }
                      return (
                        <div
                          className="mb-4 pb-4 appearslowly"
                          key={list.id}
                          style={{ borderBottom: "1px solid #efefef" }}>
                          <div className="text-muted">
                            <div className="row mx-auto">
                              <Media className="mb-2">
                                <div className="text-left">
                                  <Link
                                    to="/profile"
                                    style={{ textDecoration: "none" }}>
                                    <div className="" style={{ width: "50px" }}>
                                      {!user.photoURL && (
                                        <AccountCircleTwoToneIcon
                                          className="text-primary"
                                          style={{
                                            fontSize: "40px",
                                            marginRight: "5px",
                                            marginTop: "0px",
                                          }}
                                        />
                                      )}
                                      {user.photoURL && (
                                        <ReactImage
                                          src={convertFireStorageUrl(
                                            user.photoURL
                                          )}
                                          width="35px"
                                          height="35px"
                                          alt=""
                                          roundedCircle
                                          style={{
                                            fontSize: "40px",
                                            marginRight: "5px",
                                            marginTop: "0px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Link>
                                </div>
                                <Media.Body>
                                  <div className=" text-left float-left">
                                    <Link
                                      to="/profile"
                                      style={{ textDecoration: "none" }}>
                                      <h6 className="pb-0 mb-0 text-dark">
                                        {list.author}{" "}
                                        {list?.country && (
                                          <span className="">
                                            <ReactCountryFlag
                                              countryCode={list?.country}
                                              className="emojiFlag ml-1"
                                              style={{
                                                fontSize: "1em",
                                                lineHeight: "0.9em",
                                              }}
                                            />
                                          </span>
                                        )}
                                      </h6>
                                    </Link>

                                    <small className="text-muted font-weight-light">
                                      <PostAddOutlinedIcon
                                        className="text-primary pointer"
                                        style={{ fontSize: "12px" }}
                                        onClick={() =>
                                          this.showEntry(list, "notes")
                                        }
                                      />{" "}
                                      {new Date(list.created).toDateString()}{" "}
                                      &bull;{" "}
                                      <span className={textstyle}>
                                        {pasttime}
                                      </span>{" "}
                                      {list.count >= 1 && (
                                        <span
                                          className="pulsesmallaward"
                                          onClick={() =>
                                            this.handleAwardClick(list.count)
                                          }>
                                          <MilestoneIcon
                                            count={list.count}
                                            size="15px"
                                          />
                                        </span>
                                      )}
                                    </small>
                                  </div>
                                </Media.Body>
                              </Media>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          <p
                            className="py-3 mb-0 text-dark pointer"
                            // style={{ marginLeft: "50px" }}
                            onClick={() => this.showEntry(list, "notes")}>
                            {/* {list.content} */}
                            {list.content.split("\n").map((str, id) => (
                              <span key={id}>
                                {str}
                                <br />
                              </span>
                            ))}
                          </p>
                          {list.bigphoto && (
                            <div
                            // style={{ marginLeft: "50px" }}
                            >
                              <Zoom>
                                <img
                                  src={convertFireStorageUrl(list.bigphoto)}
                                  alt=""
                                  width="100%"
                                  className="lightframecolor roundcornernocolor mb-4"
                                />
                              </Zoom>
                            </div>
                          )}
                          {!list.reply && list.hasrecepient && (
                            <div
                              // style={{ marginLeft: "50px" }}
                              className="mt-0 mb-2 text-muted pointer"
                              onClick={() => this.processUserView(list)}>
                              {list.recepientpic ? (
                                <ReactImage
                                  src={convertFireStorageUrl(list.recepientpic)}
                                  width="15px"
                                  height="15px"
                                  alt=""
                                  roundedCircle
                                  style={{
                                    zIndex: "1",
                                    marginTop: "2px",
                                  }}
                                />
                              ) : (
                                <AccountCircleTwoToneIcon
                                  className="text-primary"
                                  style={{
                                    fontSize: "15px",
                                    marginTop: "0px",
                                  }}
                                />
                              )}{" "}
                              <small>
                                {list.recepientname} &bull;{" "}
                                <SaveOutlinedIcon
                                  className="text-primary"
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "-3px",
                                  }}
                                />{" "}
                                {formatTime(list.received)}{" "}
                                {/* <DoneAllIcon
                                  className="text-success"
                                  style={{
                                    fontSize: "15px",
                                    marginTop: "-2px",
                                  }}
                                /> */}
                              </small>
                            </div>
                          )}
                          {list.reply && (
                            <div
                              // style={{ marginLeft: "50px" }}
                              className="mt-0 mb-4 text-muted pointer">
                              {list.replyphoto !== "" ? (
                                <ReactImage
                                  src={convertFireStorageUrl(list.replyphoto)}
                                  width="15px"
                                  height="15px"
                                  alt=""
                                  roundedCircle
                                  style={{
                                    zIndex: "1",
                                    marginTop: "2px",
                                  }}
                                />
                              ) : (
                                <AccountCircleTwoToneIcon
                                  className="text-primary"
                                  style={{
                                    fontSize: "15px",
                                    marginTop: "0px",
                                  }}
                                />
                              )}{" "}
                              <small>
                                &bull;{" "}
                                <CommentOutlinedIcon
                                  className="text-primary"
                                  style={{ fontSize: "12px" }}
                                />{" "}
                                {formatTime(list.replytime)}{" "}
                              </small>
                              <div>{list.reply}</div>
                            </div>
                          )}

                          <div className="">
                            {!disablesendto && (
                              <NoteButtonAction
                                entry={list}
                                type="add"
                                buttonHandler={() =>
                                  this.handleSendNoteTo(list)
                                }
                              />
                            )}
                            <ShareNote
                              title={titleText}
                              text={this.copyTextOnly(list)}
                              item={list}
                            />
                            {/* <NoteButtonAction
                              entry={list}
                              type="play"
                              buttonHandler={() =>
                                this.showEntry(list, "notes")
                              }
                            /> */}
                            <CopyToClipboard
                              text={texttocopy}
                              onCopy={() => this.handleCopy("stream", list)}>
                              <Button
                                variant="link"
                                className="float-right btn-sm text-muted px-3 py-2">
                                <div
                                  className={`float-left ${copystyle}`}
                                  style={{ width: "25px" }}>
                                  <FileCopyOutlinedIcon
                                    className="text-success"
                                    style={{
                                      fontSize: "18px",
                                      marginTop: "-2px",
                                    }}
                                  />
                                </div>
                              </Button>
                            </CopyToClipboard>
                            <NoteButtonAction
                              entry={list}
                              type="delete"
                              buttonHandler={() =>
                                this.handleDelete(list.id, list.created)
                              }
                            />
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      );
                    })}
                  </div>

                  <div>
                    {this.state.notesnextcount > 0 && (
                      <div className="text-center py-4 mb-0 text-muted">
                        <button
                          className="btn btn-block btn-primary btn-lg"
                          onClick={async () => await this.getMoreNotes()}>
                          Load More
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="mb-4 pb-4">
                    {notes && notes.length > 3 && (
                      <div className="text-center mb-0 text-muted">
                        <ScrollToTop />
                        <div className="py-5 my-5">
                          Got feature requests? Contact{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/in/jeynergilcaga">
                            Jeyner
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="clearfix"></div>
                <div
                  className={`${this.getSectionVisibilty(
                    1
                  )} pt-0 pb-4 text-muted`}
                  style={{
                    backgroundColor: "#ffffff",
                    borderTop: "1px dashed #efefef",
                  }}>
                  <h2 className="pt-4 pb-0 text-primary appearslowly">Sent</h2>
                  {this.displaySent()}
                </div>
                <div
                  className={`${this.getSectionVisibilty(
                    2
                  )} pt-0 pb-4 text-muted`}
                  style={{
                    backgroundColor: "#ffffff",
                    borderTop: "1px dashed #efefef",
                  }}>
                  <h2 className="pt-4 pb-0 text-primary appearslowly">
                    Received
                  </h2>
                  {this.displayReceived()}
                </div>
                {this.deleteModal()}
                {this.viewUserModal()}
                {this.copyModal()}
                {this.viewEntryModal()}
                {this.sendToModal()}
                {this.viewAwardModal()}
                {this.copyLink()}
              </div>
            </main>
          </div>
        </div>
        <Toast
          style={{
            width: "250px",
            position: "fixed",
            bottom: "40%",
            transform: "translate(-50%, 0px)",
            left: "50%",
          }}
          className="text-center mx-auto"
          onClose={() =>
            this.setState({
              showalert: false,
              selecteditem: "",
            })
          }
          show={showalert}
          delay={1500}
          autohide>
          <Toast.Body className={`${alertstyle} averagefontsize p-4`}>
            {this.state.text}
          </Toast.Body>
        </Toast>
      </React.Fragment>
    );
  }
}

export default ThankYouSetup;
