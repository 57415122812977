import React from "react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "../styles/tl.css";

function DisplayTimeline({ timeline }) {
  const TimelineV1 = () => (
    <Timeline>
      {timeline.map((i, index) => {
        return (
          <TimelineEvent
            icon={<i />}
            // icon={<span className="font-weight-bold">{index + 1}</span>}
            iconColor="#3d97f8"
            iconStyle={{ fontSize: "12px" }}
            titleStyle={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
            key={index}
            title={`${i.heading}`}
            subtitle={`${i.identifier}`}
            subtitleStyle={{
              fontSize: "18px",
              color: "black",
            }}
            contentStyle={{
              fontSize: "18px",
              color: "black",
              borderRadius: "8px",
              backgroundColor: "#cce5ff",
              border: "1px dashed #318aea",
              boxShadow: "1px #b8daff",
            }}>
            {i.description}
          </TimelineEvent>
        );
      })}
    </Timeline>
  );

  const TimelineV2 = () => (
    <VerticalTimeline
      animate={false}
      className="px-0 mx-0"
      layout="1-column-left"
      lineColor="#d0d0d0">
      {timeline.map((i, index) => {
        return (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{
              borderRadius: "10px",
              background: "#cce5ff",
              color: "#404040",
            }}
            contentArrowStyle={{ borderRight: "7px solid  #cce5ff" }}
            iconStyle={{
              background: "#cce5ff",
              width: "16px",
              height: "16px",
              marginTop: "12px",
              marginLeft: "10px",
            }}
            shadowSize="large"
            icon={<i />}>
            <h5 className="mb-0 defaultfontsize" style={{ color: "#000" }}>
              {i.heading}
            </h5>
            {i?.identifier && (
              <p className="my-0 normalsmallfontsize">{i.identifier}</p>
            )}
            <p className="mb-0 normalsmallfontsize">{i.description}</p>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
  return <TimelineV2 />;
}

export default DisplayTimeline;
