import fire from "./fire";

export async function updatePhoto(userid, filename, knowledgeid = null) {
  const imageDir = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images`;
  const thumb = encodeURIComponent(
    `/${userid}/thumbs/${filename}_200x200.jpeg`
  );
  const photo = encodeURIComponent(
    `/${userid}/thumbs/${filename}_1024x1024.jpeg`
  );

  const smallPic = `${imageDir}${thumb}?alt=media`;
  const bigPic = `${imageDir}${photo}?alt=media`;
  if (!knowledgeid) {
    return;
  }

  const db = fire.firestore();
  const knowledge = await db.doc(`knowledge/${knowledgeid}`).get();

  if (knowledge && knowledge.exists) {
    await db
      .doc(`knowledge/${knowledgeid}`)
      .update({ knowledgesmallpic: smallPic, knowledgebigpic: bigPic });
  }
  return { bigPic, smallPic };
}
