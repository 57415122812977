export function getLinkedInImage() {
  return "https://firebasestorage.googleapis.com/v0/b/scoopbite.appspot.com/o/cdn%2Flogo%2Fthumbs%2FLinkedIn_logo_initials_90x90.jpeg?alt=media&token=bacedcae-5e4c-4aaa-9de6-b26d9e6778b9";
}

export function getBigImage() {
  return "https://firebasestorage.googleapis.com/v0/b/scoopbite.appspot.com/o/images%2F9yrgVHIm80QFx0dbfF59eXgxgt93%2Fthumbs%2F9yrgVHIm80QFx0dbfF59eXgxgt93_300x300.jpeg?alt=media&token=eb8487b5-d124-4837-80de-fb1b6220ebbd";
}

export function getSmallImage() {
  return "https://firebasestorage.googleapis.com/v0/b/scoopbite.appspot.com/o/images%2F9yrgVHIm80QFx0dbfF59eXgxgt93%2Fthumbs%2F9yrgVHIm80QFx0dbfF59eXgxgt93_90x90.jpeg?alt=media";
}
