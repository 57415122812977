import React from "react";
import { formatTime, formatTimeSimple } from "../../../utils/utils";
import { Row } from "react-bootstrap";
import PublicIcon from "@material-ui/icons/Public";

function TitleCreator({ title, subtitle, content, owner, smallpic }) {
  return (
    <div>
      <h1 className="text-left mb-0">{title}</h1>
      {subtitle && (
        <p className="normalsmallerfontsize text-left mb-0">{subtitle}</p>
      )}
      <Row>
        <div className="ml-3 mt-1 normalsmallerfontsize d-flex align-items-center text-muted">
          <img
            className="rounded-circle mr-1"
            src={smallpic}
            width={16}
            height={16}
          />
          {owner} &bull; {formatTimeSimple(new Date(content.created))} &bull;
          <PublicIcon className="ml-1" style={{ fontSize: "12px" }} />
        </div>
      </Row>
    </div>
  );
}

export default TitleCreator;
