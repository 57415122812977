import React, { Component } from "react";
import Header from "./header";
import ReloadBar from "./reloadBar";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import MilestoneIcon from "../thankyounote/component/milestoneIcon";
import { getAwardContent } from "../thankyounote/service/contentObject";
import DisplayStreak from "./displayStreak";
import Confetti from "react-confetti";

class StatPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
    };
  }
  componentDidMount() {}

  handleClick = async (user) => {
    const { handler } = this.props;

    handler();
  };

  displayData = (label, data) => {
    console.log({ data });
    const append =
      data.count > 0 ? (
        <CheckCircleRoundedIcon className="text-success" />
      ) : (
        <Brightness1RoundedIcon style={{ color: "#dedede" }} />
      );

    return (
      <>
        {label}
        <br />
        {append}
      </>
    );
  };

  getData = () => {
    const { data } = this.props;
    console.log({ data });
    let count = 0;

    //count how many day have entries
    data.forEach((item) => {
      if (item.count > 0) {
        count++;
      }
    });
    const [heading, subtext] = getAwardContent(count);
    const icon = <MilestoneIcon count={count} />;

    return [icon, heading, subtext];
  };

  render() {
    const { user, version, data } = this.props;
    const [icon, heading, subtext] = this.getData();
    const daylabel = [
      { id: 1, label: "S", data: data[0] },
      { id: 2, label: "M", data: data[1] },
      { id: 3, label: "T", data: data[2] },
      { id: 4, label: "W", data: data[3] },
      { id: 5, label: "T", data: data[4] },
      { id: 6, label: "F", data: data[5] },
      { id: 7, label: "S", data: data[6] },
    ];
    return (
      <React.Fragment>
        <Header
          user={user}
          smallpic={user.photoURL}
          hide={true}
          hidehome={true}
        />
        <ReloadBar version={version} />

        <div className="row justify-content-center mt-4 mx-auto appearslowly">
          <Confetti recycle={false} opacity={0.9} gravity={0.2} />
          <div className="col-lg-6">
            <main className="container text-center">
              <div className="text-center">{icon}</div>
              <h3 className="py-4">{heading}</h3>
              <div className="card lightframecolor roundcornernocolor">
                <div className="row p-4">
                  {daylabel.map((item) => (
                    <div key={item.id} className="col text-center px-0">
                      {/* {this.displayData(item.label, item.data)} */}
                      <DisplayStreak label={item.label} data={item.data} />
                    </div>
                  ))}
                </div>
              </div>
              <p className="text-center defaultfontsize my-4 px-2 pt-0 pb-0">
                {subtext}
              </p>
              <div className="py-4 my-4">
                <button
                  className="btn btn-success btn-lg px-4 py-2"
                  onClick={() => this.handleClick(user)}
                >
                  Continue
                </button>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StatPage;
