import React from "react";
import { Button } from "react-bootstrap";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import PlayCircleOutlineOutlinedIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import "./assets/css/index.css";

function NoteButtonAction({ entry, type, buttonHandler }) {
  let icon;
  if (type === "delete") {
    icon = <DeleteSweepOutlinedIcon className="text-muted notebuttonaction" />;
  } else if (type === "add") {
    icon = <PersonAddOutlinedIcon className="text-danger notebuttonaction" />;
  } else if (type === "play") {
    icon = (
      <PlayCircleOutlineOutlinedIcon className="text-primary notebuttonaction" />
    );
  }
  return (
    <Button
      variant="link"
      className="float-right btn-sm text-muted px-3 py-2"
      onClick={() => buttonHandler(entry.id, entry.created)}
    >
      <div className="float-left" style={{ width: "25px" }}>
        {icon}
      </div>
      {/* <div className="float-left defaultstandardfontsize">
    Delete
  </div> */}
    </Button>
  );
}

export default NoteButtonAction;
