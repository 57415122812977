import React from "react";
import AvatarEditor from "react-avatar-editor";
import { Button } from "react-bootstrap";
import SpinnerText from "../../common/spinnerwithText";
import { Grid, Slider } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

function PhotoEdit({
  state,
  onClickSave,
  onClickCancel,
  onZoomChange,
  setEditorRef,
  increaseZoom,
  decreaseZoom,
  imageDataUrl,
  zoom,
  saving,
}) {
  return (
    <div className="mx-auto">
      <h4 className="text-left py-4">Update Photo</h4>
      <div className="pb-4">
        <AvatarEditor
          ref={setEditorRef}
          image={imageDataUrl}
          width={250}
          height={250}
          border={0}
          borderRadius={250}
          scale={zoom}
          crossOrigin="anonymous"
        />
      </div>
      <Grid container spacing={2} className="my-2">
        <Grid item>
          <RemoveIcon onClick={decreaseZoom} />
        </Grid>
        <Grid item xs>
          <Slider
            className="text-primary"
            value={state.zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => onZoomChange(zoom)}
          />
        </Grid>
        <Grid item>
          <AddIcon onClick={increaseZoom} />
        </Grid>
      </Grid>

      {!saving && (
        <Button
          variant="primary"
          className="btn-block btn-lg"
          onClick={onClickSave}>
          Save
        </Button>
      )}
      {saving && (
        <Button variant="primary" className="btn-block btn-lg" disabled>
          <SpinnerText text="Saving..." />
        </Button>
      )}
      <Button
        variant="link"
        className="btn-block btn-lg text-primary"
        onClick={onClickCancel}>
        Cancel
      </Button>
    </div>
  );
}

export default PhotoEdit;
