import React from "react";
import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";

function DatePicker({
  name,
  selected,
  onChange,
  minDate,
  maxDate,
  isErr,
  disabled = false,
}) {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <button
        className={`${isErr && disabled === false ? "bordererr" : ""} ${
          disabled === true ? "disabledtext" : ""
        } text-left form-control form-control btn-block`}
        onClick={onClick}
        ref={ref}>
        {value}
      </button>
    </>
  ));

  return (
    <>
      <ReactDatePicker
        disabled={disabled}
        // customInput={<CustomInput />}
        name={name}
        id={name}
        selected={selected}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        //fixedHeight
        peekNextMonth
        // showMonthDropdown
        // showYearDropdown
        // dropdownMode="select"
        // dateFormat="MMM d, yyyy"
        className={`form-control  form-control-lg ${isErr ? "bordererr" : ""}`}
      />
    </>
  );
}

export default DatePicker;
