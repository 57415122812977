import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import SystemLogo from "../../blocks/systemLogoBlock";
import { logEvent } from "../../../services/log";
import { isUserLoggedIn } from "../../../services/user";
import Footer from "../../common/footer";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import {
  getWebsiteFullDescription,
  getWebsiteName,
} from "../../../services/settings";

class LoginForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
    loading: false,
    refid: this.props?.match?.params?.refid || "",
    showpass: false,
  };

  schema = {
    email: Joi.string().email().trim().required().label("Email"),
    password: Joi.string().min(8).trim().required().label("Password"),
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  doSubmit = async () => {
    // Call the server
    const { data, errors, refid } = this.state;
    console.log({ refid });
    this.setState({
      errors,
      loading: true,
    });
    let lowercaseemail = data.email.trim().toLowerCase();
    logEvent("login_click", { email: lowercaseemail });
    try {
      await fire
        .auth()
        .signInWithEmailAndPassword(lowercaseemail, data.password.trim());
      console.log({ refid });
      // // await this.sleep(1000);

      const loggedIn = await isUserLoggedIn();
      console.log({ loggedIn });
      logEvent("login_success", { email: lowercaseemail });
      let link = `/home`;
      if (refid) {
        link = `/a/${refid}`;
      }
      console.log(link);
      this.props.history.push(link);
    } catch (error) {
      let errors;
      if (error.code === "auth/too-many-requests") {
        errors = { email: error.message };
      } else {
        errors = {
          email: "Incorrect email or password",
        };
      }
      logEvent("login_fail", {
        email: lowercaseemail,
        message: error.message,
      });
      this.setState({
        loading: false,
        errors,
      });
    }
  };

  componentDidMount() {
    let el = document.querySelector("title");
    const title = `Log In | ${getWebsiteName()}`;
    const content = getWebsiteFullDescription();
    el.innerText = title;
    el = document.querySelector("meta[name='description']");
    el.setAttribute("content", content);
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, refid } = this.state;

    return (
      <React.Fragment>
        <div className="my-4 pt-0"></div>
        <SystemLogo refid={refid} />
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-4">
            <main className="container">
              <div className="pb-3 pt-4">
                <div className="d-flex justify-content-start">
                  <h4 className="pb-2">Log in to {getWebsiteName()}</h4>
                </div>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput("email", "Email", "text", "Your email")}

                  {this.renderInput(
                    "password",
                    "Password",
                    this.state.showpass ? "text" : "password",
                    "8 characters or more"
                  )}
                  <div
                    onClick={() =>
                      this.setState({ showpass: !this.state.showpass })
                    }
                    className="float-right pointer btn-link mb-4"
                    style={{ marginTop: "-15px" }}>
                    {this.state.showpass === false ? (
                      <>
                        <VisibilityOutlinedIcon /> Show Password
                      </>
                    ) : (
                      <>
                        <VisibilityOffOutlinedIcon /> Hide Password
                      </>
                    )}
                  </div>
                  {!loading && this.renderBlockButton("Log In")}
                  {loading && this.renderLoadingBlockButton("Logging in...")}
                </form>
                <div className="forgotpassword text-center pt-4">
                  <Link to={`/forgot/${refid}`}>Forgotten password?</Link>
                </div>
              </div>
              <div className="my-4 pb-2">
                <hr />
              </div>
              {/* <div className="text-center">
                <Link
                  className="btn-success btn-lg btn-block px-4 my-4"
                  to={`/register/${refid}`}>
                  Create New Account
                </Link>
              </div> */}
              <div className="my-4 py-4">
                <Footer />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginForm;
