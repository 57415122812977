// Example usage with the combined phrases
const allPhrases = [
  "Stay tuned for exciting updates!",
  "Discover the latest in upcoming posts.",
  // "Your feed's about to get better!",
  // "Get ready for a surprise!",
  // "Unleash the future of content.",
  // "Something awesome is coming soon!",
  // "Stay in the loop – watch this space!",
  // "Exciting announcements on the horizon!",
  // "Your timeline is about to shine!",
  "Don't miss out – stay updated!",
  "New content drops – stay tuned!",
  "Surprise alert in our next post!",
  "Stay connected for quick updates!",
  "Boost your feed with fresh content!",
  "For more like this...",
  "More like this...",
  "More posts like this...",
  "More content like this...",
  "More like this coming soon...",
  "Please share this post.",
  "Like this post? Share it!",
  "Share this post!",
  "Share this post with your friends!",
  "Share this post with your followers!",
  "Share this post with your network!",
  "Share this post with your audience!",
  "Share this post with your community!",
  "Share this post with your colleagues!",
  "Share this post with your family!",
  "Share this post with your friends and family!",
  "Share this post with your friends and followers!",
  // "Exciting news is on the way!",
];

export function getRandomInstruction(value) {
  // Check if the array is empty
  if (allPhrases.length === 0) {
    return null;
  }

  // Generate a random index
  const randomIndex = Math.floor(value * allPhrases.length);

  // Return the random item
  return allPhrases[randomIndex];
}
