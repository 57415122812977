import fire from "../../../services/fire";
import { getCurrentUserData } from "../../../services/user";

function compare(a, b) {
  if (a.friendname < b.friendname) {
    return -1;
  }
  if (a.friendname > b.friendname) {
    return 1;
  }
  return 0;
}
export async function generateFriends(uid) {
  const db = fire.firestore();
  const map = new Map();
  const list = [];
  //get notes that you received
  const recv = await db
    .collection("notes")
    .where("recepientid", "==", uid)
    .get();

  recv.forEach((doc) => {
    const data = doc.data();
    if (!map.has(data.authorid)) {
      map.set(data.authorid, data);

      list.push({
        ...data,
        friendname: data.author,
        friendcountry: data.country || "",
        friendpic: data.smallpic,
        friendid: data.authorid,
      });
    }
  });

  // console.log("Received:");
  // console.log({ list });
  //get notes that you sent and was recevied
  const sent = await db
    .collection("notes")
    .where("authorid", "==", uid)
    .where("hasrecepient", "==", true)
    .get();

  sent.forEach((doc) => {
    const data = doc.data();
    if (!map.has(data.recepientid)) {
      map.set(data.recepientid, data);

      list.push({
        ...data,
        friendname: data.recepientname,
        friendcountry: data.recepientcountry || "",
        friendpic: data.recepientpic,
        friendid: data.recepientid,
      });
    }
  });
  // console.log("Received and Sent:");
  //sort
  list.sort(compare);
  // console.log({ list });
  return list;
  //   return [];
}

export async function saveGeneratedFriends(uid, friends) {
  const db = fire.firestore();
  const curtime = new Date().getTime();
  const updatedFriends = [];
  for (let i = 0; i < friends.length; i++) {
    let friend = friends[i];
    // console.log(friend);
    const user = await getCurrentUserData(friend.friendid);
    const friendcountry = friend.friendcountry || user?.country || "";

    const friendpic = user?.smallpic || "";
    const friendRef = db.collection(`friends`).doc();
    // const friendRef = db.doc(`friends/${uid}/friend/${friend.friendid}`);
    const friendObj = {
      authorid: uid,
      friendname: friend.friendname,
      friendcountry: friendcountry,
      friendpic: friendpic,
      friendid: friend.friendid,
      timestamp: curtime,
    };
    if (!friendRef.exists) {
      console.log("No present");
      await friendRef.set(friendObj);
    } else {
      console.log("Present");
      await friendRef.update(friendObj);
    }
    updatedFriends.push({ ...friend, ...friendObj });
  }
  return updatedFriends;
}

export async function getGeneratedFriends(uid) {
  const db = fire.firestore();
  try {
    const friendData = await db
      .collection(`friends`)
      .where("authorid", "==", uid)
      .get();
    const friends = [];
    friendData.forEach((doc) => {
      const data = doc.data();
      friends.push({ ...data, id: doc.id });
    });
    return friends.sort(compare);
  } catch (e) {
    console.log({ e });
  }

  return null;
}

export async function autoGenerateSaveFriends(uid) {
  //build here
  let friends = await generateFriends(uid);
  //write friends to db
  friends = await saveGeneratedFriends(uid, friends);
  // console.log({ friends });
  //update flag
  const db = fire.firestore();

  const userRef = db.doc(`users/${uid}`);
  await userRef.update({ hasgeneratedfriends: true });
  return friends;
}

export async function addNewRelationship(sender, receiver) {
  //check if present
  const db = fire.firestore();

  const friends1 = await db
    .collection("friends")
    .where("authorid", "==", sender.id)
    .where("friendid", "==", receiver.id)
    .get();

  const senderFriends = [];
  friends1.forEach((doc) => {
    senderFriends.push(doc.data());
  });

  if (senderFriends.length === 0) {
    //write
    const friendRef = db.collection(`friends`).doc();
    const friendObj = {
      authorid: sender.id,
      friendname: receiver.name,
      friendcountry: receiver.country,
      friendpic: receiver.photo,
      friendid: receiver.id,
      timestamp: new Date().getTime(),
    };
    // console.log({ friendObj });
    await friendRef.set(friendObj);
  }
  //check other side
  const friends2 = await db
    .collection("friends")
    .where("authorid", "==", receiver.id)
    .where("friendid", "==", sender.id)
    .get();
  const receiverFriends = [];
  friends2.forEach((doc) => {
    receiverFriends.push(doc.data());
  });
  if (receiverFriends.length === 0) {
    const friendRef = db.collection(`friends`).doc();
    const friendObj = {
      authorid: receiver.id,
      friendname: sender.name,
      friendcountry: sender.country,
      friendpic: sender.photo,
      friendid: sender.id,
      timestamp: new Date().getTime(),
    };
    // console.log({ friendObj });

    await friendRef.set(friendObj);
  }
}
