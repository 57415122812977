import React from "react";
import TitleCreator from "./titleCreator";
import SpinnerText from "../../common/spinnerwithText";
import PanoramaRoundedIcon from "@material-ui/icons/PanoramaRounded";
import { convertFireStorageUrl } from "../../../utils/url";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

function HeadingSectionPhotoUpload({
  type,
  subtitle,
  json,
  content,
  owner,
  smallpic,
  photoUrl,
  user,
  fileUpload,
  uploadingPhoto,
}) {
  return (
    <div className="appearslowly container px-0">
      <div className="row justify-content-center mx-2">
        <div className="col-lg-4 px-0">
          <div className="d-xs-block d-sm-block d-md-block d-lg-none mb-4">
            <TitleCreator
              type={type}
              subtitle={subtitle}
              title={json.title}
              content={content}
              owner={owner}
              smallpic={smallpic}
            />
          </div>

          <React.Fragment>
            {!photoUrl && (
              <div
                className="p-4 d-flex justify-content-center align-items-center"
                style={{
                  border: "1px dashed #a0a0a0",
                  borderRadius: "10px",
                }}>
                {uploadingPhoto ? (
                  <SpinnerText text="" />
                ) : (
                  <PanoramaRoundedIcon
                    className="my-5"
                    style={{ fontSize: "100px", opacity: "0.4" }}
                  />
                )}
              </div>
            )}
            {photoUrl && (
              <img
                className="responsive img-fluid"
                style={{
                  borderRadius: "8px",
                  boxShadow: "1px 1px 10px -4px #888888",
                }}
                src={convertFireStorageUrl(photoUrl)}
                alt="Sample Image"
              />
            )}
            {user && (
              <div className="mt-4 photo-upload text-center">
                <label htmlFor="file-input" className="image-upload-icon">
                  Upload Photo <AddAPhotoIcon />
                </label>

                <input
                  id="file-input"
                  type="file"
                  onChange={fileUpload}
                  accept="image/*"
                />
              </div>
            )}
          </React.Fragment>
        </div>
        <div className="col-lg-8 pl-4 d-none d-lg-block">
          <TitleCreator
            type={type}
            subtitle={subtitle}
            title={json.title}
            content={content}
            owner={owner}
            smallpic={smallpic}
          />

          <p className="pt-4 text-left">{json.description}</p>
        </div>
        <div className="d-xs-block d-sm-block d-md-block d-lg-none">
          <p className="pt-4 text-left">{json.description}</p>
        </div>
      </div>
    </div>
  );
}

export default HeadingSectionPhotoUpload;
