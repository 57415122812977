import React, { Component } from "react";
import Header from "../../common/header";
import { getCurrentUserData, userLogout } from "../../../services/user";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import LinkButton from "../../blocks/linkButtonBlock";
import { getLatestVersion } from "../../../services/getversion";
import ReloadBar from "../../common/reloadBar";
import {
  generateFriends,
  getGeneratedFriends,
  saveGeneratedFriends,
} from "../services/friends";
import ScrollToTop from "../../common/scrollToTop";
import FriendList from "../component/friendList";
import FriendInfoModal from "../component/friendInfoModal";
import NoFriends from "../component/noFriends";
import fire from "../../../services/fire";
import { Tab, Tabs } from "react-bootstrap";
import NetworkList from "../component/networkList";

class FriendsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabkey: "following",
      data: {
        fullname: "",
        email: "",
        storecontact: "",
        storelink: "",
        storename: "",
        streak: [],
      },
      followings: [],

      user: this.props.user,
      loading: true,
    };
  }

  async getCurrentUserData() {
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        totalnotes,
        totalreceived,
        totalsent,
        fullname,
        email,
        storecontact,
        storelink,
        storename,
        bigpic,
        country,
        streak,
        userhandle,
        hasgeneratedfriends,
      } = user;
      const photoUrl = bigpic;
      let el = document.querySelector("title");
      const title = `Your Network`;
      const content = `Join the Network of Filipino Health Professionals around the World 🌎`;
      el.innerText = title;
      el = document.querySelector("meta[name='description']");
      el.setAttribute("content", content);
      const version = await getLatestVersion();

      // let buildfriends = false;
      let followings = [];
      // if (!hasgeneratedfriends) {
      //   //build here
      //   followings = await generateFriends(uid);
      //   //write followings to db
      //   followings = await saveGeneratedFriends(uid, followings);
      //   // console.log({ followings });
      //   buildfriends = true;
      //   //update flag
      //   const db = fire.firestore();

      //   const userRef = db.doc(`users/${uid}`);
      //   await userRef.update({ hasgeneratedfriends: true });
      // } else {
      //   //get from collection here

      //   followings = await getGeneratedFriends(uid);
      // }
      const result = await fire
        .firestore()
        .collection(`followings`)
        .doc(user.userid)
        .collection(`userfollowings`)
        .get();
      // if (result && result.exists) {
      //   const data = result.data();
      //   console.log(data);
      // }
      result.forEach((doc) => {
        const data = doc.data();
        // console.log(data);
        followings.push(data);
      });
      // console.log({ followings });
      this.setState({
        version,
        totalnotes,
        totalreceived,
        totalsent,
        followings,
        userhandle,
        // hasgeneratedfriends: buildfriends,
        data: {
          fullname,
          email,
          storecontact,
          storelink,
          storename,
          country,
          streak,
        },
        photoUrl,
        loading: false,
      });
    } else {
      await userLogout();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
  }

  reloadProfile = () => {
    window.location.reload();
  };

  processUserView = async (entry) => {
    // console.log(entry);
    // console.log(type);
    const id = entry.friendid;
    //get recepient details
    // const id = type === "sent"?entry.recepientid;
    const user = await getCurrentUserData(id);
    // console.log(user);
    this.setState({
      viewusermodal: { showmodal: true, obj: user },
    });
  };

  handleClose = () => {
    this.setState({
      viewusermodal: { showmodal: false, obj: null },
    });
  };

  viewUserModal = () => {
    const viewusermodal = this.state.viewusermodal;
    if (!viewusermodal && !viewusermodal?.obj) {
      return null;
    }
    return (
      <FriendInfoModal args={viewusermodal} closeHandler={this.handleClose} />
    );
  };

  handleFollowers = async (tabkey) => {
    // console.log(tabkey);
    if (tabkey === "following") {
      console.log("Following");
    } else {
      console.log("Followers");
      const uid = this.state.user.uid;

      const result = await fire
        .firestore()
        .collection(`followers`)
        .doc(uid)
        .collection(`userfollowers`)
        .get();

      const followers = [];
      result.forEach((doc) => {
        const data = doc.data();
        // console.log(data);
        followers.push(data);
      });
      // console.log(followers);
      this.setState({ followers: followers || [] });
    }
  };

  render() {
    const { loading, version, followings, followers } = this.state;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backandlogo"
          hidehome
          hidefriends
          hide="profile"
        />
      );
    }

    return (
      <React.Fragment>
        <Header type="backandlogo" hidehome hidefriends hide="profile" />
        <ReloadBar version={version} />

        <div className="row justify-content-center mx-auto appearslowly">
          <div className="col-lg-6 text-center px-0">
            <main className="container text-center">
              <h4 className="text-left pt-4 mt-2 mb-4 ml-2 text-primary">
                {`Your Network `}
              </h4>
              <Tabs
                defaultActiveKey="following"
                id="controlled-tab-example"
                activeKey={this.state.tabkey}
                onSelect={(k) => {
                  this.setState({ tabkey: k });
                  this.handleFollowers(k);
                }}>
                <Tab eventKey="following" title="Following">
                  <NetworkList
                    list={followings}
                    state={this.state}
                    type="following"
                    handler={(i) => this.props.history.push(`/${i.userhandle}`)}
                  />
                </Tab>
                <Tab eventKey="followers" title="Followers">
                  <NetworkList
                    list={followers}
                    type="follower"
                    state={this.state}
                    handler={(i) => this.props.history.push(`/${i.userhandle}`)}
                  />
                </Tab>
              </Tabs>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FriendsPage;
