import fire from "./fire";

export async function getLatestVersion() {
  console.log("Checking version...");
  const db = fire.firestore();
  const version = await db.doc(`version/web`).get();

  if (version && version.exists) {
    let data = version.data();
    return data.version;
  } else {
    return "1.0.0";
  }
}
