import React, { Component } from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import CakeOutlinedIcon from "@material-ui/icons/CakeOutlined";
import AlarmOutlinedIcon from "@material-ui/icons/AlarmOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import ReactCountryFlag from "react-country-flag";

class MyProfileCardPanel extends Component {
  constructor(props) {
    super(props);
    const { fullname, email, storecontact, storelink, storename, country } =
      props.data;

    this.state = {
      fullname,
      email,
      storecontact,
      storelink,
      storename,
      country,
      verifiedemail: this.props.verifiedemail,
    };
  }

  isCompleteProfileDetails() {
    let { fullname, email, mobile, dob, identification, parish } = this.state;
    const isComplete =
      fullname && email && dob && mobile && identification && parish;
    return isComplete;
  }

  getIconByField(field) {
    let icon;
    if (field === "email") {
      icon = <MailOutlineIcon style={{ fontSize: "24px" }} />;
    } else if (field === "store") {
      icon = <StorefrontOutlinedIcon style={{ fontSize: "24px" }} />;
    } else if (field === "fullname") {
      icon = <AccountCircleOutlinedIcon style={{ fontSize: "24px" }} />;
    } else if (field === "fb") {
      icon = <FacebookIcon style={{ fontSize: "24px" }} />;
    } else if (field === "web") {
      icon = <LanguageOutlinedIcon style={{ fontSize: "24px" }} />;
    } else if (field === "mobile") {
      icon = <CallOutlinedIcon style={{ fontSize: "24px" }} />;
    } else if (field === "country") {
      icon = <PublicOutlinedIcon style={{ fontSize: "24px" }} />;
    } else if (field === "identification") {
      icon = <AssignmentIndOutlinedIcon style={{ fontSize: "24px" }} />;
    } else if (field === "dob") {
      icon = <CakeOutlinedIcon style={{ fontSize: "24px" }} />;
    } else if (field === "parish") {
      icon = <AccountBalanceOutlinedIcon style={{ fontSize: "24px" }} />;
    } else if (field === "preferredmasstiming") {
      icon = <AlarmOutlinedIcon style={{ fontSize: "24px" }} />;
    }

    return icon;
  }

  displayProfileDetails(label, field, value) {
    const { verifiedemail } = this.state;
    return (
      <React.Fragment>
        <div
          className="pt-1 float-left"
          style={{ height: "50px", width: "40px" }}>
          {this.getIconByField(field)}
        </div>
        <div className="pt-1  text-left">
          {value}{" "}
          {field === "country" && (
            <span className="">
              <ReactCountryFlag
                countryCode={value}
                className="emojiFlag"
                style={{
                  fontSize: "1.4em",
                  lineHeight: "1em",
                }}
              />
            </span>
          )}
          <br />
          <span style={{ color: "#b0b0b0" }}>{label}</span>{" "}
          {field === "email" && verifiedemail === true && (
            <span className="badge badge-pill badge-success">Verified</span>
          )}
          {field === "email" && verifiedemail === false && (
            <span className="badge badge-pill badge-danger">Unverified</span>
          )}
        </div>
      </React.Fragment>
    );
  }

  render() {
    let { email, storecontact, storelink, storename, fullname, country } =
      this.state;

    return (
      <div className="card mb-4">
        <ul className="list-group list-group-flush">
          {fullname && (
            <li className="list-group-item px-1">
              {this.displayProfileDetails("Name", "fullname", fullname)}
            </li>
          )}
          {email && (
            <li className="list-group-item px-1">
              {this.displayProfileDetails(
                "Email",
                "email",
                email.toLowerCase()
              )}
            </li>
          )}
          {country && (
            <li className="list-group-item px-1">
              {this.displayProfileDetails("Country", "country", country)}
            </li>
          )}
          {storelink && (
            <li className="list-group-item px-1">
              {this.displayProfileDetails(
                "PalitNa Store Link",
                "web",
                storelink
              )}
            </li>
          )}
          {storecontact && (
            <li className="list-group-item px-1">
              {this.displayProfileDetails(
                "FaceBook Profile Link",
                "fb",
                storecontact.replace("https://www.facebook.com/", "")
              )}
            </li>
          )}
        </ul>
      </div>
    );
  }
}

export default MyProfileCardPanel;
