import React, { Component } from "react";
import Header from "../../common/header";
import fire from "../../../services/fire";
import { convertToDate } from "../../../utils/utils";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";

class BasicStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noteFreq: [],
      noteList: [],
      userFreq: [],
      loading: true,
    };
  }

  async componentDidMount() {
    await this.getStatsData();
  }

  async getStatsData() {
    //retrieve data from firestore

    try {
      fire
        .firestore()
        .collection("notes")
        .orderBy("created", "desc")
        .get()
        .then(async (querySnapshot) => {
          const list = [];
          const noteFreq = [];
          const userFreq = [];
          const dailyUsers = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const date = convertToDate(data.created);
            const authorid = data.authorid;
            const tempId = authorid.substr(0, 6);
            const compId = `${date}*${tempId}`;
            list.push({ ...data, datestr: date });
            //create noteFreq Tab
            if (noteFreq[date]) {
              noteFreq[date]++;
            } else {
              noteFreq[date] = 1;
            }
            //create dailyUsers Tab
            if (dailyUsers[compId]) {
              dailyUsers[compId]++;
            } else {
              dailyUsers[compId] = 1;
            }
            //create userFreq Tab
            if (userFreq[tempId]) {
              userFreq[tempId]++;
            } else {
              userFreq[tempId] = 1;
            }
          });

          if (list.length) {
            console.log("List: ", list);
          }

          console.log(dailyUsers);

          const usersDaily = [];

          for (const property in dailyUsers) {
            // console.log(`${property}: ${dailyUsers[property]}`);
            const idx = property.indexOf("*");
            const day = property.substring(0, idx);
            // const id = property.substring(idx + 1, property.length);
            // console.log(`${id} ${day}`);
            //create userFreq Tab
            if (usersDaily[day]) {
              usersDaily[day]++;
            } else {
              usersDaily[day] = 1;
            }
          }
          console.log(usersDaily);
          console.log(noteFreq);
          console.log(userFreq);
          this.setState({
            loading: false,
            noteFreq: noteFreq,
            noteList: list,
            userFreq: userFreq,
          });
        });
    } catch (error) {
      console.error("Notes not yet created");
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    if (loading === true) {
      return <LoadingSpinnerNav hide="profile" />;
    }

    return (
      <React.Fragment>
        <Header hide="profile" />
        <div className="row justify-content-center  mx-0">
          <div className="col-lg-6">
            <main className="container mx-0 px-0">
              {/* <h1 className="pb-3 pt-4 px-0">Basic Stats</h1> */}
              {/* <h3>Daily Notes</h3> */}
              {/* {noteFreq.map((entry, index) => {
                return <div id={index}>{`${entry} ${index}`}</div>;
              })} */}
              {/* <h3>Daily Users</h3> */}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BasicStats;
