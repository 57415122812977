import React from "react";

const MilestoneIcon = ({ ...rest }) => {
  let { count, size } = rest;

  if (!size) {
    size = "80px";
  }
  let icon = (
    <span
      className="text-center pointer"
      style={{ fontSize: size }}
      role="img"
      aria-label="champion"
    >
      👍
    </span>
  );

  if (count === 7) {
    icon = (
      <span
        className="text-center pointer"
        style={{ fontSize: size }}
        role="img"
        aria-label="champion"
      >
        🏆
      </span>
    );
  } else if (count === 6) {
    icon = (
      <span
        className="text-center pointer"
        style={{ fontSize: size }}
        role="img"
        aria-label="gold"
      >
        🏅
      </span>
    );
  } else if (count === 5) {
    icon = (
      <span
        className="text-center pointer"
        style={{ fontSize: size }}
        role="img"
        aria-label="first"
      >
        🥇
      </span>
    );
  } else if (count === 4) {
    icon = (
      <span
        className="text-center pointer"
        style={{ fontSize: size }}
        role="img"
        aria-label="second"
      >
        🥈
      </span>
    );
  } else if (count === 3) {
    icon = (
      <span
        className="text-center pointer"
        style={{ fontSize: size }}
        role="img"
        aria-label="third"
      >
        🥉
      </span>
    );
  } else if (count === 2) {
    icon = (
      <span
        className="text-center pointer"
        style={{ fontSize: size }}
        role="img"
        aria-label="clap"
      >
        👏
      </span>
    );
  }
  return icon;
};

export default MilestoneIcon;
