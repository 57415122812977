import React from "react";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import { Image as ReactImage } from "react-bootstrap";
import { convertFireStorageUrl } from "../../../utils/url";

function ProfileTime({ timeago, item, haspic, loggedin, photo }) {
  //   console.log({ timeago });
  //   console.log({ item });
  //   console.log({ haspic });
  //   console.log({ loggedin });

  return (
    <div>
      {!loggedin && !haspic && (
        <>
          <AccountCircleTwoToneIcon
            className="text-primary"
            style={{ fontSize: "20px" }}
          />{" "}
          {timeago && <span style={{ fontSize: "14px" }}>{timeago}</span>}
        </>
      )}
      {loggedin && !haspic && (
        <>
          <AccountCircleTwoToneIcon
            className="text-primary"
            style={{ fontSize: "20px" }}
          />{" "}
          {timeago && <span style={{ fontSize: "14px" }}>{timeago}</span>}
        </>
      )}
      {loggedin && haspic && (
        <>
          <ReactImage
            src={convertFireStorageUrl(photo)}
            width="15px"
            height="15px"
            alt=""
            roundedCircle
            style={{
              zIndex: "1",
              marginTop: "2px",
            }}
          />{" "}
          {timeago && <span style={{ fontSize: "14px" }}>{timeago}</span>}
        </>
      )}
    </div>
  );
}

export default ProfileTime;
