import React, { Component } from "react";
import { Button } from "react-bootstrap";
import RefreshIcon from "@material-ui/icons/Refresh";
import { getWebVersion } from "../../services/settings";

class ReloadBar extends Component {
  doReload = () => {
    window.location.reload();
  };

  render() {
    const { text } = this.props;
    const webversion = getWebVersion();
    const version = this.props.version;
    if (webversion === version) {
      return null;
    }
    console.log(`Latest version: ${version} Web Version: ${webversion}`);
    let label = text ? text : "Update Available";
    return (
      <div className="appearslowly mb-2 p-2 text-center clickablebar alert-success">
        <Button
          variant="success"
          className="btn-lg px-4"
          onClick={this.doReload}
        >
          <div className="iconcircle float-left mt-1">
            <RefreshIcon
              fontSize="small"
              className="darkgreencolor"
              style={{ marginTop: "-4px" }}
            />
          </div>
          <div className="pl-3 float-right text-left">
            <div className="standardfontsize">{label}</div>
            <div style={{ marginTop: "-10px" }}>
              <span className="defaultstandardfontsize">Click to Update</span>
            </div>
          </div>
        </Button>
      </div>
    );
  }
}

export default ReloadBar;
