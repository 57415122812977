import React from "react";
import BlockRoundedIcon from "@material-ui/icons/BlockRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { getColorOptions } from "../../utils/colors";

const options = getColorOptions();

function ColorOptions({ selected = 0, handler = () => {} }) {
  //   const options = [
  //     { id: 0, bgcolor: "", border: "#c0c0c0", iconcolor: "" },
  //     { id: 1, bgcolor: "bg-success", border: "#fff", iconcolor: "#28a745" },
  //     { id: 2, bgcolor: "bg-primary", border: "#fff", iconcolor: "#007bff" },
  //     { id: 3, bgcolor: "bg-info", border: "#fff", iconcolor: "#17a2b8" },
  //     { id: 4, bgcolor: "bg-warning", border: "#fff", iconcolor: "#ffc107" },
  //     { id: 5, bgcolor: "bg-danger", border: "#fff", iconcolor: "#dc3545" },
  //     {
  //       id: 6,
  //       bgcolor: "alert alert-success",
  //       border: "#fff",
  //       iconcolor: "#d4edda",
  //     },
  //     {
  //       id: 7,
  //       bgcolor: "alert alert-primary",
  //       border: "#fff",
  //       iconcolor: "#cce5ff",
  //     },
  //     {
  //       id: 8,
  //       bgcolor: "alert alert-warning",
  //       border: "#fff",
  //       iconcolor: "#fff3cd",
  //     },
  //     {
  //       id: 9,
  //       bgcolor: "alert alert-danger",
  //       border: "#fff",
  //       iconcolor: "#f8d7da",
  //     },
  //     {
  //       id: 10,
  //       bgcolor: "alert alert-danger",
  //       border: "#fff",
  //       iconcolor: "#f8d7da",
  //     },
  //     {
  //       id: 11,
  //       bgcolor: "alert alert-danger",
  //       border: "#fff",
  //       iconcolor: "#f8d7da",
  //     },
  //     {
  //       id: 12,
  //       bgcolor: "alert alert-danger",
  //       border: "#fff",
  //       iconcolor: "#f8d7da",
  //     },
  //     {
  //       id: 13,
  //       bgcolor: "alert alert-danger",
  //       border: "#fff",
  //       iconcolor: "#f8d7da",
  //     },
  //   ];

  const Options = ({ chosen, opt }) => {
    console.log(opt);
    return (
      <div
        onClick={() => handler(opt)}
        className={`d-flex align-items-center justify-content-center col pointer roundcornernocolor m-1 ${opt.bgcolor}`}
        style={{
          maxWidth: "40px",
          height: "40px",
          border:
            chosen === false ? `1px solid ${opt.border}` : `2px solid #666666`,
        }}>
        {opt.id === 0 ? (
          <BlockRoundedIcon style={{ color: `${opt.iconcolor}` }} />
        ) : (
          <CheckRoundedIcon style={{ color: `${opt.iconcolor}` }} />
        )}
      </div>
    );
  };
  return (
    <div className="row px-4">
      {options.map((i, idx) => {
        return <Options key={idx} chosen={i.id === selected} opt={i} />;
      })}
    </div>
  );
}

export default ColorOptions;
