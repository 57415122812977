import React, { Component } from "react";
import Header from "../../common/header";
import { getCurrentUserData, userLogout } from "../../../services/user";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import LinkButton from "../../blocks/linkButtonBlock";
import { getLatestVersion } from "../../../services/getversion";
import ReloadBar from "../../common/reloadBar";
import {
  getNotifications,
  openNotifications,
} from "../../../services/notifications";
import ScrollToTop from "../../common/scrollToTop";
import NotificationList from "../component/notificationList";
import NoNotications from "../component/noNotifications";

class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fullname: "",
        email: "",
        streak: [],
      },
      friends: [],
      user: this.props.user,
      loading: true,
    };
  }

  async getCurrentUserData() {
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        totalnotes,
        totalreceived,
        totalsent,
        fullname,
        email,
        bigpic,
        country,
        streak,
      } = user;
      const photoUrl = bigpic;
      let el = document.querySelector("title");
      const title = `Notifications`;
      const content = `Together we spread gratitude around the world!`;
      el.innerText = title;
      el = document.querySelector("meta[name='description']");
      el.setAttribute("content", content);
      const version = await getLatestVersion();

      const notifications = await getNotifications(uid);
      // console.log({ notifications });
      this.setState({
        version,
        totalnotes,
        totalreceived,
        totalsent,
        notifications,
        data: {
          fullname,
          email,
          country,
          streak,
        },
        photoUrl,
        loading: false,
      });
    } else {
      await userLogout();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
  }

  reloadProfile = () => {
    window.location.reload();
  };

  processUserView = async (entry) => {
    // console.log(entry);
    // console.log(type);
    const id = entry.friendid;
    //get recepient details
    // const id = type === "sent"?entry.recepientid;
    const user = await getCurrentUserData(id);
    // console.log(user);
    this.setState({
      viewusermodal: { showmodal: true, obj: user },
    });
  };

  handleClose = () => {
    this.setState({
      viewusermodal: { showmodal: false, obj: null },
    });
  };

  handleClickNotification = async (entry) => {
    if (!entry.open) {
      await openNotifications(entry);
    }
    this.props.history.push(`/p/${entry.refid}`);
  };

  render() {
    const { loading, version, notifications } = this.state;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backandlogo"
          hidehome
          hidefriends
          hide="profile"
        />
      );
    }

    return (
      <React.Fragment>
        <Header type="backandlogo" hidehome hidefriends hide="profile" />
        <ReloadBar version={version} />

        <div className="row justify-content-center mx-auto appearslowly">
          <div className="col-lg-6 text-center px-0">
            <main className="container text-center">
              <h4 className="text-left pt-4 mt-2 ml-2 text-primary">
                {notifications.length
                  ? `Notifications`
                  : `No New Notifications`}
              </h4>
              <div className="py-4 pointer text-center">
                {notifications &&
                  notifications.map((entry, index) => {
                    return (
                      <NotificationList
                        key={index}
                        entry={entry}
                        clickHandler={() => this.handleClickNotification(entry)}
                      />
                    );
                  })}
              </div>
              {notifications.length === 0 && <NoNotications />}
              {notifications.length > 5 && (
                <>
                  <ScrollToTop />
                  <LinkButton type="link" link="/home" label="Back to Home" />
                </>
              )}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NotificationsPage;
