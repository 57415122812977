const options = [
  {
    id: 0,
    bgcolor: "",
    border: "#c0c0c0",
    iconcolor: "",
    foreground: "",
    linkcolor: "#007bff",
  },
  {
    id: 6,
    bgcolor: "alert alert-success",
    border: "#fff",
    iconcolor: "#d4edda",
    foreground: "",
    linkcolor: "#155724",
  },
  {
    id: 7,
    bgcolor: "alert alert-primary",
    border: "#fff",
    iconcolor: "#cce5ff",
    foreground: "",
    linkcolor: "#004085",
  },
  {
    id: 8,
    bgcolor: "alert alert-warning",
    border: "#fff",
    iconcolor: "#fff3cd",
    foreground: "",
    linkcolor: "#856404",
  },
  {
    id: 9,
    bgcolor: "alert alert-danger",
    border: "#fff",
    iconcolor: "#f8d7da",
    foreground: "",
    linkcolor: "#721c24",
  },
  {
    id: 1,
    bgcolor: "bg-success",
    border: "#fff",
    iconcolor: "#28a745",
    foreground: "#fff",
    linkcolor: "#fff",
  },
  {
    id: 2,
    bgcolor: "bg-primary",
    border: "#fff",
    iconcolor: "#007bff",
    foreground: "#fff",
    linkcolor: "#fff",
  },
  {
    id: 3,
    bgcolor: "bg-info",
    border: "#fff",
    iconcolor: "#17a2b8",
    foreground: "#fff",
    linkcolor: "#fff",
  },
  {
    id: 4,
    bgcolor: "bg-warning",
    border: "#fff",
    iconcolor: "#ffc107",
    foreground: "#333",
    linkcolor: "#333",
  },
  // {
  //   id: 5,
  //   bgcolor: "bg-danger",
  //   border: "#fff",
  //   iconcolor: "#dc3545",
  //   foreground: "#fff",
  //   linkcolor: "#fff",
  // },
];

export function getColorOptions() {
  return options;
}

export function getThemeData(opt) {
  const opts = getColorOptions();
  let option = {};
  for (let i = 0; i < opts.length; i++) {
    if (opt === opts[i].id) {
      option = opts[i];
      break;
    }
  }
  return option;
}
