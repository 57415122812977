import React from "react";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { convertFireStorageUrl } from "../../../utils/url";
import { Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import ProfileFullname from "../../profile/component/profileFullname";
import EditIcon from "@material-ui/icons/Edit";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import LinkCopiedModal from "./linkCopiedModal";

function BasicInfo({
  state,
  data,
  enablePicUpload,
  photoUrl,
  linkcolor,
  forecolor,
  fullname,
  onFileChange,
  onHide,
  onCopy,
  showlink = false,
}) {
  function copyText() {
    // if (text.length > 70) {
    //   //text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    //   text = `${this.shorten(text, 83)}... See more.`;
    // }
    const texttocopy = `https://jeyner.ai/u/${data.userhandle}`;
    return texttocopy;
  }

  return (
    <div className="d-flex justify-content-center flex-column">
      <h1 className="mx-auto mt-0 text-black-50 text-center">
        {!enablePicUpload && (
          <PersonRoundedIcon
            // className="text-primary"
            style={{
              fontSize: "150px",
            }}
          />
        )}
        {enablePicUpload && (
          <React.Fragment>
            {!photoUrl && (
              <PersonRoundedIcon
                // className="text-primary"
                style={{ fontSize: "150px", color: linkcolor }}
              />
            )}
            {photoUrl && (
              <Image
                src={convertFireStorageUrl(photoUrl)}
                width="150px"
                height="150px"
                alt=""
                roundedCircle
              />
            )}
            <div className="image-upload">
              <label htmlFor="file-input" className="image-upload-icon">
                <OverlayTrigger overlay={<Tooltip>Upload Photo</Tooltip>}>
                  <AddAPhotoIcon style={{ color: linkcolor }} />
                </OverlayTrigger>
              </label>

              <input
                id="file-input"
                type="file"
                onChange={onFileChange}
                accept="image/*"
              />
            </div>
          </React.Fragment>
        )}
      </h1>
      {/* </nav> */}
      <div
        className="d-flex justify-content-center"
        style={{ color: forecolor }}>
        <ProfileFullname
          isvalidfullname={true}
          fullname={data.storename || fullname}
        />
        <Link className="ml-1 mt-2 text-center" to="/account/edit">
          <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
            <EditIcon style={{ fontSize: "18px", color: linkcolor }} />
          </OverlayTrigger>
        </Link>
      </div>
      {showlink && data?.userhandle && (
        <div className="d-flex justify-content-center">
          <div className=" py-1 my-0 ">
            <a
              style={{ color: forecolor }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://jeyner.ai/u/${data.userhandle}`}>
              www.jeyner.ai/u/{data.userhandle}{" "}
            </a>
          </div>
          <CopyToClipboard text={copyText()} onCopy={onCopy}>
            <Button variant="pure" className="btn-sm py-0">
              <div className="float-left">
                <OverlayTrigger overlay={<Tooltip>Copy Link</Tooltip>}>
                  <FileCopyOutlinedIcon
                    style={{
                      color: linkcolor,
                      fontSize: "18px",
                      marginTop: "-2px",
                    }}
                  />
                </OverlayTrigger>
              </div>
            </Button>
          </CopyToClipboard>
          <LinkCopiedModal state={state} onHide={onHide} />
        </div>
      )}
      {data?.bio && (
        <div>
          <h5 className=" py-1 my-0 " style={{ color: forecolor }}>
            {data?.bio}
          </h5>
        </div>
      )}
      {data?.phonenumber && data?.email && (
        <div>
          <div>
            <p className=" py-0 my-0 " style={{ color: forecolor }}>
              📧 {data?.email}
            </p>
          </div>
          <div>
            <p className=" py-0 my-0 " style={{ color: forecolor }}>
              📞 {data?.phonenumber}
            </p>
          </div>
        </div>
      )}
      {/* {data?.phonenumber && (
        <div>
          <p className=" py-0 my-0 " style={{ color: forecolor }}>
            {data?.phonenumber}
          </p>
        </div>
      )} */}

      {/* //display date a November 1, 2020 format */}

      {data?.dob && (
        <div>
          <p className=" py-0 my-0 " style={{ color: forecolor }}>
            🎂{" "}
            {new Date(data?.dob)?.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}{" "}
            🏷️ {data?.civilstatus}
          </p>
        </div>
      )}
      {data?.height && data?.weight && (
        <div>
          <p className=" py-0 my-0 " style={{ color: forecolor }}>
            {data?.height} cm &bull; {data?.weight} kg
          </p>
        </div>
      )}
      {data?.address && (
        <div>
          <p className=" py-0 my-0 " style={{ color: forecolor }}>
            {data?.address}
          </p>
        </div>
      )}
      {data?.country && (
        <p className="py-1 mb-1 " style={{ color: forecolor }}>
          {data?.countryStr}{" "}
          <span className="">
            <ReactCountryFlag
              countryCode={data?.country}
              className="emojiFlag"
              style={{
                fontSize: "1.2em",
                lineHeight: "1em",
              }}
            />
          </span>
        </p>
      )}
    </div>
  );
}

export default BasicInfo;
