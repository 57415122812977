import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import fire from "./services/fire";
import Home from "./core/home";
import LoadingSpinner from "./core/common/loadingSpinner";
import RegisterForm from "./core/account/page/register";
import LoginForm from "./core/account/page/login";
import AccountSetup from "./core/account/page/accountSetup";
import AccountSetupSuccess from "./core/account/page/accountSetupSuccess";
import ForgotPassword from "./core/account/page/forgot";
import RegisterSuccess from "./core/account/page/registerSuccess";
import AuthHandler from "./core/account/page/auth";
import PageNotFound from "./core/common/pageNotFound";
import ProfilePage from "./core/account/page/profile";
import UserProfilePage from "./core/account/page/userProfile";
import AccountVerify from "./core/account/page/accountVerify";
import ThankYouSetup from "./core/thankyounote/admin/setupThankYou";
import AccountEdit from "./core/account/page/accountEdit";
import ProtectedRoute from "./core/route/protectedRoute";
import WelcomePage from "./core/welcome/page/welcomePage";
import PublicHome from "./core/view/page/publicHome";
import PublicViewPage from "./core/view/page/publicViewPage";
import ViewMessage from "./core/thankyounote/page/viewMessage";
import WelcomePage1 from "./core/welcome/page/welcomePage1";
import BasicStats from "./core/stats/page/basicStats";
import FriendsPage from "./core/friends/page/friends";
import NotificationsPage from "./core/notifications/page/notifications";
import Privacy from "./core/privacy/page/privacy";
import DeleteRequest from "./core/privacy/page/deleterequest";
import AddFriend from "./core/friends/page/addFriend";
import Connections from "./core/friends/page/connections";
import InviteFriends from "./core/friends/component/inviteFriends";
import ViewLink from "./core/link/page/viewLink";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.archbishop) {
            user.archbishop = idTokenResult.claims.archbishop;
          } else if (idTokenResult.claims.archcomms) {
            user.archcomms = idTokenResult.claims.archcomms;
          } else if (idTokenResult.claims.parishpriest) {
            user.parishpriest = idTokenResult.claims.parishpriest;
          } else if (idTokenResult.claims.secretary) {
            user.secretary = idTokenResult.claims.secretary;
          } else if (idTokenResult.claims.superadmin) {
            user.superadmin = idTokenResult.claims.superadmin;
          } else if (idTokenResult.claims.support) {
            user.support = idTokenResult.claims.support;
          } else if (idTokenResult.claims.systemadmin) {
            user.systemadmin = idTokenResult.claims.systemadmin;
          } else if (idTokenResult.claims.systemvalidator) {
            user.systemvalidator = idTokenResult.claims.systemvalidator;
          } else if (idTokenResult.claims.apiconsumer) {
            user.apiconsumer = idTokenResult.claims.apiconsumer;
          }
          this.setState({ user, loading: false });
        });
      } else {
        this.setState({ user: null, loading: false });
      }
    });
  }

  render() {
    const { user, loading } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <Switch>
          {/*
          NO LOGIN REQUIRED
          */}

          <Route
            path="/welcome/:refid"
            render={(props) => {
              if (!user) {
                return <WelcomePage {...props} user={user} />;
              }
              return <Home {...props} user={user} />;
            }}
          />
          <Route
            path="/a/:refid"
            render={(props) => {
              if (!user) {
                return <Redirect to="/home" />;
              }
              return <AddFriend {...props} user={user} />;
            }}
          />
          <Route
            path="/l/:itemid"
            render={(props) => {
              return <ViewLink {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/welcome1"
            render={(props) => {
              return <WelcomePage1 {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/welcome"
            render={(props) => {
              if (!user) {
                return <WelcomePage {...props} user={user} />;
              }
              return <Home {...props} user={user} />;
            }}
          />

          <Route
            path="/auth"
            render={(props) => {
              return <AuthHandler {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/register/:refid"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <RegisterForm {...props} user={user} />;
            }}
          />
          <Route
            path="/register"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <RegisterForm {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/forgot/:refid"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <ForgotPassword {...props} user={user} />;
            }}
          />
          <Route
            path="/forgot"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <ForgotPassword {...props} user={user} />;
            }}
          />
          <Route
            path="/login/:refid"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <LoginForm {...props} user={user} />;
            }}
          />
          <Route
            path="/login"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <LoginForm {...props} user={user} />;
            }}
          />

          <Route
            path="/account/created"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <RegisterSuccess {...props} user={user} />;
            }}
          />
          <Route
            path="/account/setup"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AccountSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/account/edit"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AccountEdit {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/account/verify"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AccountVerify {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/account/success"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AccountSetupSuccess {...props} user={user} />;
            }}
          />

          <Route
            path="/profile"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }

              return <ProfilePage {...props} user={user} />;
            }}
          />

          <ProtectedRoute
            component={UserProfilePage}
            path="/home"
            user={user}
          />

          <Route
            path="/v/:id"
            render={(props) => {
              if (!user) {
                return <PublicViewPage {...props} />;
              }
              return <PublicViewPage {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/:id"
            render={(props) => {
              return <PublicHome {...props} user={user} />;
            }}
          /> */}

          <Redirect from="/" exact to="/welcome" />
          <Route
            path="*"
            render={(props) => {
              if (!user) {
                return <PageNotFound />;
              }
              return <PageNotFound {...props} user={user} />;
            }}
          />
          <Route
            path="/404"
            render={(props) => {
              if (!user) {
                return <PageNotFound />;
              }
              return <PageNotFound {...props} user={user} />;
            }}
          />
        </Switch>
        <div className="clearfix my-4">&nbsp;</div>
      </React.Fragment>
    );
  }
}

export default App;
