import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ShareIcon from "@material-ui/icons/Share";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

function ShareOrCopyLink({
  link = "https://scoopbite.com",
  text,
  title,
  item,
}) {
  const url = link;
  const [showcopymodal, setShowCopyModal] = useState(false);

  const [texttocopy, setTextToCopy] = useState(url);

  const shareDetails = { url, title, text };

  const handleClose = () => {
    setShowCopyModal(false);
  };
  const handleCopy = () => {
    // const logname = `link_copy_${source}`;
    // logEvent(logname, {
    //   text: `link copy ${source}`,
    // });

    setShowCopyModal(true);
  };

  if (!navigator.share) {
    //show copy only
    return (
      <div>
        <CopyToClipboard text={texttocopy} onCopy={() => handleCopy()}>
          <Button variant={`text-dark`} className="float-right btn-sm py-2">
            <div className="float-left">
              <FileCopyOutlinedIcon
                className="text-primary"
                style={{
                  fontSize: "18px",
                  marginTop: "-2px",
                }}
              />
            </div>
            <div className="float-left">Copy</div>
          </Button>
        </CopyToClipboard>
        <Modal
          show={showcopymodal}
          onHide={handleClose}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Header style={{ border: "none" }}>
            <Modal.Title className="text-dark font-weight-bold">
              Link Copied
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-0 mb-4 text-center">
            <CheckCircleIcon
              className="text-success"
              style={{ fontSize: "50px" }}
            />
            <br />
            <br />
            You may now share www.scoopbite.com with your friends 🚀
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant="link"
              className="forcenoline noline mr-2"
              onClick={handleClose}>
              <span className="text-muted forcenoline noline">Got it</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };
  return (
    <Button
      variant="link"
      className="float-right btn-sm text-dark px-3 py-2"
      onClick={handleSharing}>
      <div className={`float-left `} style={{ width: "25px" }}>
        <ShareIcon
          className="text-primary"
          style={{
            fontSize: "18px",
            marginTop: "-2px",
          }}
        />
      </div>{" "}
      Share
      {/* <div className={`float-left defaultstandardfontsize`}>Share</div> */}
    </Button>
  );
}

export default ShareOrCopyLink;
