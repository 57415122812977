import React from "react";
import Header from "../../common/header";
import Footer from "../../common/footer";

function DeleteRequest() {
  return (
    <React.Fragment>
      <Header hide="profile" />
      <div className="p-4">
        <h1>Account Delete Request for ThankyNote</h1>
        <p>Thank you for using ThankyNote 🙏</p>
        <p>We are sorry to know that you have reached this page. 😔</p>
        <p>
          If you wish to delete your ThankyNote account, please contact us @
          jeynergil@gmail.com.
        </p>
        <p>
          It would mean a lot for us if you could also message us how we can
          improve ThankyNote.
        </p>
        <p>
          Please note that after we have deleted your account, we cannot undo
          it.
        </p>
        <p>
          Once we have deleted your account, we will send you an email 📧
          confirmation.
        </p>
        <p>Thank you once again for using ThankyNote!</p>
        <h1>Deleting Your Entries of ThankyNote</h1>
        <p>
          You can manually delete your entries by clicking/tapping on the Delete
          Icon on each entry.
        </p>
        <p>
          Please note that after you have deleted your entry, you cannot undo
          it.
        </p>
      </div>

      <div className="my-4 py-4">
        <hr />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default DeleteRequest;
