import React from "react";
import { Button, Modal } from "react-bootstrap";
import ColorOptions from "../../common/colorOptions";

function BackgroundSelectModal({
  state,
  onHide,
  onCancel,
  onSelectColor,
  doUpdateBackground,
}) {
  return (
    <div>
      <Modal
        show={state.showbackground}
        onHide={onHide}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title className="text-dark font-weight-bold">
            Select Background
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="font-weight-bold mt-0 mb-4 text-left">
          <ColorOptions
            selected={state.background}
            handler={(o) => onSelectColor(o)}
          />
        </Modal.Body>
        <div className="d-flex justify-content-end px-4 pb-4 ">
          <div>
            <Button
              disabled={state.updatingbackground}
              variant="link"
              className="forcenoline noline mr-2"
              onClick={onCancel}>
              <span className="text-dark forcenoline noline">Cancel</span>
            </Button>
            {state.updatingbackground !== true && (
              <Button variant="success" onClick={() => doUpdateBackground()}>
                Update
              </Button>
            )}
            {state.updatingbackground === true && (
              <Button disabled variant="success">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>{" "}
                Updating...
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BackgroundSelectModal;
