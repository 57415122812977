import React, { useState } from "react";
import { Link, PDFDownloadLink } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { getRandomInstruction } from "../../../utils/instruct";
import {
  getBigImage,
  getLinkedInImage,
  getSmallImage,
} from "../../../utils/staticimages";
const fixImg = getSmallImage();
const fixBigImg = getBigImage();
const fixName = "Jeyner Gil Caga";
const fixLinkedInImg = getLinkedInImage();

const styles = StyleSheet.create({
  spacing: {
    marginBottom: 8,
  },
  verticalSpacing: {
    marginBottom: 8,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    // fontFamily: "Open Sans",
    backgroundColor: "#000000",
  },
  name: {
    fontSize: 16,
    textAlign: "left",
    paddingBottom: 3,
    // borderBottom: "1px solid #d0d0d0",
    // marginBottom: 5,
  },
  apply: {
    fontSize: 12,
    textAlign: "left",
    paddingTop: 3,
    color: "#ffffff",
    // fontFamily: "Open Sans",
  },
  follow: {
    fontSize: 10,
    textAlign: "left",
    paddingTop: 3,
    color: "#ffffff",
    // fontFamily: "Open Sans",
  },
  userhandle: {
    fontSize: 14,
    textAlign: "left",
    marginTop: 4,
  },
  bio: {
    fontSize: 10,
    textAlign: "left",
    color: "#ffffff",
    lineHeight: 1.3,
    // marginTop: 4,
  },
  country: {
    fontSize: 14,
    textAlign: "left",
    marginTop: 4,
  },
  heading: {
    fontSize: 18,
    textAlign: "left",
    marginTop: 15,
    // marginBottom: 15,
    color: "#ffffff",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  instruction: {
    fontSize: 24,
    textAlign: "center",
    marginTop: 10,
    marginBottom: 15,
    color: "#ffffff",
    fontWeight: "bold",
    lineHeight: 1.5,
  },
  maintext: {
    fontSize: 14,
    textAlign: "left",
    marginTop: 20,
    marginBottom: 5,
    lineHeight: 1.65,
    color: "#ffffff",
  },
  summary: {
    fontSize: 13,
    textAlign: "left",
    marginTop: 20,
    marginBottom: 5,
    lineHeight: 1.65,
    color: "#ffffff",
  },
  title: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "left",
    marginTop: 5,
  },
  sub: {
    fontSize: 8,
    textAlign: "left",
    color: "#6a6a6a",
    marginTop: 2,
  },
  subgray: {
    fontSize: 8,
    color: "#6a6a6a",
    textAlign: "left",
    marginTop: 2,
  },
  description: {
    fontSize: 8,
    textAlign: "left",
    color: "#404040",
    marginBottom: 5,
    marginTop: 5,
  },
  objective: {
    fontSize: 10,
    textAlign: "left",
    color: "#101010",
    padding: 10,
    lineHeight: 1.5,
  },
  profile: {
    //css with d-flex and justify content start
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "row",
    // paddingRight: "20px",
    width: "100%",
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 0,
    width: 95,
    height: 95,
    // marginRight: 40,
  },
  linkedinimage: {
    marginVertical: 0,
    marginHorizontal: 0,
    // width: 14,
    // height: 14,
  },
  bigimage: {
    // marginTop: 10,
    marginVertical: 0,
    marginHorizontal: 0,
    width: "100%",
    height: "100%",
    // marginRight: 40,
  },
  circleimage: {
    marginVertical: 0,
    marginHorizontal: 0,
    width: 22,
    height: 22,
    // marginRight: 40,
    borderRadius: "50%",
    objectFit: "cover",
  },
  bigcircleimage: {
    marginVertical: 0,
    marginHorizontal: 0,
    width: 80,
    height: 80,
    // marginRight: 40,
    borderRadius: "50%",
    objectFit: "cover",
  },
  roundedBorderAll: {
    border: "solid 1px #d0d0d0",
    borderRadius: 4,
  },
  roundAll: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  roundTopLeft: {
    borderTopLeftRadius: 4,
  },
  roundTopRight: {
    borderTopRightRadius: 4,
  },
  roundTop: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  roundBottom: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  roundBottomLeft: {
    borderBottomLeftRadius: 4,
  },
  roundBottomRight: {
    borderBottomRightRadius: 4,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  hrule: {
    borderBottom: "1px solid #d0d0d0",
    marginTop: 10,
    marginBottom: 4,
  },
  table: {
    fontSize: 8,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    textAlign: "left",
  },
  tableRow: {
    fontSize: 8,
    margin: "auto",
    flexDirection: "row",
    borderColor: "#ffffff",
    textAlign: "left",
  },
  tableFull: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#d0d0d0",
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableAuto: {
    width: "80%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#d0d0d0",
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableCol: {
    fontSize: 8,
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#d0d0d0",
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableColFilled: {
    fontSize: 8,
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#d0d0d0",
    borderTopWidth: 0,
    textAlign: "left",
    backgroundColor: "#eaf3ff",
  },
  shippingAgency: {
    width: "16%",
  },
  vessel: {
    width: "14%",
  },
  type: {
    width: "8%",
  },
  grt: {
    width: "7%",
  },
  kw: {
    width: "7%",
  },
  rank: {
    width: "14%",
  },
  signOn: {
    width: "13%",
  },
  signOff: {
    width: "13%",
  },
  total: {
    width: "8%",
  },
  school: {
    width: "35%",
  },
  degree: {
    width: "39%",
  },
  trainname: {
    width: "35%",
  },
  number: {
    width: "21%",
    wordBreak: "break-all",
  },
  place: {
    width: "18%",
  },
  column1: {
    width: "18%",
  },
  columnpad: {
    width: "2%",
  },
  column2: {
    width: "32%",
  },
  column20: {
    width: "20%",
  },
  column80: {
    width: "80%",
  },
  column10: {
    width: "10%",
  },
  column90: {
    width: "90%",
  },
  tableExpCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#d0d0d0",
    borderTopWidth: 0,
    textAlign: "left",
  },
  filled: {
    backgroundColor: "#eaf3ff",
  },
  tableCell: {
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 3,
    fontSize: 10,
    textAlign: "left",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#e0e0e0",
  },
});

function PrintablePDF({ user, data, json }) {
  // console.log(user);
  // console.log(data);
  // console.log(json);
  const [profUri, setProfUri] = useState(null);
  const [bigProfUri, setBigProfUri] = useState(null);
  const [imageUri, setImageUri] = useState(null);
  const [linkedInUri, setLinkedInUri] = useState(null);
  const [randValue, setRandValue] = useState(Math.random());
  // console.log(data);
  const options = {
    httpHeaders: { crossOrigin: "anonymous" },
  };

  // const chunkSubstr = (str, size) => {
  //   const numChunks = Math.ceil(str.length / size);
  //   const chunks = new Array(numChunks);

  //   for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
  //     chunks[i] = str.substr(o, size);
  //   }

  //   return chunks;
  // };

  // Font.registerHyphenationCallback((word) => {
  //   if (word.length > 12) {
  //     return chunkSubstr(word, 10);
  //   } else {
  //     return [word];
  //   }
  // });

  const breakName = (name) => {
    return [name];
  };

  const Name = ({ size = "default" }) => {
    const nameSize = size === "default" ? 12 : 16;
    const imageSize = size === "default" ? 14 : 18;
    return (
      <View
        style={[
          {
            width: "92%",
            // border: "1px solid #d0d0d0",
            paddingLeft: 5,
            paddingBottom: 5,
            // paddingTop: 5,
          },
        ]}>
        <View
          style={[
            {
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
            },
          ]}>
          <View>
            <Link
              style={{
                textDecoration: "none",
                color: "#ffffff",
                // paddingBottom: 2,
                // borderBottom: "1px dashed #ffffff",
              }}
              src={`https://www.linkedin.com/in/jeynergilcaga`}>
              <Text style={[styles.apply, { fontSize: nameSize }]}>
                {fixName}
              </Text>
            </Link>
          </View>
          <View>
            <Image
              style={[
                styles.linkedinimage,
                {
                  width: imageSize,
                  height: imageSize,
                  marginLeft: 5,
                  marginTop: 4,
                },
              ]}
              options={options}
              src={{
                uri: linkedInUri,
                method: "GET",
                headers: {
                  Pragma: "no-cache",
                  "Cache-Control": "no-cache",
                },
                body: "",
              }}
            />
            {/* <Text style={styles.bio}>/jeynergilcaga</Text> */}
          </View>
        </View>
      </View>
    );
  };

  const ProfileSection = ({ isBreak }) => {
    return (
      <View style={[styles.table, styles.profile]} break={isBreak}>
        <View style={[styles.tableRow]}>
          <View style={[styles.column10, { width: "10%" }]}>
            {fixImg && fixImg !== "" && (
              <Image
                style={[styles.circleimage]}
                options={options}
                src={{
                  uri: profUri,
                  method: "GET",
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                  body: "",
                }}
              />
            )}
          </View>
          <Name />
          {/* <View
            style={[
              {
                width: "92%",
                // border: "1px solid #d0d0d0",
                paddingLeft: 5,
                paddingBottom: 5,
                // paddingTop: 5,
              },
            ]}>
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "start",
                },
              ]}>
              <View>
                <Text style={styles.apply}>{fixName}</Text>
              </View>
              <View>
                <Image
                  style={[
                    styles.linkedinimage,
                    { marginLeft: 5, marginTop: 4 },
                  ]}
                  options={options}
                  src={{
                    uri: linkedInUri,
                    method: "GET",
                    headers: {
                      Pragma: "no-cache",
                      "Cache-Control": "no-cache",
                    },
                    body: "",
                  }}
                />
               
              </View>
            </View>
          </View> */}
        </View>
      </View>
    );
  };
  // Create Document Component
  // See: https://github.com/diegomura/react-pdf/blob/master/packages/layout/src/page/getSize.js
  const PDFType = () => (
    <Document>
      <Page wrap size="A6" style={styles.body}>
        <ProfileSection isBreak={false} />
        <View style={[styles.heading, { marginBottom: 15 }]}>
          <Text
            hyphenationCallback={(e) => breakName(e)}
            style={[styles.heading]}>
            {data?.header?.trim()}
          </Text>
          {data?.subheader && data?.type?.trim() === "book" && (
            <Text
              hyphenationCallback={(e) => breakName(e)}
              style={[styles.bio, { marginTop: 10 }]}>
              {data?.subheader?.trim()}
            </Text>
          )}
        </View>

        <View>
          {data?.knowledgebigpic && data?.knowledgebigpic !== "" && (
            <Image
              style={[styles.bigimage, styles.roundAll]}
              options={options}
              src={{
                uri: imageUri,
                method: "GET",
                headers: {
                  Pragma: "no-cache",
                  "Cache-Control": "no-cache",
                },
                body: "",
              }}
            />
          )}
        </View>
        <ProfileSection isBreak={false} />
        <View style={[styles.heading, { marginTop: 10 }]}>
          <Text
            hyphenationCallback={(e) => breakName(e)}
            style={[styles.heading]}>
            About
          </Text>
          {data?.description && (
            <Text
              hyphenationCallback={(e) => breakName(e)}
              style={[styles.summary, { marginTop: 30, color: "#FDFDFE" }]}>
              {data?.description?.trim()}
            </Text>
          )}
        </View>
        {json?.timeline?.length > 0 &&
          json?.timeline?.map((i, index) => {
            // console.log(i);
            return (
              <View wrap={false} key={index}>
                <ProfileSection isBreak={true} />

                <View wrap={false}>
                  <Text
                    hyphenationCallback={(e) => breakName(e)}
                    style={styles.heading}>
                    {i?.heading?.trim()}
                  </Text>
                  {i?.identifier && (
                    <Text
                      hyphenationCallback={(e) => breakName(e)}
                      style={[styles.bio, { marginTop: 5 }]}>
                      {i?.identifier?.toString().trim()}
                    </Text>
                  )}
                  <Text
                    hyphenationCallback={(e) => breakName(e)}
                    style={[styles.maintext, { color: "#FDFDFE" }]}>
                    {i?.description?.trim()}
                  </Text>
                </View>
              </View>
            );
          })}

        <View style={[styles.table, { textAlign: "center" }]} break>
          <View style={[styles.instruction]}>
            <Text hyphenationCallback={(e) => breakName(e)}>
              {getRandomInstruction(randValue)}
            </Text>
          </View>
          {fixBigImg && fixBigImg !== "" && (
            <Image
              style={[
                styles.bigcircleimage,
                { textAlign: "center", margin: "auto" },
              ]}
              options={options}
              src={{
                uri: bigProfUri,
                method: "GET",
                headers: {
                  Pragma: "no-cache",
                  "Cache-Control": "no-cache",
                },
                body: "",
              }}
            />
          )}
          <View style={[styles.instruction, { marginTop: 20 }]}>
            <Text>Follow me.</Text>
          </View>
          <View style={{ margin: "auto" }}>
            <Name size="big" />
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  // https://stackoverflow.com/questions/6150289/how-to-convert-image-into-base64-string-using-javascript
  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  toDataURL(fixImg).then((dataUrl) => {
    // console.log(dataUrl);
    setProfUri(dataUrl);
  });
  toDataURL(fixBigImg).then((dataUrl) => {
    // console.log(dataUrl);
    setBigProfUri(dataUrl);
  });
  toDataURL(fixLinkedInImg).then((dataUrl) => {
    // console.log(dataUrl);
    setLinkedInUri(dataUrl);
  });

  toDataURL(data?.knowledgebigpic).then((dataUrl) => {
    // console.log(dataUrl);
    setImageUri(dataUrl);
  });
  toDataURL(data?.knowledgebigpic).then((dataUrl) => {
    // console.log(dataUrl);
    setImageUri(dataUrl);
  });
  toDataURL(data?.knowledgebigpic).then((dataUrl) => {
    // console.log(dataUrl);
    setImageUri(dataUrl);
  });

  const handleClick = () => {
    // fire.analytics().logEvent("cv_download", { email: data?.email });
    // console.log("CV Download Clicked by: ", data?.email);
    setRandValue(Math.random());
    console.log("Download PDF");
  };

  // console.log(data?.bigpic);
  return profUri ? (
    <PDFDownloadLink
      className="btn btn-sm btn-primary roundcornernocolorbutton text-center d-flex justify-content-center"
      document={<PDFType />}
      fileName={`${data.header}.pdf`}>
      {({ blob, url, loading, error }) =>
        loading ? (
          <div>Download PDF</div>
        ) : (
          <div onClick={handleClick}>Download PDF</div>
        )
      }
    </PDFDownloadLink>
  ) : null;
  // return (
  //   <PDFViewer>
  //     <PDFCV />
  //   </PDFViewer>
  // );
}

export default PrintablePDF;
