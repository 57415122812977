import { getMassDateTime } from "../utils/utils";
import fire from "./fire";

export async function logEvent(event, data) {
  fire.analytics().logEvent(event, data);
}

export async function logMessage(where, data) {
  const logMessage = fire.functions("asia-east2").httpsCallable("logMessage");
  console.error(
    `logMessage Source: ${where} Email: ${data.email} Code: ${data.code} Message: ${data.message}`
  );
  let date = new Date(); //.toDateString()
  logMessage({
    email: data.email,
    code: data.code,
    message: data.message,
    where: where,
    timestamp: `${date.toDateString()} ${getMassDateTime(date)}`,
  });
}
