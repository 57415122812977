import React from "react";

const Loader = () => {
  return (
    <React.Fragment>
      <span
        className="spinner-border text-primary spinner-border-lg"
        role="status"
        aria-hidden="true"
      ></span>
    </React.Fragment>
  );
};

export default Loader;
