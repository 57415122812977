import React from "react";
import TitleCreator from "./titleCreator";
import { convertFireStorageUrl } from "../../../utils/url";

function HeadingSectionPhoto({
  type,
  subtitle,
  json,
  content,
  owner,
  smallpic,
  photoUrl,
}) {
  return (
    <div className="appearslowly container px-0">
      <div className="row justify-content-center mx-2">
        <div className="col-lg-4 px-0">
          <div className="d-xs-block d-sm-block d-md-block d-lg-none mb-4">
            <TitleCreator
              type={type}
              subtitle={subtitle}
              title={json.title}
              content={content}
              owner={owner}
              smallpic={smallpic}
            />
          </div>

          {photoUrl && (
            <img
              className="responsive img-fluid"
              style={{
                borderRadius: "8px",
                boxShadow: "1px 1px 10px -4px #888888",
              }}
              src={convertFireStorageUrl(photoUrl)}
              alt="Sample Image"
            />
          )}
        </div>
        <div className="col-lg-8 pl-4 d-none d-lg-block">
          <TitleCreator
            type={type}
            subtitle={subtitle}
            title={json.title}
            content={content}
            owner={owner}
            smallpic={smallpic}
          />

          <p className="pt-4 text-left">{json.description}</p>
        </div>
        <div className="d-xs-block d-sm-block d-md-block d-lg-none">
          <p className="pt-4 text-left">{json.description}</p>
        </div>
      </div>
    </div>
  );
}

export default HeadingSectionPhoto;
