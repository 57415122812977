import React from "react";
import { Button } from "react-bootstrap";
import ShareIcon from "@material-ui/icons/Share";

function ShareNote({ text, title, item }) {
  const url = `https://thankynote.com/p/${item.id}`;
  const shareDetails = { url, title, text };

  if (!navigator.share) {
    return null;
  }
  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };
  return (
    <Button
      variant="link"
      className="float-right btn-sm text-muted px-3 py-2"
      onClick={handleSharing}
    >
      <div className={`float-left `} style={{ width: "25px" }}>
        <ShareIcon
          className="text-primary"
          style={{
            fontSize: "18px",
            marginTop: "-2px",
          }}
        />
      </div>
      {/* <div className={`float-left defaultstandardfontsize`}>Share</div> */}
    </Button>
  );
}

export default ShareNote;
