import React, { Component } from "react";
import LoadingSpinner from "../../common/loadingSpinner";
import { getCurrentUserData, userLogout } from "../../../services/user";
import UserDetailsEdit from "../component/userDetailsEdit";
import Header from "../../common/header";
import { enableEditBasicProfile } from "../../../services/settings";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";

class AccountEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      data: {
        fullname: "",
        email: "",
        bio: "",
        country: "",
        address: "",
        phonenumber: "",
        dob: "",
        civilstatus: "",
        height: "",
        weight: "",
      },
      errors: {},
      parish: [],
      loading: true,
    };
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        userid,
        created,
        fullname,
        email,
        height,
        weight,
        civilstatus,
        dob,
        exp,
        edu,
        bio,
        address,
        country,
        currentcompanydesc,
        currentcompany,
        phonenumber,
        degree,
      } = user;

      this.setState({
        data: {
          userid,
          created,
          fullname,
          email,
          exp,
          edu,
          bio,
          height,
          weight,
          civilstatus,
          dob,
          phonenumber,
          currentcompanydesc,
          currentcompany,
          country,
          address,
          degree,
        },
        loading: false,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  componentDidMount() {
    if (enableEditBasicProfile()) {
      this.getCurrentUserData();
      window.scrollTo(0, 0);
    } else {
      this.props.history.push("/profile");
    }
  }

  render() {
    const { data, loading } = this.state;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backandlogo"
          hidehome
          hidefriends
          hide="profile"
        />
      );
    }
    return (
      <React.Fragment>
        <Header type="backandlogo" hidehome hidefriends hide="profile" />

        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-3">
                <UserDetailsEdit userdetails={data} />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountEdit;
