import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { withRouter } from "react-router-dom";
import { userLogout } from "../../../services/user";
import { logEvent } from "../../../services/log";
import LinkButton from "../../blocks/linkButtonBlock";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { isValidFullname } from "../../../services/validation";
import { Button, Col, Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DatePickerWithHeader from "../../common/datePickerWithHeader";
import { normaliseDate } from "../../../utils/date";

class UserDetailsEdit extends Form {
  constructor(props) {
    super(props);
    const {
      fullname,
      country,
      address,
      bio,
      phonenumber,
      height,
      weight,
      civilstatus,
      dob,
    } = this.props.userdetails;
    console.log(dob);
    let isvalidfullname = isValidFullname(fullname);
    let errors = isvalidfullname
      ? {}
      : { fullname: "Valid Full Name only. No email please." };
    this.state = {
      isvalidfullname,
      countries: countryList()
        .getData()
        .map((item) => {
          return { _id: item.value, name: item.label };
        }),
      data: {
        fullname: fullname || "",
        country: country || "",
        bio: bio || "",
        // address: address || "",
        // phonenumber: phonenumber || "",
        // // dob: dob,
        // civilstatus: civilstatus || "",
        // height: height || "",
        // weight: weight || "",
      },
      fullname,
      errors: errors,
      dob: dob || normaliseDate(new Date()),
      loading: false,
      profilesave: false,
    };
  }

  schema = {
    fullname: Joi.string().trim().required().label("Full Name"),
    country: Joi.string().trim().required().label("Country"),
    bio: Joi.string().trim().required().label("Headline"),
    // address: Joi.string().trim().required().label("Address"),
    // phonenumber: Joi.string().trim().required().label("Phone Number"),
    // civilstatus: Joi.string().trim().required().label("Civil Status"),
    // height: Joi.string().trim().required().label("Height"),
    // weight: Joi.string().trim().required().label("Weight"),
    // // dob: Joi.string().trim().required().label("Date of Birth"),
    // dob: Joi.string().allow("").optional(),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  doSubmit = async () => {
    const { email } = this.props.userdetails;
    const { data, dob } = this.state;
    console.log(dob);
    let err = {};

    this.setState({
      errors: err,
      loading: true,
    });

    try {
      const currentUser = fire.auth().currentUser;

      await fire.firestore().doc(`users/${currentUser.uid}`).update({
        fullname: data.fullname.trim(),
        country: data.country.trim(),
        bio: data.bio.trim(),
        // phonenumber: data?.phonenumber?.trim(),
        // address: data?.address?.trim(),
        // height: data?.height?.trim(),
        // weight: data?.weight?.trim(),
        // civilstatus: data?.civilstatus?.trim(),
        // dob: dob.toString(),
      });
      await currentUser.updateProfile({
        displayName: data.fullname.trim(),
        country: data.country.trim(),
      });
      //set state to refresh page
      this.setState({ profilesave: true });
    } catch (ex) {
      logEvent("user_account_update_fail", {
        email: email,
        message: ex.message,
      });
      console.error("Unable to Set Data");
    }
  };

  forceLogout = async () => {
    try {
      await userLogout();
      // signed out
      window.location.reload();
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  handleDobChange = (value) => {
    const updatedValue = normaliseDate(value);
    console.log(updatedValue);
    this.setState({
      dob: updatedValue,
      // data: { ...this.data, dob: updatedValue },
    });
    // this.setState({ sdate: updatedValue, edate: updatedValue });
  };

  render() {
    const { loading, profilesave, countries } = this.state;
    const status = [
      { _id: "Single", name: "Single" },
      { _id: "Married", name: "Married" },
      { _id: "Widowed", name: "Widowed" },
      { _id: "Separated", name: "Separated" },
    ];
    return (
      <div>
        {!profilesave && (
          <React.Fragment>
            <h3 className="py-3">Basic Info 🗂️</h3>

            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "fullname",
                "Your Name",
                "text",
                "Type your name"
              )}
              {/* <Row>
                <Col xs={6} className="pr-1">
                  <div>
                    <label htmlFor="dob">
                      Date of Birth&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <DatePickerWithHeader
                      endYear={-17}
                      // disabled={state.currentlyworking === true}
                      name="dob"
                      selected={
                        this.state?.dob
                          ? normaliseDate(new Date(this.state?.dob))
                          : normaliseDate(new Date())
                      }
                      onChange={this.handleDobChange}
                      isErr={this.state.errors["dob"]}

                     
                    />
                  </div>
                  {this.state.errors["dob"] && (
                    <p className="standardfontsize font-weight-normal text-danger">
                      *{this.state.errors["dob"]}
                    </p>
                  )}
                </Col>
                <Col xs={6}>
                  {this.renderSelect("civilstatus", "Civil Status", status)}
                </Col>
              </Row> */}

              {/* <Row>
                <Col xs={6} className="pr-1">
                  {this.renderInput(
                    "height",
                    "Height (cm)",
                    "text",
                    "ex. 163 cm"
                  )}
                </Col>
                <Col xs={6}>
                  {this.renderInput(
                    "weight",
                    "Weight (kg)",
                    "text",
                    "ex. 60 kg"
                  )}
                </Col>
              </Row>

              {this.renderInput(
                "phonenumber",
                "Phone Number",
                "text",
                "ex. +63 912 345 6789"
              )}
              {this.renderInput(
                "address",
                "Address",
                "text",
                "ex. Pooc Occidental, Tubigon, Bohol"
              )} */}
              {this.renderSelect("country", "Country", countries)}
              {this.renderInput(
                "bio",
                "Position Applied",
                "text",
                "ex. Third Officer"
              )}
              {/* <h3 className="py-3">Experience 💼</h3>

              {this.renderInput(
                "currentpos",
                "Title",
                "text",
                "ex. Senior Staff Nurse"
              )}
              {this.renderInput(
                "currentcompany",
                "Company Name",
                "text",
                "ex. Gleneagles Hospital, Singapore"
              )}
              {this.renderInput(
                "currentcompanydesc",
                "Job Description",
                "text",
                "Brief Job Description"
              )}
              <h3 className="py-3">Education 🎓</h3>

              {this.renderInput(
                "school",
                "School",
                "text",
                "ex. Holy Name University, Bohol, Philippines"
              )}
              {this.renderInput(
                "degree",
                "Degree or Field or Course",
                "text",
                "ex. BS in Nursing, 2020"
              )} */}
              <div className="py-4"></div>
              {!loading && this.renderBlockButton("Save")}
              {loading && this.renderLoadingBlockButton("Saving...")}
            </form>
            <Button
              variant="link"
              onClick={() => this.props.history.goBack()}
              className="btn-block btn-lg my-4">
              Cancel
            </Button>
          </React.Fragment>
        )}

        {profilesave && (
          <React.Fragment>
            <div className="text-center">
              <CheckCircleIcon style={{ fontSize: "100px", color: "green" }} />
              <h3 className="my-4">Awesome!</h3>

              {/* <LinkButton link="/profile" label="View Profile" /> */}
              <LinkButton link="/home" label="Visit Home" />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(UserDetailsEdit);
