import React, { Component } from "react";
import Header from "../../common/header";
import Footer from "../../common/footer";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import BottomBar from "../../common/bottomBar";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import fire from "../../../services/fire";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import ReactCountryFlag from "react-country-flag";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import countryList from "react-select-country-list";
// import CVBlock from "../../common/cvBlock";
import { getLatestVersion } from "../../../services/getversion";
import ReloadBar from "../../common/reloadBar";
import CVBlockV1 from "../../common/cvBlockV1";
import PrintableCV from "../../common/printableCV";

class PublicHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "ready",
      user: this.props.user,
      loading: true,
      id: this.props.match.params.id,
    };
  }

  async componentDidMount() {
    const id = this.state.id;
    //get the uid
    try {
      const db = fire.firestore();

      const doc = await db
        .collection("users")
        .where("userhandle", "==", id)
        .get();

      let bigpic,
        smallpic,
        fullname,
        userhandle,
        email = "",
        userid,
        school,
        degree,
        bio,
        country,
        exp,
        edu,
        train,
        currentpos,
        verified,
        currentcompanydesc,
        background,
        currentcompany;
      doc.forEach((item) => {
        let data = item.data();
        userhandle = data.userhandle;
        email = data.email;
        exp = data.exp;
        edu = data.edu;
        school = data.school;
        fullname = data.fullname;
        train = data?.train || [];
        degree = data.degree;
        currentcompany = data.currentcompany;
        currentcompanydesc = data.currentcompanydesc;
        bigpic = data.bigpic;
        smallpic = data.smallpic;
        bio = data.bio;
        currentpos = data.currentpos;
        country = data.country;
        userid = data.userid;
        verified = data?.verified || false;
        background = data?.background || 0;
      });
      if (email === "") {
        this.props.history.push("/home");
        return;
      }
      const countries = countryList().getData();

      let countryStr = "";
      if (countries && country !== "") {
        //get country str
        for (let i = 0; i < countries.length; i++) {
          const item = countries[i];
          if (item.value === country) {
            countryStr = item.label;
            break;
          }
        }
      }
      let version = await getLatestVersion();

      this.setState({
        version,
        inputdata: {
          background: background || 0,
          bigpic,
          smallpic,
          verified: verified || "",
          countryStr,
          fullname,
          currentpos,
          userhandle,
          userid,
          email,
          school,
          exp,
          edu,
          train,
          degree,
          bio,
          country,
          currentcompanydesc,
          currentcompany,
        },
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
    window.scrollTo(0, 0);
  }

  handleButtonClick = () => {
    const { inputdata } = this.state;
    window.open(`https://m.me/${inputdata.storecontact}`, "_blank");
  };

  displayImages = (list) => {
    let style = "col-4 text-center p-1  zoom pointer";
    if (list.length === 1) {
      style = "col-lg-8 text-center py-3 mx-auto zoom pointer";
    } else {
      if (list.length === 2) {
        style = "col-6 text-center py-3 zoom pointer";
      }
    }
    return list.map((item) => (
      <div key={item.timestamp} className={`${style}`}>
        <Link to={`/v/${item.id}`}>
          <img alt="" className="responsive" src={item.image} />
        </Link>
      </div>
    ));
  };

  render() {
    const { loading, user, version } = this.state;
    const hide = "profile";
    let hidehome = true;
    if (user) {
      hidehome = false;
    }
    if (loading === true) {
      return <LoadingSpinnerNav hidehome={true} hide={hide} />;
    }
    return (
      <React.Fragment>
        <Header
          hide={hide}
          hideinvite={true}
          hidehome={!user}
          showcreate={true}
          user={user}
          shortcode={this.props.match.params.id}
        />
        <ReloadBar version={version} />
        {/* <div className="row justify-content-center mx-0">
          <div className="col-lg-8 mx-0 px-0"> */}
        <main className="">
          <CVBlockV1
            user={this.state.user}
            state={this.state.state}
            inputdata={this.state.inputdata}
          />
        </main>
        <Footer />
        {/* </div>
        </div> */}
        {/* <div className="row justify-content-center mx-0">
          <div className="col-lg-8 mx-0 px-0">
            <main className="container-fluid text-center mb-4 py-4">
       

              <CVBlockV
                user={this.state.user}
                state={this.state.state}
                inputdata={this.state.inputdata}
              />
            </main>
            <Footer />
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

export default PublicHome;
