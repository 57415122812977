import React from "react";
import Linkify from "react-linkify";
import Typist from "react-typist";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { convertFireStorageUrl } from "../../../utils/url";

const ViewContentBody = ({ ...rest }) => {
  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} rel="noopener noreferrer" target="_blank">
      {text}
    </a>
  );

  const { item, animate, onTypingDoneHandler } = rest;
  let extra = () => {};
  if (onTypingDoneHandler) {
    extra = () => onTypingDoneHandler();
  }
  return (
    <div className="pt-2 pb-0">
      {!item.bigphoto && (
        <h3 className="px-4 pt-0 pb-0 mb-4 text-left text-black">
          {!animate && (
            <Linkify componentDecorator={componentDecorator}>
              {item.content}
            </Linkify>
          )}
          {animate && (
            <Typist cursor={{ show: false }} onTypingDone={extra}>
              {item.content.split("\n").map((str, id) => (
                <span key={id}>
                  {str}
                  <br />
                </span>
              ))}
              {/* {item.content} */}
            </Typist>
          )}
        </h3>
      )}
      {item.bigphoto && (
        <div className="px-4">
          {animate && (
            <Typist onTypingDone={extra}>
              <span className="font-weight-bold">{item.content}</span>
            </Typist>
          )}
          <div className="mt-4">
            <Zoom>
              <img
                src={convertFireStorageUrl(item.bigphoto)}
                alt=""
                width="100%"
                className="lightframecolor roundcornernocolor mb-4"
              />
            </Zoom>
          </div>
        </div>
      )}
      {/* <div className="text-right pr-2">
        <FormatQuoteIcon
          className="px-0 text-right"
          style={{ fontSize: "80px", color: "#efefef" }}
        />
      </div> */}
    </div>
  );
};

export default ViewContentBody;
