import React from "react";

const Input = ({
  name,
  label,
  error,
  showsuccess,
  labelextra = null,
  ...rest
}) => {
  let formClass = "form-control form-control-lg ";
  if (error) {
    formClass = `${formClass} is-invalid`;
  }
  if (showsuccess) {
    formClass = `${formClass} is-valid`;
  }
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name}>
          <span className="">
            {label}
            {labelextra}
          </span>
        </label>
      )}

      <input
        {...rest}
        name={name}
        id={name}
        className={formClass}
        autoComplete="none"
      />
      {error && (
        <small style={{ marginTop: "5px" }} className="text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default Input;
