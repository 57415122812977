import React from "react";
import { Button, Modal } from "react-bootstrap";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function LinkCopiedModal({ state, onHide }) {
  return (
    <div>
      <Modal
        show={state.showcopymodal}
        onHide={onHide}
        animation={false}
        centered
        backdrop="static"
        keyboard={true}>
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title className="text-dark font-weight-bold">
            Link Copied
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-0 mb-4 text-center">
          <CheckCircleIcon
            className="text-success"
            style={{ fontSize: "50px" }}
          />
          <br />
          <br />
          You may now share this link with your friends
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button
            variant="link"
            className="forcenoline noline mr-2"
            onClick={onHide}>
            <span className="text-dark forcenoline noline">Got it</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LinkCopiedModal;
