import React, { useState } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import { dateDifference } from "../../utils/date";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
const styles = StyleSheet.create({
  spacing: {
    marginBottom: 8,
  },
  verticalSpacing: {
    marginBottom: 8,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    // fontFamily: "Open Sans",
  },
  name: {
    fontSize: 14,
    textAlign: "left",
    // fontFamily: "Open Sans",
  },
  userhandle: {
    fontSize: 16,
    textAlign: "left",
    marginTop: 4,
  },
  bio: {
    fontSize: 12,
    textAlign: "left",
    marginTop: 4,
  },
  country: {
    fontSize: 16,
    textAlign: "left",
    marginTop: 4,
  },
  heading: {
    fontSize: 14,
    textAlign: "left",
    marginTop: 20,
    marginBottom: 5,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    marginTop: 5,
  },
  sub: {
    fontSize: 11,
    textAlign: "left",
    color: "#6a6a6a",
    marginTop: 2,
  },
  subgray: {
    fontSize: 11,
    color: "#6a6a6a",
    textAlign: "left",
    marginTop: 2,
  },
  description: {
    fontSize: 12,
    textAlign: "left",
    color: "#404040",
    marginBottom: 5,
    marginTop: 5,
  },
  profile: {
    //css with d-flex and justify content start
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "row",
    paddingRight: "20px",
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 0,
    width: 100,
    height: 100,
    marginRight: 20,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  hrule: {
    borderBottom: "1px solid #d0d0d0",
    marginTop: 10,
    marginBottom: 4,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d0d0d0",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    textAlign: "left",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderColor: "#d0d0d0",
    textAlign: "left",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#d0d0d0",
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableColFilled: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#d0d0d0",
    borderTopWidth: 0,
    textAlign: "left",
    backgroundColor: "#eaf3ff",
  },
  shippingAgency: {
    width: "16%",
  },
  vessel: {
    width: "14%",
  },
  type: {
    width: "8%",
  },
  grt: {
    width: "7%",
  },
  kw: {
    width: "7%",
  },
  rank: {
    width: "14%",
  },
  signOn: {
    width: "13%",
  },
  signOff: {
    width: "13%",
  },
  total: {
    width: "8%",
  },
  school: {
    width: "38%",
  },
  degree: {
    width: "36%",
  },
  trainname: {
    width: "38%",
  },
  number: {
    width: "18%",
  },
  place: {
    width: "18%",
  },
  column1: {
    width: "18%",
  },
  column2: {
    width: "32%",
  },
  column3: {
    width: "20%",
  },
  tableExpCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: "#d0d0d0",
    borderTopWidth: 0,
    textAlign: "left",
  },
  filled: {
    backgroundColor: "#eaf3ff",
  },
  tableCell: {
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 3,
    fontSize: 10,
    textAlign: "left",
  },
});

function PrintableCV({ user, data }) {
  const [dataUri, setDataUri] = useState(null);
  // console.log(data);
  const options = {
    httpHeaders: { crossOrigin: "anonymous" },
  };

  // Create Document Component
  const PDFCV = () => (
    <Document>
      <Page style={styles.body}>
        <View style={styles.profile}>
          {data?.bigpic && data?.bigpic !== "" && (
            <Image
              style={styles.image}
              options={options}
              src={{
                uri: dataUri,
                method: "GET",
                headers: { Pragma: "no-cache", "Cache-Control": "no-cache" },
                body: "",
              }}
            />
          )}
          <View>
            <Text style={styles.name}>{data?.fullname}</Text>
            {/* <Text style={styles.userhandle}>
              <Link src={`https://jeyner.ai/u/${data?.userhandle}`}>
                www.jeyner.ai/u/{data?.userhandle}
              </Link>
            </Text> */}
            <Text style={styles.bio}>Applying for {data?.bio}</Text>
            <Text style={styles.bio}>{data?.email}</Text>
            <Text style={styles.bio}>{data?.phonenumber}</Text>
            <Text style={styles.bio}>{data?.address}</Text>
            <Text style={styles.bio}>{data?.countryStr}</Text>
          </View>
        </View>
        <Text style={styles.heading}>Personal Details</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.filled, styles.column1]}>
              <Text style={styles.tableCell}>Date of Birth</Text>
            </View>
            <View style={[styles.tableCol, styles.column2]}>
              {data?.dob && (
                <Text style={styles.tableCell}>
                  {new Date(data?.dob)?.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                  (
                  {Math.floor((new Date() - new Date(data?.dob)) / 31557600000)}{" "}
                  years old)
                </Text>
              )}
            </View>
            <View style={[styles.tableCol, styles.filled, styles.column1]}>
              <Text style={styles.tableCell}>Civil Status</Text>
            </View>
            <View style={[styles.tableCol, styles.column2]}>
              <Text style={styles.tableCell}>{data?.civilstatus}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.filled, styles.column1]}>
              <Text style={styles.tableCell}>Height</Text>
            </View>
            <View style={[styles.tableCol, styles.column2]}>
              <Text style={styles.tableCell}>{data?.height} cm</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.column1]}>
              <Text style={styles.tableCell}>Weight</Text>
            </View>
            <View style={[styles.tableCol, styles.column2]}>
              <Text style={styles.tableCell}>{data?.weight} kg</Text>
            </View>
          </View>
        </View>

        <Text style={styles.heading}>
          Sea-Based Experience ({data?.exp?.length})
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View
              style={[
                styles.tableExpCol,
                styles.filled,
                styles.shippingAgency,
              ]}>
              <Text style={styles.tableCell}>Shipping Agency</Text>
            </View>
            <View style={[styles.tableExpCol, styles.filled, styles.vessel]}>
              <Text style={styles.tableCell}>Vessel</Text>
            </View>
            <View style={[styles.tableExpCol, styles.filled, styles.type]}>
              <Text style={styles.tableCell}>Type</Text>
            </View>
            <View style={[styles.tableExpCol, styles.filled, styles.grt]}>
              <Text style={styles.tableCell}>GRT</Text>
            </View>
            <View style={[styles.tableExpCol, styles.filled, styles.kw]}>
              <Text style={styles.tableCell}>KW</Text>
            </View>
            <View style={[styles.tableExpCol, styles.filled, styles.rank]}>
              <Text style={styles.tableCell}>Rank</Text>
            </View>
            <View style={[styles.tableExpCol, styles.filled, styles.signOn]}>
              <Text style={styles.tableCell}>Sign On</Text>
            </View>
            <View style={[styles.tableExpCol, styles.filled, styles.signOff]}>
              <Text style={styles.tableCell}>Sign Off</Text>
            </View>
            <View style={[styles.tableExpCol, styles.filled, styles.total]}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
          </View>
          {data?.exp?.map((i, index) => {
            // const skills = i?.skills?.trim() || "";

            const sDate = i?.sdate
              ? new Date(i?.sdate).toDateString()
              : new Date().toDateString();
            const sDateStr = sDate.slice(4);
            const eDate = i?.edate
              ? new Date(i?.edate).toDateString()
              : new Date().toDateString();

            const eDateStr = i?.currentlyworking === false ? eDate : "Present";
            const timeframe = dateDifference(sDateStr, eDateStr);

            return (
              <View key={index} style={styles.tableRow}>
                <View style={[styles.tableExpCol, styles.shippingAgency]}>
                  <Text style={styles.tableCell}>{i?.company}</Text>
                </View>
                <View style={[styles.tableExpCol, styles.vessel]}>
                  <Text style={styles.tableCell}>{i?.vessel}</Text>
                </View>
                <View style={[styles.tableExpCol, styles.type]}>
                  <Text style={styles.tableCell}>{i?.type}</Text>
                </View>
                <View style={[styles.tableExpCol, styles.grt]}>
                  <Text style={styles.tableCell}>{i?.grt}</Text>
                </View>
                <View style={[styles.tableExpCol, styles.kw]}>
                  <Text style={styles.tableCell}>{i?.kw}</Text>
                </View>
                <View style={[styles.tableExpCol, styles.rank]}>
                  <Text style={styles.tableCell}>{i?.title}</Text>
                </View>
                <View style={[styles.tableExpCol, styles.signOn]}>
                  <Text style={styles.tableCell}>{sDateStr}</Text>
                </View>
                <View style={[styles.tableExpCol, styles.signOff]}>
                  <Text style={styles.tableCell}>
                    {eDateStr === "Present" ? "-" : eDateStr.slice(4)}
                  </Text>
                </View>
                <View style={[styles.tableExpCol, styles.total]}>
                  <Text style={styles.tableCell}>{timeframe}</Text>
                </View>
              </View>
            );
          })}
        </View>
        <Text style={styles.heading}>
          Educational Background ({data?.edu?.length})
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.filled, styles.school]}>
              <Text style={styles.tableCell}>School</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.degree]}>
              <Text style={styles.tableCell}>Degree/Course</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.signOn]}>
              <Text style={styles.tableCell}>Start</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.signOff]}>
              <Text style={styles.tableCell}>End</Text>
            </View>
          </View>
          {data?.edu?.length > 0 && (
            <View>
              {data?.edu?.map((i, index) => {
                return (
                  <View key={index} style={styles.tableRow}>
                    <View style={[styles.tableCol, styles.school]}>
                      <Text style={styles.tableCell}>{i.school}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.degree]}>
                      <Text style={styles.tableCell}>{i.degree}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.signOn]}>
                      <Text
                        style={styles.tableCell}>{`${i?.startmonth.substring(
                        0,
                        3
                      )} ${i?.startyear}`}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.signOff]}>
                      <Text style={styles.tableCell}>
                        {i?.endmonth.substring(0, 3)} {i?.endyear}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )}
        </View>
        <Text style={styles.heading}>
          Trainings/Certificates ({data?.train?.length})
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.filled, styles.trainname]}>
              <Text style={styles.tableCell}>Name</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.number]}>
              <Text style={styles.tableCell}>Number</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.place]}>
              <Text style={styles.tableCell}>Place of Issue</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.signOn]}>
              <Text style={styles.tableCell}>Issue Date</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.signOff]}>
              <Text style={styles.tableCell}>Expiry</Text>
            </View>
          </View>
          {data?.train?.length > 0 && (
            <View>
              {data?.train?.map((i, index) => {
                // const skills = i?.skills?.trim() || "";

                const sDate = i?.sdate
                  ? new Date(i?.sdate).toDateString()
                  : new Date().toDateString();
                const sDateStr = sDate.slice(4);
                const eDate = i?.edate
                  ? new Date(i?.edate).toDateString()
                  : new Date().toDateString();

                const eDateStr = eDate;
                return (
                  <View key={index} style={styles.tableRow}>
                    <View style={[styles.tableCol, styles.trainname]}>
                      <Text style={styles.tableCell}>{i.title}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.number]}>
                      <Text style={styles.tableCell}>{i.certnumber}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.place]}>
                      <Text style={styles.tableCell}>{i.location}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.signOn]}>
                      <Text style={styles.tableCell}>{sDateStr}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.signOff]}>
                      <Text style={styles.tableCell}>
                        {eDateStr === "Present" ? "-" : eDateStr.slice(4)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )}
        </View>
        <Text style={styles.heading}>
          Travel Documents ({data?.travel?.length})
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.filled, styles.trainname]}>
              <Text style={styles.tableCell}>Document</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.number]}>
              <Text style={styles.tableCell}>Number</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.place]}>
              <Text style={styles.tableCell}>Place of Issue</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.signOn]}>
              <Text style={styles.tableCell}>Issue Date</Text>
            </View>
            <View style={[styles.tableCol, styles.filled, styles.signOff]}>
              <Text style={styles.tableCell}>Expiry</Text>
            </View>
          </View>
          {data?.travel?.length > 0 && (
            <View>
              {data?.travel?.map((i, index) => {
                // const skills = i?.skills?.trim() || "";

                const sDate = i?.sdate
                  ? new Date(i?.sdate).toDateString()
                  : new Date().toDateString();
                const sDateStr = sDate.slice(4);
                const eDate = i?.edate
                  ? new Date(i?.edate).toDateString()
                  : new Date().toDateString();

                const eDateStr = eDate;
                return (
                  <View key={index} style={styles.tableRow}>
                    <View style={[styles.tableCol, styles.trainname]}>
                      <Text style={styles.tableCell}>{i.title}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.number]}>
                      <Text style={styles.tableCell}>{i.documentnumber}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.place]}>
                      <Text style={styles.tableCell}>{i.location}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.signOn]}>
                      <Text style={styles.tableCell}>{sDateStr}</Text>
                    </View>
                    <View style={[styles.tableCol, styles.signOff]}>
                      <Text style={styles.tableCell}>
                        {eDateStr === "Present" ? "-" : eDateStr.slice(4)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );

  // https://stackoverflow.com/questions/6150289/how-to-convert-image-into-base64-string-using-javascript
  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  toDataURL(data?.bigpic).then((dataUrl) => {
    // console.log(dataUrl);
    setDataUri(dataUrl);
  });
  // console.log(data?.bigpic);
  return dataUri ? (
    <PDFDownloadLink
      className="btn btn-primary text-center d-flex justify-content-center"
      document={<PDFCV />}
      fileName={`${data.fullname}.pdf`}>
      {({ blob, url, loading, error }) =>
        loading ? (
          <>
            Download Resume <SaveAltIcon className="ml-1" />
          </>
        ) : (
          <>
            Download Resume <SaveAltIcon className="ml-1" />
          </>
        )
      }
    </PDFDownloadLink>
  ) : null;
  // return (
  //   <PDFViewer>
  //     <PDFCV />
  //   </PDFViewer>
  // );
}

export default PrintableCV;
