import React from "react";
import { Image, Media } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { convertFireStorageUrl } from "../../../utils/url";

function FriendList({ entry, clickHandler, action }) {
  let icon = null;
  if (action === "viewstats") {
    icon = <EqualizerIcon />;
  } else if (action === "send") {
    icon = <SendRoundedIcon />;
  }
  return (
    <div
      className="clickableentry reactiveiconlite py-1 pointer"
      onClick={() => clickHandler(entry)}>
      <Media className="my-1">
        <div className="py-2 px-2">
          {!entry?.smallpic && (
            <AccountCircleTwoToneIcon
              className="text-primary"
              style={{
                fontSize: "30px",
                marginRight: "5px",
              }}
            />
          )}
          {entry?.smallpic && (
            <Image
              src={convertFireStorageUrl(entry?.smallpic)}
              width="30px"
              height="30px"
              alt=""
              roundedCircle
              style={{
                zIndex: "1",
                marginRight: "5px",
              }}
            />
          )}
        </div>
        <Media.Body className="text-left reactiveiconlite">
          <div className="py-2">
            <div className="pb-2 float-left reactiveicondark">
              <div className="d-none d-sm-block font-weight-bold">
                {entry.fullname}
              </div>
              <div className="d-block d-sm-none">{entry.fullname}</div>
              {entry?.country && (
                <div className="text-dark standardfontsize">
                  {entry?.country}
                  <ReactCountryFlag
                    countryCode={entry?.country}
                    className="emojiFlag ml-1"
                    style={{
                      fontSize: "1em",
                      lineHeight: "0.9em",
                    }}
                  />
                </div>
              )}
            </div>
            {action !== "viewprof" ? (
              <div
                className="float-right pb-2 adjusticon"
                style={{ width: "30px" }}>
                {icon}
              </div>
            ) : (
              <div className="float-right pb-2 adjusticon pr-2">
                <button className="btn btn-sm btn-success">View</button>
              </div>
            )}
          </div>
        </Media.Body>
      </Media>
    </div>
  );
}

export default FriendList;
