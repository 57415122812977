import React, { Component } from "react";
import { withRouter } from "react-router";
import Header from "../../common/header";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import { logEvent } from "../../../services/log";
import { Helmet } from "react-helmet";
import { getCurrentUserData } from "../../../services/user";
import { getLatestVersion } from "../../../services/getversion";
import ReloadBar from "../../common/reloadBar";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { getNotifications } from "../../../services/notifications";
import { getKnowledgeById } from "../../../services/knowledge";
import { Timeline, TimelineEvent } from "react-event-timeline";
import LinkButton from "../../blocks/linkButtonBlock";
import { Button, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import TitleCreator from "../component/titleCreator";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { convertFireStorageUrl } from "../../../utils/url";
import fire from "../../../services/fire";
import { updateProfilePic } from "../../../services/profile";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import PanoramaRoundedIcon from "@material-ui/icons/PanoramaRounded";
import AvatarEditor from "react-avatar-editor";
import { Grid, Slider } from "@material-ui/core";
import SpinnerText from "../../common/spinnerwithText";
import { updatePhoto } from "../../../services/uploadimage";
import DisplayTimeline from "../component/displayTimeline";
import HeadingSectionOnly from "../component/headingSectionOnly";
import HeadingSectionPhoto from "../component/headingSectionPhoto";
import HeadingSectionPhotoUpload from "../component/headingSectionPhotoUpload";
import PrintablePDF from "../../shareable/component/pdf";

class ViewLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      imageDataUrl: "",
      showphotoedit: false,
      photoUrl: null,
      itemid: this.props.match.params.itemid,
      content: [],
      zoom: 1,
      errors: {},
      user: this.props.user,
      loading: true,
      knowledge: [],
      version: null,
      json: null,
      timeline: null,
      knowledgeid: null,
      uploadingPhoto: false,
    };
  }

  getKnowledge = async (itemid) => {
    try {
      //retrieve data from firestore
      let know = await getKnowledgeById(itemid);

      if (know) {
        const content = [];
        know.forEach((doc) => {
          content.push(doc.data());
        });
        //convert content[0].content to json
        const json = JSON.parse(content[0].content);
        const smallpic = content[0].smallpic;
        const owner = content[0].owner;
        const version = await getLatestVersion();
        let el = document.querySelector("title");
        const title = content[0].header;
        const attr = content[0].description;
        const knowledgeid = content[0].id;
        //new fields
        const knowledgesmallpic = content[0]?.knowledgesmallpic || "";
        const knowledgebigpic = content[0]?.knowledgebigpic || "";
        const type = content[0]?.type || "";
        const subheader = content[0]?.subheader || "";
        let subheaderText = subheader;
        if (type === "book") {
          subheaderText = `📚 by ${subheader}`;
        }
        el.innerText = title;
        el = document.querySelector("meta[name='description']");
        el.setAttribute("content", attr);
        this.setState({
          version,
          content: content[0],
          json,
          knowledgeid,
          knowledgebigpic,
          knowledgesmallpic,
          photoUrl: knowledgebigpic,
          smallpic: smallpic,
          owner: owner,
          type,
          subheader: subheaderText,
          timeline: json.timeline,
          loading: false,
        });
      }
    } catch (error) {
      console.error("Link not found...");
      this.setState({ loading: false });
    }
  };

  getCurrentUserData = async () => {
    //retrieve data from firestore
    const itemid = this.state.itemid;
    try {
      if (this.state.user) {
        const uid = this.state.user.uid;

        const user = await getCurrentUserData(uid);

        if (user) {
          const country = user?.country || "";

          this.setState({
            country,
          });
        }
      }
      await this.getKnowledge(itemid);
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  fileUpload = (e) => {
    const storageRef = fire.storage();
    const file = e.target.files[0];
    console.log("FileName", file);
    const userid = this.state.user.uid;
    const rand = new Date().getTime();
    const uploadpath = `images/${userid}`;
    const filename = `${userid}-${rand}`;
    const fullfilename = `${filename}.jpeg`;

    this.setState({ uploadingPhoto: true });
    const uploadTask = storageRef.ref(uploadpath).child(fullfilename).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("error:-", error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);

          updatePhoto(userid, filename, this.state.knowledgeid).then(
            (picObj) => {
              console.log(picObj);
              // Delay for 4 seconds
              setTimeout(() => {
                this.setState({
                  saving: false,
                  photoUrl: picObj?.bigPic || "",
                });
              }, 4000);
            }
          );
        });
      }
    );
  };

  render() {
    const {
      version,
      loading,
      content,
      user,
      json,
      itemid,
      owner,
      subheader,
      type,
      smallpic,
      timeline,
      unreadnotifications,
      photoUrl,
    } = this.state;
    if (loading === true) {
      return <LoadingSpinnerNav hide="profile" hidehome={true} />;
    }
    return (
      <React.Fragment>
        {user ? (
          <Header
            user={user}
            smallpic={user.photoURL}
            hide="profile"
            hidefriends={true}
            hidehome={true}
            notificationcount={unreadnotifications || 0}
          />
        ) : (
          <Header hide="profile" />
        )}
        {version && <ReloadBar version={version} />}
        {!json && (
          <div>
            <div className="row justify-content-center mx-auto">
              <main className="container">
                <div className="py-4 text-center my-4">
                  <div style={{ fontSize: "100px" }}>🤯</div>
                  <h1>Something's wrong...</h1>
                </div>
                <LinkButton type="link" link="/home" label="Back to Home" />
              </main>
            </div>
          </div>
        )}
        {json && (
          <div className="row justify-content-center mx-auto">
            <div className="col-lg-8 mx-0">
              <main className="contsainer">
                <div className="col-lg-12 pb-3 pt-1 px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pb-2 pointer text-left ">
                      <Link
                        to="/home"
                        style={{ textDecoration: "none" }}
                        className="my-2 text-primary d-flex align-items-center justify-content-start">
                        <ArrowBackIosRoundedIcon
                          fontSize="small"
                          className=" mr-2"
                        />
                        <span className="text-dark">Home</span>
                      </Link>
                    </div>
                    {this.state.user && (
                      <PrintablePDF
                        user={this.props.user}
                        data={content}
                        json={json}
                      />
                    )}
                  </div>

                  {!user && !photoUrl && (
                    <HeadingSectionOnly
                      type={type}
                      subtitle={subheader}
                      json={json}
                      content={content}
                      owner={owner}
                      smallpic={smallpic}
                    />
                  )}
                  {!user && photoUrl && (
                    <HeadingSectionPhoto
                      type={type}
                      subtitle={subheader}
                      json={json}
                      content={content}
                      owner={owner}
                      smallpic={smallpic}
                      photoUrl={photoUrl}
                    />
                  )}
                  {user && (
                    <HeadingSectionPhotoUpload
                      type={type}
                      subtitle={subheader}
                      user={user}
                      json={json}
                      content={content}
                      owner={owner}
                      smallpic={smallpic}
                      photoUrl={photoUrl}
                      fileUpload={this.fileUpload}
                      uploadingPhoto={this.state.uploadingPhoto}
                    />
                  )}
                </div>
              </main>
              <div className="row justify-content-center mx-auto">
                <main className="container px-0">
                  {timeline && <DisplayTimeline timeline={timeline} />}
                  <LinkButton type="link" link="/home" label="Back to Home" />
                </main>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ViewLink);
